import { call, put, takeEvery } from "redux-saga/effects"
import {
  FETCH_GOODS_CATEGORY_GRAPH_DATA,
  FETCH_GOODS_CATEGORY_REG_DATA,
} from "./actionTypes"
import {
  fetchGoodsCategoryRegDataSuccess,
  fetchGoodsCategoryRegDataError,
  fetchGraphDataSuccess,
  fetchGraphDataError,
} from "./actions"

//Include Both Helper File with needed methods
import { getGoodsCategory, putGoodsCategory } from "../../helpers/backend_helper"

function* fetchGraphDataSaga(action) {
  try {
    const graphData = yield call(getGoodsCategory)
    yield put(fetchGraphDataSuccess(graphData))
  } catch (error) {
    yield put(fetchGraphDataError(error))
  }
}

function* fetchGoodsCategoryRegDataSaga(action) {
  try {
    const { targetGoods, category, categoryName, deleteFlg, createFlg } =
      action.payload
    const graphData = yield call(
      putGoodsCategory,
      targetGoods,
      category,
      categoryName,
      deleteFlg,
      createFlg
    )
    yield put(fetchGoodsCategoryRegDataSuccess(graphData))
  } catch (error) {
    yield put(fetchGoodsCategoryRegDataError(error))
  }
}

function* CategoriesSaga() {
  yield takeEvery(FETCH_GOODS_CATEGORY_GRAPH_DATA, fetchGraphDataSaga)
  yield takeEvery(FETCH_GOODS_CATEGORY_REG_DATA, fetchGoodsCategoryRegDataSaga)
}

export default CategoriesSaga
