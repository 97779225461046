// src/pages/DeliveryPlan/DeliveryPlanExecute.js

import React, { useEffect, useState, useRef, useMemo } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  FormGroup,
  Input,
  Table,
} from "reactstrap"
// Google Maps のロード状態を管理するフック
import { useJsApiLoader } from "@react-google-maps/api"
import { decode } from "@googlemaps/polyline-codec"
import ErrorMessage from "../Common/ErrorMessage"
import LoadingOverlay from "../Common/LoadingOverlay"
import { FaBurn } from "react-icons/fa"

import { fetchSoldOut } from "../../store/soldout/actions"
import {
  fetchItemsForLoading,
  fetchDeliveryPlanDetailRequest,
  updateDeliveryPlanRequest,
} from "../../store/deliveryPlan/actions"
import { fetchGraphData } from "../../store/stocks/actions"

import { googleMapsConfig } from "./googleMapsConfig"
import CustomGoogleMap from "./CustomGoogleMap"
import { getLabel } from "./labelUtils"

const DeliveryPlanExecute = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const { history, deliveryPlan, vehicleId } = location.state || {}

  const startWarehouseId =
    history?.startWarehouseId || deliveryPlan?.start_warehouse_id
  const endWarehouseId =
    history?.endWarehouseId || deliveryPlan?.end_warehouse_id
  const selectedLocations = useMemo(
    () =>
      history?.selectedLocations ||
      deliveryPlan?.locations?.waypoints?.map(wp => wp.id) ||
      [],
    [history, deliveryPlan]
  )

  const { id: planId, user_name: userName, created_at: createdAt } =
    deliveryPlan || {}

  const deliveryPlanDetail =
    useSelector(state => state.deliveryPlanReducer.deliveryPlanDetail) || {}
  const items = useSelector(state => state.deliveryPlanReducer.items) || []
  const isLoading1 = useSelector(state => state.deliveryPlanReducer.loading)
  const soldOutData =
    useSelector(state => state.soldOutReducer.soldOutData) || []
  const isLoading2 = useSelector(state => state.soldOutReducer.loading)

  const graphData = useSelector(state => state.stocksReducer.graphData)
  const isLoading3 = useSelector(state => state.stocksReducer.loading)
  const results = graphData && graphData.data ? graphData.data.results : []

  const [totalDistance, setTotalDistance] = useState(0)
  const [totalDuration, setTotalDuration] = useState(0)
  const [startDate, setStartDate] = useState("")

  const [decodedRoute, setDecodedRoute] = useState([])
  const [routeInfo, setRouteInfo] = useState(null)
  const [currentTime, setCurrentTime] = useState(new Date())
  const [elapsedTime, setElapsedTime] = useState("")
  const [segmentDistance, setSegmentDistance] = useState(null)
  const [segmentDuration, setSegmentDuration] = useState(null)
  const [markers, setMarkers] = useState([])
  const [buttonText, setButtonText] = useState("最初のロケーションに行く")
  const [showMap, setShowMap] = useState(true)
  const [locationInfo, setLocationInfo] = useState(null)
  const [currentLocationIndex, setCurrentLocationIndex] = useState(0)
  const [mapCenter, setMapCenter] = useState({ lat: 35.6895, lng: 139.6917 })
  const [mapZoom, setMapZoom] = useState(10)
  const [showTotalDistanceDuration, setShowTotalDistanceDuration] =
    useState(true)
  const [showItems, setShowItems] = useState(false)
  const [showGraphData, setShowGraphData] = useState(false)
  const [includeCloseToSoldOut, setIncludeCloseToSoldOut] = useState(false)
  const [exRoute, setExRoute] = useState({})
  const [showReturnItems, setShowReturnItems] = useState(false)
  const [returnItems, setReturnItems] = useState([])
  const [confirmationStatus, setConfirmationStatus] = useState([])
  const [exclusionStatus, setExclusionStatus] = useState([])
  const [aggregatedItems, setAggregatedItems] = useState([])

  const [selectedCluster, setSelectedCluster] = useState(null)
  const [selectedMarker, setSelectedMarker] = useState(null)

  const { isLoaded, loadError } = useJsApiLoader(googleMapsConfig)
  const mapRef = useRef(null)
  const [completedLocations, setCompletedLocations] = useState([])

  // マーカーをクリックした際
  const handleMarkerClick = index => {
    setSelectedMarker(index)
    setSelectedCluster(null)
  }

  // クラスターをクリックした際
  const handleClusterClick = (position, labels) => {
    setSelectedCluster({ position, labels })
    setSelectedMarker(null)
  }

  // 配送計画詳細を取得
  useEffect(() => {
    if (deliveryPlan) {
      dispatch(fetchDeliveryPlanDetailRequest(deliveryPlan.id))
    }
  }, [dispatch, deliveryPlan])

  // 初期ロード後: ルートをセット
  useEffect(() => {
    const fetchRouteDetails = async () => {
      if (
        deliveryPlanDetail.route &&
        buttonText === "最初のロケーションに行く"
      ) {
        if (deliveryPlanDetail && deliveryPlanDetail.locations.waypoints) {
          const waypoints = deliveryPlanDetail.locations.waypoints

          // マーカー情報を合体
          if (
            deliveryPlanDetail.route.waypoints_info &&
            deliveryPlanDetail.route.waypoints_names &&
            deliveryPlanDetail.route.waypoints_info.length ===
              deliveryPlanDetail.route.waypoints_names.length
          ) {
            const combinedMarkers =
              deliveryPlanDetail.route.waypoints_info.map((wpInfo, idx) => ({
                position: wpInfo.location,
                name: deliveryPlanDetail.route.waypoints_names[idx].name,
                address: deliveryPlanDetail.route.waypoints_names[idx].address,
              }))
            setMarkers(combinedMarkers)
          } else {
            setMarkers(
              deliveryPlanDetail.route.waypoints_info.map((wp, idx) => ({
                position: wp.location,
                name: wp.name,
                address: wp.address,
              }))
            )
          }

          for (let i = 1; i < waypoints.length - 1; i++) {
            const waypoint = waypoints[i]
            switch (waypoint.status) {
              case "移動中": {
                setShowGraphData(false)

                const origin =
                  deliveryPlanDetail.route.waypoints_info[currentLocationIndex]
                    .location
                const destination =
                  deliveryPlanDetail.route.waypoints_info[
                    currentLocationIndex + 1
                  ].location

                const result = await calculateRoute(origin, destination)
                if (result) {
                  const decodedPath = decode(result.routes[0].overview_polyline)
                  setDecodedRoute(decodedPath)
                  setSegmentDistance(result.routes[0].legs[0].distance.text)
                  setSegmentDuration(result.routes[0].legs[0].duration.text)
                  setMarkers([
                    {
                      position: origin,
                      name:
                        "出発地(" +
                        deliveryPlanDetail.route.waypoints_names[
                          currentLocationIndex
                        ].name +
                        ")",
                      address: deliveryPlanDetail.route.waypoints_names[
                        currentLocationIndex
                      ].address
                        .replace(/〒\d{3}-\d{4}/, "")
                        .replace(/〒\d{7}/, "")
                        .replace("日本、", ""),
                    },
                    {
                      position: destination,
                      name:
                        "目的地(" +
                        deliveryPlanDetail.route.waypoints_names[
                          currentLocationIndex + 1
                        ].name +
                        ")",
                      address: deliveryPlanDetail.route.waypoints_names[
                        currentLocationIndex + 1
                      ].address
                        .replace(/〒\d{3}-\d{4}/, "")
                        .replace(/〒\d{7}/, "")
                        .replace("日本、", ""),
                    },
                  ])

                  const total = deliveryPlanDetail.route.waypoints_info.length
                  setRouteInfo({
                    waypoints_info: [
                      { location: origin, globalIndex: currentLocationIndex },
                      {
                        location: destination,
                        globalIndex: currentLocationIndex + 1,
                      },
                    ],
                    totalWaypoints: total,
                  })

                  const bounds = new window.google.maps.LatLngBounds()
                  bounds.extend(origin)
                  bounds.extend(destination)
                  if (mapRef.current) {
                    mapRef.current.fitBounds(bounds)
                  }

                  setCurrentLocationIndex(currentLocationIndex + 1)
                  setButtonText("ロケーションに到着")
                  setShowTotalDistanceDuration(false)
                  setShowMap(true)
                  setExRoute(deliveryPlanDetail.route)
                }
                break
              }
              case "到着": {
                setCurrentLocationIndex(i)
                setStartDate(deliveryPlanDetail.actual_start_date)
                setShowMap(false)
                setLocationInfo({
                  name: waypoint.name,
                  address: waypoint.address,
                })
                setShowItems(false)
                setShowGraphData(false)
                setButtonText("補充を開始")
                setExRoute(deliveryPlanDetail.route)
                break
              }
              case "補充開始": {
                setStartDate(deliveryPlanDetail.actual_start_date)
                setShowMap(false)

                dispatch(fetchSoldOut({ locId: waypoint.id }))
                dispatch(
                  fetchItemsForLoading({
                    deliveryPlanId: deliveryPlanDetail.id,
                    vehicleId: deliveryPlanDetail.vehicle_id,
                  })
                )

                const filteredItems = deliveryPlanDetail.items.filter(
                  item => item.loc_id === waypoint.id
                )
                const aggregated = filteredItems.reduce((acc, item) => {
                  acc.push({ ...item })
                  return acc
                }, [])

                setAggregatedItems(aggregated)
                setReturnItems(aggregated)
                setShowItems(true)
                setButtonText("最新の補充を確認")
                setExRoute(deliveryPlanDetail.route)
                setCurrentLocationIndex(i)
                setConfirmationStatus(aggregated.map(() => false))
                setExclusionStatus(aggregated.map(() => false))
                break
              }
              case "補充終了": {
                setExRoute(deliveryPlanDetail.route)
                setCurrentLocationIndex(i)
                break
              }
              default: {
                setTotalDistance(deliveryPlanDetail.locations.total_distance)
                setTotalDuration(deliveryPlanDetail.locations.total_duration)
                setStartDate(new Date())
                const decodedPathDefault = decode(
                  deliveryPlanDetail.route.route
                )
                setDecodedRoute(decodedPathDefault)
                setRouteInfo({
                  ...deliveryPlanDetail.route,
                  totalWaypoints:
                    deliveryPlanDetail.route.waypoints_info.length,
                })

                if (
                  deliveryPlanDetail.route.waypoints_info &&
                  deliveryPlanDetail.route.waypoints_names &&
                  deliveryPlanDetail.route.waypoints_info.length ===
                    deliveryPlanDetail.route.waypoints_names.length
                ) {
                  const combinedMarkers =
                    deliveryPlanDetail.route.waypoints_info.map(
                      (wpInfo, idx) => ({
                        position: wpInfo.location,
                        name: deliveryPlanDetail.route.waypoints_names[idx].name,
                        address:
                          deliveryPlanDetail.route.waypoints_names[idx].address,
                      })
                    )
                  setMarkers(combinedMarkers)
                } else {
                  setMarkers(
                    deliveryPlanDetail.route.waypoints_info.map((wp, idx) => ({
                      position: wp.location,
                      name: wp.name,
                      address: wp.address,
                    }))
                  )
                }

                setExRoute(deliveryPlanDetail.route)

                if (mapRef.current && decodedPathDefault.length > 0) {
                  const bounds = new window.google.maps.LatLngBounds()
                  decodedPathDefault.forEach(([lat, lng]) =>
                    bounds.extend({ lat, lng })
                  )
                  mapRef.current.fitBounds(bounds)
                }
                break
              }
            }
          }
        }
      }
    }
    fetchRouteDetails()
  }, [deliveryPlanDetail, currentLocationIndex, items, buttonText, dispatch])

  // 時間の更新
  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date()
      setCurrentTime(new Date(now.getTime() + 9 * 60 * 60 * 1000)) // UTC+9

      const start = new Date(startDate)
      const elapsed = Math.floor((now - start) / 1000)
      const hours = Math.floor(elapsed / 3600)
      const minutes = Math.floor((elapsed % 3600) / 60)
      const seconds = elapsed % 60
      setElapsedTime(
        `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
      )
    }, 1000)
    return () => clearInterval(intervalId)
  }, [startDate])

  // ルート計算関数
  const calculateRoute = async (origin, destination) => {
    try {
      const directionsService = new window.google.maps.DirectionsService()
      const result = await directionsService.route({
        origin,
        destination,
        travelMode: window.google.maps.TravelMode.DRIVING,
      })
      return result
    } catch (error) {
      console.error("Error calculating route:", error)
      return null
    }
  }

  // aggregatedItems を storeの itemsに反映
  const updateItemQuantities = (aggregatedItems, deliveryPlanDetail) => {
    const updatedItems = deliveryPlanDetail.items.map(item => {
      const matchedAggregatedItem = aggregatedItems.find(
        aggItem => aggItem.name === item.name
      )
      if (matchedAggregatedItem) {
        return {
          ...item,
          needed_quantity:
            item.needed_quantity - matchedAggregatedItem.needed_quantity,
        }
      }
      return item
    })
    return updatedItems
  }

  // ★ロケーションに出発時に Googleマップを開く新関数
  const handleDeparture = () => {
    // 現在の地点(currentLocationIndex-1) から 次の地点(currentLocationIndex)
    // ただし「最初のロケーションに行く」でcurrentLocationIndexが+1された後なので、
    //   origin = exRoute.waypoints_info[currentLocationIndex - 1]
    //   destination = exRoute.waypoints_info[currentLocationIndex]
    if (exRoute.waypoints_info && currentLocationIndex > 0) {
      const origin = exRoute.waypoints_info[currentLocationIndex - 1]?.location
      const destination = exRoute.waypoints_info[currentLocationIndex]?.location

      if (origin && destination) {
        const navigationUrl = `https://www.google.com/maps/dir/?api=1&origin=${origin.lat},${origin.lng}&destination=${destination.lat},${destination.lng}&travelmode=driving`
        window.open(navigationUrl, "_blank")
        // 戻ると「ロケーションに到着」にボタンテキストを変更
        setButtonText("ロケーションに到着")
      }
    }
  }

  // メイン操作用関数 (ボタンを押したとき)
  const handleNextLocation = async step => {
    if (buttonText !== "最新の補充を確認") {
      setConfirmationStatus(confirmationStatus.map(() => false))
      setExclusionStatus(exclusionStatus.map(() => false))
    }

    // 1) 「最初のロケーションに行く」
    if (
      buttonText === "最初のロケーションに行く" &&
      exRoute.waypoints_info &&
      exRoute.waypoints_info.length > currentLocationIndex + 1
    ) {
      setShowGraphData(false)

      const origin = exRoute.waypoints_info[currentLocationIndex].location
      const destination =
        exRoute.waypoints_info[currentLocationIndex + 1].location

      const result = await calculateRoute(origin, destination)
      if (result) {
        const decodedPath = decode(result.routes[0].overview_polyline)
        setDecodedRoute(decodedPath)
        setSegmentDistance(result.routes[0].legs[0].distance.text)
        setSegmentDuration(result.routes[0].legs[0].duration.text)
        setMarkers([
          {
            position: origin,
            name:
              "出発地(" +
              exRoute.waypoints_names[currentLocationIndex].name +
              ")",
            address: exRoute.waypoints_names[currentLocationIndex].address
              .replace(/〒\d{3}-\d{4}/, "")
              .replace(/〒\d{7}/, "")
              .replace("日本、", ""),
          },
          {
            position: destination,
            name:
              "目的地(" +
              exRoute.waypoints_names[currentLocationIndex + 1].name +
              ")",
            address: exRoute.waypoints_names[currentLocationIndex + 1].address
              .replace(/〒\d{3}-\d{4}/, "")
              .replace(/〒\d{7}/, "")
              .replace("日本、", ""),
          },
        ])

        // ここで従来は setButtonText("ロケーションに到着") していたが、
        //  「ロケーションに出発」に変更して出発ボタンを表示する
        setButtonText("ロケーションに出発")

        const total = exRoute.waypoints_info.length
        setRouteInfo({
          waypoints_info: [
            { location: origin, globalIndex: currentLocationIndex },
            { location: destination, globalIndex: currentLocationIndex + 1 },
          ],
          totalWaypoints: total,
        })

        const bounds = new window.google.maps.LatLngBounds()
        bounds.extend(origin)
        bounds.extend(destination)
        if (mapRef.current) {
          mapRef.current.fitBounds(bounds)
        }

        setCurrentLocationIndex(currentLocationIndex + 1)
        setShowTotalDistanceDuration(false)
        setShowMap(true)
      }

      const updatedDeliveryPlan = {
        ...deliveryPlanDetail,
        status: 3,
        locations: {
          ...deliveryPlanDetail.locations,
          waypoints: deliveryPlanDetail.locations.waypoints.map(
            (waypoint, index) => {
              if (index === currentLocationIndex + 1) {
                return {
                  ...waypoint,
                  status: "移動中",
                  start_moving_time: new Date().toISOString(),
                }
              }
              return waypoint
            }
          ),
        },
      }
      dispatch(updateDeliveryPlanRequest({ delivery_plan: updatedDeliveryPlan }))
    }

    // 2) 「次のロケーションに移動する」(step===1)
    else if (
      step === 1 &&
      exRoute.waypoints_info &&
      exRoute.waypoints_info.length > currentLocationIndex + 1
    ) {
      setShowGraphData(false)

      const origin = exRoute.waypoints_info[currentLocationIndex].location
      const destination =
        exRoute.waypoints_info[currentLocationIndex + 1].location

      const result = await calculateRoute(origin, destination)
      if (result) {
        const decodedPath = decode(result.routes[0].overview_polyline)
        setDecodedRoute(decodedPath)
        setSegmentDistance(result.routes[0].legs[0].distance.text)
        setSegmentDuration(result.routes[0].legs[0].duration.text)
        setMarkers([
          {
            position: origin,
            name:
              "出発地(" +
              exRoute.waypoints_names[currentLocationIndex].name +
              ")",
            address: exRoute.waypoints_names[currentLocationIndex].address
              .replace(/〒\d{3}-\d{4}/, "")
              .replace(/〒\d{7}/, "")
              .replace("日本、", ""),
          },
          {
            position: destination,
            name:
              "目的地(" +
              exRoute.waypoints_names[currentLocationIndex + 1].name +
              ")",
            address: exRoute.waypoints_names[currentLocationIndex + 1].address
              .replace(/〒\d{3}-\d{4}/, "")
              .replace(/〒\d{7}/, "")
              .replace("日本、", ""),
          },
        ])

        // ここでも同様に「ロケーションに出発」を表示したければ
        setButtonText("ロケーションに出発")

        const total = exRoute.waypoints_info.length
        setRouteInfo({
          waypoints_info: [
            { location: origin, globalIndex: currentLocationIndex },
            { location: destination, globalIndex: currentLocationIndex + 1 },
          ],
          totalWaypoints: total,
        })

        const bounds = new window.google.maps.LatLngBounds()
        bounds.extend(origin)
        bounds.extend(destination)
        if (mapRef.current) {
          mapRef.current.fitBounds(bounds)
        }

        const updatedItems = updateItemQuantities(aggregatedItems, deliveryPlanDetail)
        const updatedDeliveryPlan = {
          ...deliveryPlanDetail,
          items: updatedItems,
          locations: {
            ...deliveryPlanDetail.locations,
            waypoints: deliveryPlanDetail.locations.waypoints.map(
              (waypoint, index) => {
                if (index === currentLocationIndex) {
                  return {
                    ...waypoint,
                    status: "補充終了",
                    end_refilling_time: new Date().toISOString(),
                  }
                }
                if (index === currentLocationIndex + 1) {
                  return {
                    ...waypoint,
                    status: "移動中",
                    start_moving_time: new Date().toISOString(),
                  }
                }
                return waypoint
              }
            ),
          },
        }

        dispatch(
          updateDeliveryPlanRequest({
            delivery_plan: updatedDeliveryPlan,
          })
        )

        setCurrentLocationIndex(currentLocationIndex + 1)
        setShowTotalDistanceDuration(false)
        setShowMap(true)
      }
    }

    // 3) 「ロケーションに到着」
    else if (buttonText === "ロケーションに到着") {
      // 最後のロケーションの場合
      if (currentLocationIndex === exRoute.waypoints_info.length - 1) {
        setShowMap(false)
        setShowReturnItems(true)  // 戻し処理画面を表示
        setButtonText("戻し完了")  // ボタンのテキストを変更

        // 戻し商品一覧を作成
        const aggregatedReturnItems = deliveryPlanDetail.items.reduce((acc, item) => {
          const existingItem = acc.find(i => i.name === item.name)
          if (existingItem) {
            existingItem.needed_quantity += item.needed_quantity
          } else {
            acc.push({ ...item })
          }
          return acc
        }, [])

        setReturnItems(aggregatedReturnItems)
        setConfirmationStatus(aggregatedReturnItems.map(() => false))
        setExclusionStatus(aggregatedReturnItems.map(() => false))

        const updatedDeliveryPlan = {
          ...deliveryPlanDetail,
          locations: {
            ...deliveryPlanDetail.locations,
            waypoints: deliveryPlanDetail.locations.waypoints.map(
              (waypoint, index) => {
                if (index === currentLocationIndex) {
                  return {
                    ...waypoint,
                    status: "到着",
                    end_moving_time: new Date().toISOString(),
                  }
                }
                return waypoint
              }
            ),
          },
        }

        dispatch(
          updateDeliveryPlanRequest({
            delivery_plan: updatedDeliveryPlan,
          })
        )
      } else {
        // 最後のロケーション以外の場合は既存の処理を実行
        setShowMap(false)
        setLocationInfo({
          name: exRoute.waypoints_names[currentLocationIndex].name,
          address: exRoute.waypoints_names[currentLocationIndex].address,
        })
        setShowItems(false)
        setShowGraphData(false)
        setButtonText("補充を開始")

        const updatedDeliveryPlan = {
          ...deliveryPlanDetail,
          locations: {
            ...deliveryPlanDetail.locations,
            waypoints: deliveryPlanDetail.locations.waypoints.map(
              (waypoint, index) => {
                if (index === currentLocationIndex) {
                  return {
                    ...waypoint,
                    status: "到着",
                    end_moving_time: new Date().toISOString(),
                  }
                }
                return waypoint
              }
            ),
          },
        }

        dispatch(
          updateDeliveryPlanRequest({
            delivery_plan: updatedDeliveryPlan,
          })
        )
      }
    }

    // 4) 「補充を開始」
    else if (buttonText === "補充を開始") {
      const currentLocation =
        deliveryPlanDetail.locations.waypoints[currentLocationIndex]

      dispatch(fetchSoldOut({ locId: currentLocation.id }))
      dispatch(
        fetchItemsForLoading({
          deliveryPlanId: deliveryPlanDetail.id,
          vehicleId: deliveryPlanDetail.vehicle_id,
        })
      )

      const updatedDeliveryPlan = {
        ...deliveryPlanDetail,
        locations: {
          ...deliveryPlanDetail.locations,
          waypoints: deliveryPlanDetail.locations.waypoints.map(
            (waypoint, index) => {
              if (index === currentLocationIndex) {
                return {
                  ...waypoint,
                  status: "補充開始",
                  start_refilling_time: new Date().toISOString(),
                }
              }
              return waypoint
            }
          ),
        },
      }

      dispatch(
        updateDeliveryPlanRequest({
          delivery_plan: updatedDeliveryPlan,
        })
      )

      const filteredItems = deliveryPlanDetail.items.filter(
        item =>
          item.loc_id ===
          deliveryPlanDetail.locations.waypoints[currentLocationIndex].id
      )
      const aggregated = filteredItems.reduce((acc, item) => {
        const existingItem = acc.find(i => i.name === item.name)
        if (existingItem) {
          existingItem.needed_quantity += item.needed_quantity
          existingItem.current_quantity += item.current_quantity
        } else {
          acc.push({ ...item })
        }
        return acc
      }, [])

      setAggregatedItems(aggregated)
      setShowItems(true)
      setButtonText("最新の補充を確認")
    }

    // 5) 「最新の補充を確認」
    else if (buttonText === "最新の補充を確認") {
      const currentLocationName =
        exRoute.waypoints_names[currentLocationIndex].name
      const currentLocationIndexInPlan =
        deliveryPlanDetail.location_names.findIndex(
          name => name === currentLocationName
        )

      const currentLocation =
        deliveryPlanDetail.locations.waypoints[currentLocationIndexInPlan]
      if (currentLocation && currentLocation.id) {
        const today = new Date()
        const yesterday = new Date(today)
        yesterday.setDate(today.getDate() - 1)

        dispatch(
          fetchGraphData(null, currentLocation.id, "時", today, yesterday)
        )
        setShowGraphData(true)
      } else {
        console.error("Current location is undefined or does not have loc_id")
      }
    }

    // 6) 「戻し完了」(step===2)
    else if (step === 2) {
      const confirmedItems = returnItems.filter(
        (item, index) => confirmationStatus[index] || exclusionStatus[index]
      )

      const updatedDeliveryPlan = {
        ...deliveryPlanDetail,
        actual_end_date: new Date().toISOString(),
        confirmedItems,
      }

      dispatch(
        updateDeliveryPlanRequest({
          delivery_plan: { ...updatedDeliveryPlan, status: 5 },
        })
      )

      navigate("/delivery-plan/complete", {
        state: {
          aggregatedItems: aggregatedItems,
          deliveryPlan: { ...updatedDeliveryPlan, status: 5 },
        },
      })
    }
  }

  // 戻し商品の数量変更
  const handleQuantityChange = (index, value) => {
    const newReturnItems = [...returnItems]
    if (newReturnItems[index]) {
      newReturnItems[index].needed_quantity = Math.max(0, value)
      setReturnItems(newReturnItems)
    }
  }

  // 戻し商品の「確認」
  const handleConfirmItem = index => {
    const newConfirmationStatus = [...confirmationStatus]
    newConfirmationStatus[index] = !newConfirmationStatus[index]
    setConfirmationStatus(newConfirmationStatus)
  }

  // 戻し商品の「除外」
  const handleExcludeItem = index => {
    const newExclusionStatus = [...exclusionStatus]
    newExclusionStatus[index] = !newExclusionStatus[index]
    setExclusionStatus(newExclusionStatus)
  }

  // 戻し商品の一括確認
  const handleConfirmAll = () => {
    const newStatus = confirmationStatus.some(status => !status)
    setConfirmationStatus(returnItems.map(() => newStatus))
    setExclusionStatus(returnItems.map(() => !newStatus))
  }

  // (必要に応じて) ローディング画面に戻る
  const handleReturnToLoading = () => {
    navigate("/delivery-plan/loading", {
      state: {
        deliveryPlan: deliveryPlanDetail,
        vehicleId: deliveryPlanDetail.vehicle_id,
      },
    })
  }

  // (必要に応じて) ルート再編集 (ロケーション追加)
  const handleAddLocation = () => {
    navigate("/delivery-plan/create", {
      state: {
        startWarehouseId: deliveryPlanDetail.start_warehouse_id,
        startWarehouseAddress: deliveryPlanDetail.start_warehouse_address,
        endWarehouseId: deliveryPlanDetail.end_warehouse_id,
        endWarehouseAddress: deliveryPlanDetail.end_warehouse_address,
        selectedUser: {
          value: deliveryPlanDetail.user_id,
          label: deliveryPlanDetail.user_name,
        },
        selectedLocations: deliveryPlanDetail.locations.waypoints.map(
          wp => wp.id
        ),
        startDate: deliveryPlanDetail.start_date,
        endDate: deliveryPlanDetail.end_date,
        isAddingLocations: true,
        originalPlanId: deliveryPlanDetail.id,
      },
    })
  }

  if (loadError) {
    return <div>Google Maps APIのロード中にエラーが発生しました。</div>
  }

  if (!isLoaded) {
    return <div>Google Maps APIをロード中...</div>
  }

  const tableStyle = window.innerWidth < 500 ? "p-0" : "mx-auto p-3"
  const tableStyle2 =
    window.innerWidth < 500 ? "p-0 bg-secondary" : "mx-auto p-3 bg-secondary"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="ホーム" breadcrumbItem="配送計画実行" />
          <ErrorMessage />
          <LoadingOverlay isLoading={isLoading1 || isLoading2 || isLoading3} />
          <Row>
            <Col xs={12}>
              <Card className={tableStyle2} style={{ maxWidth: "950px" }}>
                <CardBody style={{ textAlign: "center" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "20px",
                      marginBottom: "10px",
                      backgroundColor: "#dcdcdc",
                      border: "1px solid #ccc",
                      padding: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    <h2 style={{ margin: 0 }}>計画NO: {planId}</h2>
                    <p style={{ margin: 0 }}>
                      作成時刻: {new Date(createdAt).toLocaleString()}
                    </p>
                  </div>
                  <div style={{ marginBottom: "0px" }}>
                    <p style={{ margin: "0px" }}>
                      開始時刻: {new Date(startDate).toLocaleString()}
                    </p>
                    <p style={{ margin: "0px" }}>
                      現在時刻: {currentTime.toLocaleString()}
                    </p>
                    <p style={{ margin: "0px" }}>経過時間: {elapsedTime}</p>
                  </div>
                  <FormGroup>
                    <Input
                      type="text"
                      value={userName || ""}
                      disabled
                      style={{ backgroundColor: "#e9ecef" }}
                    />
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {showMap && (
            <Row>
              <Col xs={12}>
                <Card className={tableStyle2} style={{ maxWidth: "950px" }}>
                  <CardBody>
                    {showTotalDistanceDuration && (
                      <div
                        style={{
                          textAlign: "center",
                          fontSize: "20px",
                          marginBottom: "20px",
                        }}
                      >
                        <strong>総距離: </strong>
                        {totalDistance} km
                        <br />
                        <strong>総時間: </strong>
                        {totalDuration} 分
                      </div>
                    )}
                    {segmentDistance && segmentDuration && (
                      <div
                        style={{
                          textAlign: "center",
                          fontSize: "20px",
                          marginBottom: "20px",
                        }}
                      >
                        <strong>区間距離: </strong>
                        {segmentDistance}
                        <br />
                        <strong>区間時間: </strong>
                        {segmentDuration}
                      </div>
                    )}

                    {decodedRoute.length > 0 && routeInfo && (
                      <div
                        style={{
                          width: "100%",
                          height: "400px",
                          marginBottom: "20px",
                        }}
                      >
                        <CustomGoogleMap
                          decodedRoute={decodedRoute}
                          waypointsInfo={routeInfo.waypoints_info}
                          totalWaypoints={routeInfo.totalWaypoints}
                          waypointsNames={deliveryPlanDetail.locations.waypoints}
                          completedLocations={completedLocations}
                          mapId="b163a3409a584201"
                          onMarkerClick={handleMarkerClick}
                          onClusterClick={handleClusterClick}
                          mapRef={mapRef}
                        />
                      </div>
                    )}

                    <div
                      style={{
                        textAlign: "center",
                        marginTop: "20px",
                      }}
                    >
                      {exRoute.waypoints_names && currentLocationIndex > 0 ? (
                        <div className="waypoints-list">
                          <Row className="waypoints-item">
                            <Col xs="2" sm="1" md="1" lg="1">
                              <strong className="waypoints-label">
                                {getLabel(
                                  currentLocationIndex - 1,
                                  exRoute.waypoints_names.length
                                )}
                                :
                              </strong>
                            </Col>
                            <Col xs="10" sm="11" md="11" lg="11">
                              <div className="waypoints-content">
                                {
                                  exRoute.waypoints_names[
                                    currentLocationIndex - 1
                                  ].name
                                }
                                (
                                {exRoute.waypoints_names[
                                  currentLocationIndex - 1
                                ].address
                                  .replace(/〒\d{3}-\d{4}/, "")
                                  .replace(/〒\d{7}/, "")
                                  .replace("日本、", "")}
                                )
                              </div>
                            </Col>
                          </Row>

                          <Row className="arrow-row">
                            <Col xs="12">
                              <div style={{ fontSize: "1.4rem", textAlign: "center" }}>
                                ↓
                              </div>
                            </Col>
                          </Row>

                          <Row className="waypoints-item">
                            <Col xs="2" sm="1" md="1" lg="1">
                              <strong className="waypoints-label">
                                {getLabel(
                                  currentLocationIndex,
                                  exRoute.waypoints_names.length
                                )}
                                :
                              </strong>
                            </Col>
                            <Col xs="10" sm="11" md="11" lg="11">
                              <div className="waypoints-content">
                                {exRoute.waypoints_names[currentLocationIndex].name}(
                                {exRoute.waypoints_names[
                                  currentLocationIndex
                                ].address
                                  .replace(/〒\d{3}-\d{4}/, "")
                                  .replace(/〒\d{7}/, "")
                                  .replace("日本、", "")}
                                )
                              </div>
                            </Col>
                          </Row>
                        </div>
                      ) : (
                        <div className="waypoints-list">
                          {exRoute.waypoints_names &&
                            exRoute.waypoints_names.map((point, index) => {
                              const displayName =
                                point.name.split(":")[1] || point.name
                              return (
                                <React.Fragment key={index}>
                                  <Row className="waypoints-item">
                                    <Col xs="2" sm="1" md="1" lg="1">
                                      <strong className="waypoints-label">
                                        {getLabel(
                                          index,
                                          exRoute.waypoints_names.length
                                        )}
                                        :
                                      </strong>
                                    </Col>
                                    <Col xs="10" sm="11" md="11" lg="11">
                                      <div className="waypoints-content">
                                        {displayName}(
                                        {point.address
                                          .replace(/〒\d{3}-\d{4}/, "")
                                          .replace(/〒\d{7}/, "")
                                          .replace("日本、", "")}
                                        )
                                      </div>
                                    </Col>
                                  </Row>
                                  {index < exRoute.waypoints_names.length - 1 && (
                                    <Row className="arrow-row">
                                      <Col xs="12">
                                        <div style={{ fontSize: "1.4rem", textAlign: "center" }}>
                                          ↓
                                        </div>
                                      </Col>
                                    </Row>
                                  )}
                                </React.Fragment>
                              )
                            })}
                        </div>
                      )}

                      {/* ボタン:
                          「ロケーションに出発」ボタンが表示されたら handleDeparture
                          それ以外なら 従来 handleNextLocation */}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "20px",
                        }}
                      >
                        {buttonText === "ロケーションに出発" ? (
                          <Button color="info" onClick={handleDeparture}>
                            ロケーションに出発
                          </Button>
                        ) : (
                          <Button color="success" onClick={handleNextLocation}>
                            {buttonText}
                          </Button>
                        )}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          {showReturnItems && (
            <Row>
              <Col xs={12}>
                <Card className={tableStyle2} style={{ maxWidth: "950px" }}>
                  <CardBody>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <h5>戻し商品一覧</h5>
                      <Button
                        color="primary"
                        onClick={handleConfirmAll}
                        style={{ marginBottom: "10px" }}
                      >
                        一括確認
                      </Button>
                    </div>
                    <Table className="custom-table">
                      <thead>
                        <tr>
                          <th>商品名</th>
                          <th>個数</th>
                          <th>確認</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {returnItems.map((item, index) => (
                          <tr key={index}>
                            <td>{item.name}</td>
                            <td>
                              <Input
                                type="number"
                                min="0"
                                value={item.needed_quantity}
                                onChange={e =>
                                  handleQuantityChange(
                                    index,
                                    parseInt(e.target.value)
                                  )
                                }
                              />
                            </td>
                            <td>
                              <Button
                                color={
                                  confirmationStatus[index] ? "success" : "warning"
                                }
                                onClick={() => handleConfirmItem(index)}
                                disabled={exclusionStatus[index]}
                              >
                                {confirmationStatus[index] ? "確認済み" : "確認"}
                              </Button>
                            </td>
                            <td>
                              <Button
                                color={
                                  exclusionStatus[index] ? "danger" : "secondary"
                                }
                                onClick={() => handleExcludeItem(index)}
                                disabled={confirmationStatus[index]}
                              >
                                {exclusionStatus[index] ? "除外済み" : "除外"}
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20px",
                      }}
                    >
                      <Button
                        color="success"
                        onClick={() => handleNextLocation(2)}
                        disabled={
                          !confirmationStatus.every(status => status) &&
                          !exclusionStatus.every(status => status)
                        }
                      >
                        戻し完了
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          {/* Mapを非表示かつ 戻し商品画面でない場合: 補充処理など既存ロジック */}
          {!showMap && !showReturnItems && (
            <Row>
              <Col xs={12}>
                <Card className={tableStyle2} style={{ maxWidth: "950px" }}>
                  <CardBody>
                    {locationInfo && (
                      <div
                        style={{
                          textAlign: "center",
                          marginBottom: "20px",
                          fontSize: "18px",
                        }}
                      >
                        <strong>到着したロケーション情報:</strong>
                        <br />
                        <strong>名前:</strong>{" "}
                        {
                          deliveryPlanDetail.route.waypoints_names[
                            currentLocationIndex
                          ].name
                        }
                      </div>
                    )}

                    {showItems && aggregatedItems.length > 0 && (
                      <Row>
                        <Col md={6} lg={8} className="mx-auto">
                          <Card
                            className="mb-3"
                            style={{
                              border: "1px solid black",
                              backgroundColor: "#f8f9fa",
                            }}
                          >
                            <CardBody>
                              <h5>補充予定商品一覧</h5>
                              <p>商品を補充してください</p>
                              <Table className="custom-table">
                                <thead>
                                  <tr>
                                    <th>商品名</th>
                                    <th>必要数量</th>
                                    <th>確認</th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {aggregatedItems.map((item, index) => (
                                    <tr key={index}>
                                      <td>{item.column_no}_{item.name}</td>
                                      <td>
                                        <Input
                                          type="number"
                                          min="0"
                                          value={item.needed_quantity}
                                          onChange={e =>
                                            handleQuantityChange(
                                              index,
                                              parseInt(e.target.value)
                                            )
                                          }
                                        />
                                      </td>
                                      <td>
                                        <Button
                                          color={
                                            confirmationStatus[index]
                                              ? "success"
                                              : "warning"
                                          }
                                          onClick={() => handleConfirmItem(index)}
                                          disabled={exclusionStatus[index]}
                                        >
                                          {confirmationStatus[index]
                                            ? "確認済み"
                                            : "確認"}
                                        </Button>
                                      </td>
                                      <td>
                                        <Button
                                          color={
                                            exclusionStatus[index] ? "danger" : "secondary"
                                          }
                                          onClick={() => handleExcludeItem(index)}
                                          disabled={confirmationStatus[index]}
                                        >
                                          {exclusionStatus[index]
                                            ? "除外済み"
                                            : "除外"}
                                        </Button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    )}

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20px",
                      }}
                    >
                      <Button color="success" onClick={handleNextLocation}>
                        {buttonText}
                      </Button>
                    </div>

                    {showGraphData && results.length > 0 && (
                      <Row>
                        <Col md={6} lg={8} className="mx-auto">
                          <Card
                            className="mb-3"
                            style={{ border: "1px solid black" }}
                          >
                            <CardBody>
                              <h5>最新の補充データ</h5>
                              <Table>
                                <thead>
                                  <tr>
                                    <th>商品名</th>
                                    <th className="stocks-count">満ﾀﾝ</th>
                                    <th className="stocks-count">在庫</th>
                                    <th className="stocks-count">不足</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {results.map((result, index) => (
                                    <tr key={index}>
                                      <td>{result.goods_name}</td>
                                      <td className="stocks-count">
                                        {result.full_stock}
                                      </td>
                                      <td
                                        style={{
                                          color:
                                            result.current_stock <= 0
                                              ? "red"
                                              : "inherit",
                                          fontWeight:
                                            result.current_stock <= 0
                                              ? "bold"
                                              : "normal",
                                        }}
                                        className="stocks-count"
                                      >
                                        {result.current_stock}
                                      </td>
                                      <td className="stocks-count">
                                        {result.shortage}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    )}
                    {showGraphData && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "20px",
                        }}
                      >
                        <Button
                          color="primary"
                          onClick={() => {
                            if (
                              currentLocationIndex <
                              deliveryPlanDetail.locations.waypoints.length
                            ) {
                              setShowMap(true)
                              setCurrentLocationIndex(currentLocationIndex + 1)
                              handleNextLocation(1)
                              setButtonText("ロケーションに出発")
                            } else {
                              setShowMap(true)
                              setCurrentLocationIndex(currentLocationIndex + 1)
                              handleNextLocation(1)
                              setButtonText("ロケーションに出発")
                            }
                          }}
                        >
                          次のロケーションに移動する
                        </Button>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DeliveryPlanExecute
