import { get } from "../api_helper"
import * as url from "../url_helper"

export const getSyscheck = async () => {
  try {
    const response = await get(url.GET_SYSCHECK)
    return { data: response }
  } catch (error) {
    throw error
  }
} 