// actions.js
import {
  FETCH_GOODS_CATEGORY_GRAPH_DATA,
  FETCH_GOODS_CATEGORY_GRAPH_DATA_SUCCESS,
  FETCH_GOODS_CATEGORY_GRAPH_DATA_ERROR,
  FETCH_GOODS_CATEGORY_REG_DATA,
  FETCH_GOODS_CATEGORY_REG_DATA_SUCCESS,
  FETCH_GOODS_CATEGORY_REG_DATA_ERROR,
  UPDATE_GOODS,
} from "./actionTypes"

// Fetch graph data action
export const fetchGraphData = (goodsCategory, ipcs, timePeriod, currentDate) => {
  return {
    type: FETCH_GOODS_CATEGORY_GRAPH_DATA,
    payload: { goodsCategory, ipcs, timePeriod, currentDate },
  }
}

// Fetch graph data success action
export const fetchGraphDataSuccess = payload => {
  return {
    type: FETCH_GOODS_CATEGORY_GRAPH_DATA_SUCCESS,
    payload,
  }
}

// Fetch graph data error action
export const fetchGraphDataError = error => {
  return {
    type: FETCH_GOODS_CATEGORY_GRAPH_DATA_ERROR,
    error,
  }
}
// Fetch graph data action
export const fetchGoodsCategoryRegData = (
  targetGoods,
  category,
  categoryName,
  deleteFlg,
  createFlg
) => {
  return {
    type: FETCH_GOODS_CATEGORY_REG_DATA,
    payload: { targetGoods, category, categoryName, deleteFlg, createFlg },
  }
}

// Fetch graph data success action
export const fetchGoodsCategoryRegDataSuccess = payload => {
  return {
    type: FETCH_GOODS_CATEGORY_REG_DATA_SUCCESS,
    payload,
  }
}

// Fetch graph data error action
export const fetchGoodsCategoryRegDataError = error => {
  return {
    type: FETCH_GOODS_CATEGORY_REG_DATA_ERROR,
    error,
  }
}

// Update goods action
export const updateGoods = updatedGoods => ({
  type: UPDATE_GOODS,
  payload: updatedGoods,
})
