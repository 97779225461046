import { startOfMonth, subMonths, subYears } from "date-fns"
import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap"

import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { fetchGraphData } from "../../../store/marketing/customer/actions"
import Linechart from "../../AllCharts/chartist/dountchart"
import ErrorMessage from "../../Common/ErrorMessage"
import CommonComponent from "../../CommonComponent"

Linechart.defaultProps = {
  labels: [],
  data: [],
}

const MarketingCustomer = () => {
  const [goodsCategory, setGoodsCategory] = useState(null)
  const params = new URLSearchParams(location.search)
  const ipc_no_from_url = Number(params.get("ipc_no"))
  const storedLoc = Number(localStorage.getItem("selectedLoc"))
  const initialIpcsValue = ipc_no_from_url || storedLoc || ""
  const [locId, setLoc] = useState(initialIpcsValue)
  const [timePeriod, setTimePeriod] = useState("日")
  const [currentDate, setCurrentDate] = useState(new Date())
  const [vendor_id, setVendor] = useState(null)
  const [locGroup, setLocGroup] = useState(null)
  const graphData = useSelector(
    state => state.marketingCustomerReducer.graphData
  )
  const isLoading = useSelector(state => state.marketingCustomerReducer.loading)

  const dispatch = useDispatch()

  const gender_results =
    graphData && graphData.data ? graphData.data.gender_results : []
  const gender_labels = gender_results.length
    ? gender_results.map(item => item.labels)
    : []
  const gender_data = gender_results.length
    ? gender_results.map(item => item.data)
    : []

  const age_results =
    graphData && graphData.data ? graphData.data.age_results : []
  const age_labels = age_results.length
    ? age_results.map(item => item.labels)
    : []
  const age_data = age_results.length ? age_results.map(item => item.data) : []

  const previousDate = useMemo(() => {
    if (timePeriod === "月") {
      return startOfMonth(subYears(currentDate, 1))
    } else if (timePeriod === "日") {
      return startOfMonth(currentDate)
    } else if (timePeriod === "時") {
      return currentDate
    } else if (timePeriod === "3か月") {
      return startOfMonth(subMonths(currentDate, 3))
    }
  }, [timePeriod, currentDate])

  useEffect(() => {
    if (locId !== "" || locGroup !== null) {
      dispatch(
        fetchGraphData(goodsCategory, locId, timePeriod, currentDate, previousDate, locGroup)
      )
    }
  }, [goodsCategory, locId, currentDate, locGroup])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="ホーム" breadcrumbItem="購入者層" />
          <ErrorMessage />
          <Row>
            <Col xs={12}>
              <Card className="bg-secondary">
                <CardBody>
                  <h4 className="card-title mb-4">検索条件</h4>
                  {isLoading && (
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        zIndex: 9999, // 必要に応じて、他の要素より前面に表示されるように設定します
                      }}
                    >
                      <div style={{ color: "white", fontSize: "2em" }}>
                        Loading...
                      </div>
                    </div>
                  )}
                  <CommonComponent
                    goodsCategory={goodsCategory}
                    setGoodsCategory={setGoodsCategory}
                    locId={locId}
                    setLoc={setLoc}
                    setLocGroup={setLocGroup}
                    currentDate={currentDate}
                    setCurrentDate={setCurrentDate}
                    timePeriod={timePeriod}
                    setTimePeriod={setTimePeriod}
                    previousDate={previousDate}
                    vendor_id={vendor_id}
                    setVendor={setVendor}
                    past_loc={true}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Card>
                <CardBody>
                  <CardTitle>性別購入者層</CardTitle>
                  <Linechart labels={gender_labels} data={gender_data} />
                </CardBody>
              </Card>
            </Col>
            <Col xs={12} md={6}>
              <Card>
                <CardBody>
                  <CardTitle>年代別購入者層</CardTitle>
                  <Linechart labels={age_labels} data={age_data} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default MarketingCustomer
