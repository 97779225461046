import React, { useEffect, useState } from "react"
import { Container, Row, Col, Card, CardBody, Table, Modal, ModalHeader, ModalBody } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { getIpcErrors } from "../../store/ipc-error/actions"
import ErrorMessage from "../Common/ErrorMessage"
import LoadingOverlay from "../Common/LoadingOverlay"
import CommonComponent from "../CommonComponent"

const IpcError = () => {
  const dispatch = useDispatch()
  const { ipcErrorData, loading, error } = useSelector(
    state => state.ipcErrorReducer
  )

  const [locId, setLoc] = useState("")
  const [locGroup, setLocGroup] = useState(null)
  const [selectedError, setSelectedError] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    dispatch(getIpcErrors(locId))
  }, [dispatch, locId])

  // エラー詳細を表示する関数
  const handleErrorClick = (error) => {
    setSelectedError(error)
    setModalOpen(true)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            maintitle="Home"
            title="システム管理"
            breadcrumbItem="障害情報"
          />
          <ErrorMessage error={error} />
          <LoadingOverlay isLoading={loading} />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <h4>IPC障害情報</h4>

                  <CommonComponent
                    showGoodsCategory={false}
                    showDate={false}
                    showAll={true}
                    locId={locId}
                    setLoc={setLoc}
                    setLocGroup={setLocGroup}
                    past_loc={false}
                  />

                  {ipcErrorData && ipcErrorData.results && (
                    <div className="table-responsive">
                      <Table className="table table-striped mb-0">
                        <thead>
                          <tr>
                            <th>ロケーション名</th>
                            <th>IPC番号</th>
                            <th>エラーコード</th>
                            <th>エラー名</th>
                            <th>発生日時</th>
                            <th>ステータス</th>
                          </tr>
                        </thead>
                        <tbody>
                          {ipcErrorData.results.map((item, index) => (
                            <tr 
                              key={index}
                              onClick={() => handleErrorClick(item)}
                              style={{ cursor: 'pointer' }}
                            >
                              <td>{item.loc_name}</td>
                              <td>{item.ipc_no}</td>
                              <td>{item.error_code}</td>
                              <td>{item.error_name}</td>
                              <td>{new Date(item.error_time).toLocaleString()}</td>
                              <td>
                                <span className={`badge ${item.fix_status === 1 ? 'bg-success' : 'bg-danger'}`}>
                                  {item.fix_status === 1 ? '解決済み' : '未解決'}
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        {/* エラー詳細モーダル */}
        <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)} size="lg">
          <ModalHeader toggle={() => setModalOpen(!modalOpen)}>
            障害詳細情報
          </ModalHeader>
          <ModalBody>
            {selectedError && (
              <Table className="table-bordered">
                <tbody>
                  <tr>
                    <th width="30%">ロケーション名</th>
                    <td>{selectedError.loc_name}</td>
                  </tr>
                  <tr>
                    <th>IPC番号</th>
                    <td>{selectedError.ipc_no}</td>
                  </tr>
                  <tr>
                    <th>IPC名</th>
                    <td>{selectedError.ipc_name}</td>
                  </tr>
                  <tr>
                    <th>機器アドレス</th>
                    <td>{selectedError.machine_address}</td>
                  </tr>
                  <tr>
                    <th>カラム番号</th>
                    <td>{selectedError.column_no}</td>
                  </tr>
                  <tr>
                    <th>エラーコード</th>
                    <td>{selectedError.error_code}</td>
                  </tr>
                  <tr>
                    <th>エラー名</th>
                    <td>{selectedError.error_name}</td>
                  </tr>
                  <tr>
                    <th>対処方法</th>
                    <td>{selectedError.how_to_fix}</td>
                  </tr>
                  <tr>
                    <th>ステータス</th>
                    <td>
                      <span className={`badge ${selectedError.fix_status === 1 ? 'bg-success' : 'bg-danger'}`}>
                        {selectedError.fix_status === 1 ? '解決済み' : '未解決'}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th>エラー発生時刻</th>
                    <td>{new Date(selectedError.error_time).toLocaleString()}</td>
                  </tr>
                  <tr>
                    <th>解決時刻</th>
                    <td>{selectedError.fix_time ? new Date(selectedError.fix_time).toLocaleString() : '-'}</td>
                  </tr>
                  <tr>
                    <th>送信ステータス</th>
                    <td>{selectedError.send_status === 1 ? '送信済み' : '未送信'}</td>
                  </tr>
                  <tr>
                    <th>送信時刻</th>
                    <td>{selectedError.send_time ? new Date(selectedError.send_time).toLocaleString() : '-'}</td>
                  </tr>
                </tbody>
              </Table>
            )}
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  )
}

export default IpcError 