export const DELIVERY_PLAN_REQUEST = "DELIVERY_PLAN_REQUEST"
export const DELIVERY_PLAN_SUCCESS = "DELIVERY_PLAN_SUCCESS"
export const DELIVERY_PLAN_ERROR = "DELIVERY_PLAN_ERROR"

export const FETCH_ITEMS_FOR_LOADING_REQUEST = "FETCH_ITEMS_FOR_LOADING_REQUEST"
export const FETCH_ITEMS_FOR_LOADING_SUCCESS = "FETCH_ITEMS_FOR_LOADING_SUCCESS"
export const FETCH_ITEMS_FOR_LOADING_ERROR = "FETCH_ITEMS_FOR_LOADING_ERROR"

export const FETCH_DELIVERY_PLANS_REQUEST = "FETCH_DELIVERY_PLANS_REQUEST"
export const FETCH_DELIVERY_PLANS_SUCCESS = "FETCH_DELIVERY_PLANS_SUCCESS"
export const FETCH_DELIVERY_PLANS_ERROR = "FETCH_DELIVERY_PLANS_ERROR"

export const UPDATE_DELIVERY_PLAN_REQUEST = "UPDATE_DELIVERY_PLAN_REQUEST"
export const UPDATE_DELIVERY_PLAN_SUCCESS = "UPDATE_DELIVERY_PLAN_SUCCESS"
export const UPDATE_DELIVERY_PLAN_ERROR = "UPDATE_DELIVERY_PLAN_ERROR"

export const FETCH_DELIVERY_PLAN_DETAIL_REQUEST =
  "FETCH_DELIVERY_PLAN_DETAIL_REQUEST"
export const FETCH_DELIVERY_PLAN_DETAIL_SUCCESS =
  "FETCH_DELIVERY_PLAN_DETAIL_SUCCESS"
export const FETCH_DELIVERY_PLAN_DETAIL_ERROR =
  "FETCH_DELIVERY_PLAN_DETAIL_ERROR"

export const DELETE_DELIVERY_PLAN_REQUEST = "DELETE_DELIVERY_PLAN_REQUEST"
export const DELETE_DELIVERY_PLAN_SUCCESS = "DELETE_DELIVERY_PLAN_SUCCESS"
export const DELETE_DELIVERY_PLAN_ERROR = "DELETE_DELIVERY_PLAN_ERROR"

export const FETCH_INCOMPLETE_DELIVERY_PLANS_COUNT_REQUEST = "FETCH_INCOMPLETE_DELIVERY_PLANS_COUNT_REQUEST"
export const FETCH_INCOMPLETE_DELIVERY_PLANS_COUNT_SUCCESS = "FETCH_INCOMPLETE_DELIVERY_PLANS_COUNT_SUCCESS"
export const FETCH_INCOMPLETE_DELIVERY_PLANS_COUNT_ERROR = "FETCH_INCOMPLETE_DELIVERY_PLANS_COUNT_ERROR"
