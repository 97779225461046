import {
  DELIVERY_PLAN_REQUEST,
  DELIVERY_PLAN_SUCCESS,
  DELIVERY_PLAN_ERROR,
  FETCH_ITEMS_FOR_LOADING_REQUEST,
  FETCH_ITEMS_FOR_LOADING_SUCCESS,
  FETCH_ITEMS_FOR_LOADING_ERROR,
  FETCH_DELIVERY_PLANS_REQUEST,
  FETCH_DELIVERY_PLANS_SUCCESS,
  FETCH_DELIVERY_PLANS_ERROR,
  UPDATE_DELIVERY_PLAN_REQUEST,
  UPDATE_DELIVERY_PLAN_SUCCESS,
  UPDATE_DELIVERY_PLAN_ERROR,
  FETCH_DELIVERY_PLAN_DETAIL_REQUEST,
  FETCH_DELIVERY_PLAN_DETAIL_SUCCESS,
  FETCH_DELIVERY_PLAN_DETAIL_ERROR,
  DELETE_DELIVERY_PLAN_REQUEST,
  DELETE_DELIVERY_PLAN_SUCCESS,
  DELETE_DELIVERY_PLAN_ERROR,
  FETCH_INCOMPLETE_DELIVERY_PLANS_COUNT_REQUEST,
  FETCH_INCOMPLETE_DELIVERY_PLANS_COUNT_SUCCESS,
  FETCH_INCOMPLETE_DELIVERY_PLANS_COUNT_ERROR,  
} from "./actionTypes"

const initialState = {
  loading: false,
  data: null,
  items: [],
  deliveryPlans: [],
  deliveryPlanDetail: null, // `deliveryPlanDetail` を初期化
  incompleteDeliveryPlansCount: 0,
  error: null,
}

const deliveryPlanReducer = (state = initialState, action) => {
  switch (action.type) {
    case DELIVERY_PLAN_REQUEST:
    case FETCH_ITEMS_FOR_LOADING_REQUEST:
    case FETCH_DELIVERY_PLANS_REQUEST:
    case UPDATE_DELIVERY_PLAN_REQUEST:
    case FETCH_DELIVERY_PLAN_DETAIL_REQUEST:
    case DELETE_DELIVERY_PLAN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case DELIVERY_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      }
    case FETCH_ITEMS_FOR_LOADING_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
      }
    case FETCH_DELIVERY_PLANS_SUCCESS:
      return {
        ...state,
        loading: false,
        deliveryPlans: action.payload,
      }
    case UPDATE_DELIVERY_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        deliveryPlanDetail: action.payload,
      }
    case FETCH_DELIVERY_PLAN_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        deliveryPlanDetail: action.payload,
      }
    case DELETE_DELIVERY_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        deliveryPlans: action.payload.plans,
      }
    case DELIVERY_PLAN_ERROR:
    case FETCH_ITEMS_FOR_LOADING_ERROR:
    case FETCH_DELIVERY_PLANS_ERROR:
    case UPDATE_DELIVERY_PLAN_ERROR:
    case FETCH_DELIVERY_PLAN_DETAIL_ERROR:
    case DELETE_DELIVERY_PLAN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case FETCH_INCOMPLETE_DELIVERY_PLANS_COUNT_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      }
    case FETCH_INCOMPLETE_DELIVERY_PLANS_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        incompleteDeliveryPlansCount: action.payload,
        error: '',
      }
    case FETCH_INCOMPLETE_DELIVERY_PLANS_COUNT_ERROR:
      return {
        ...state,
        loading: false,
        incompleteDeliveryPlansCount: 0,
        error: action.error,
      }      
    default:
      return state
  }
}

export default deliveryPlanReducer
