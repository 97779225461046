// api/authApi.js

import { get } from "../api_helper"
import * as url from "../url_helper"

// Login Method
const getMarketingVolume = async (
  goodsCategory,
  locId,
  timePeriod,
  currentDate,
  previousDate,
  locGroup
) => {
  try {
    let re_timePeriod = ""
    if (timePeriod === "月") {
      re_timePeriod = "year"
    } else if (timePeriod === "日") {
      re_timePeriod = "month"
    } else if (timePeriod === "時") {
      re_timePeriod = "day"
    } else if (timePeriod === "3か月") {
      re_timePeriod = "threeMonth"
    }

    const currentDateStr = formatDate(currentDate)
    const previousDateStr = formatDate(previousDate)

    const queryParams = new URLSearchParams()

    if (goodsCategory) {
      queryParams.append("goodsCategory", goodsCategory)
    }
    if (locId) {
      queryParams.append("locId", locId)
    }
    if (locGroup) {
      queryParams.append("location_group", locGroup)
    }
    queryParams.append("timePeriod", re_timePeriod)
    queryParams.append("currentDate", currentDateStr)
    queryParams.append("previousDate", previousDateStr)

    const response = await get(
      `${url.GET_MARKETING_VOLUME}?${queryParams.toString()}`,
    )
    return { data: response }
  } catch (error) {
    throw error
  }
}

function formatDate(date) {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, "0")
  const day = String(date.getDate()).padStart(2, "0")

  return `${year}-${month}-${day}`
}

export { getMarketingVolume }
