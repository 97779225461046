import {
  GET_SYSCHECK,
  GET_SYSCHECK_SUCCESS,
  GET_SYSCHECK_FAIL,
} from "./actionTypes"

export const getSyscheck = () => ({
  type: GET_SYSCHECK,
})

export const getSyscheckSuccess = (data) => ({
  type: GET_SYSCHECK_SUCCESS,
  payload: data,
})

export const getSyscheckFail = (error) => ({
  type: GET_SYSCHECK_FAIL,
  payload: error,
}) 