// src/pages/DeliveryPlan/labelUtils.js

/**
 * グローバルインデックスと全体数を使ってラベルを決定するサンプル関数
 * @param {number} globalIndex - 配送計画全体におけるこの地点のインデックス (0-based)
 * @param {number} totalWaypoints - 全体のwaypoints数
 * @returns {string} "S" / "E" / それ以外は数字
 */
export function getLabel(globalIndex, totalWaypoints) {
  if (globalIndex === 0) {
    return "S"
  } else if (globalIndex === totalWaypoints - 1) {
    return "E"
  } else {
    // 例: 1-based数字にするには (globalIndex) → (globalIndex) or (globalIndex+1)など
    return String(globalIndex) // "1", "2", ...
  }
}
