// api/authApi.js

import { get, put } from "../api_helper"
import * as url from "../url_helper"

// Login Method
const getStocks = async (
  goodsCategory,
  locId,
  timePeriod,
  currentDate,
  previousDate,
) => {
  try {
    let re_timePeriod = ""
    if (timePeriod === "月") {
      re_timePeriod = "year"
    } else if (timePeriod === "日") {
      re_timePeriod = "month"
    } else if (timePeriod === "時") {
      re_timePeriod = "day"
    } else if (timePeriod === "3か月") {
      re_timePeriod = "threeMonth"
    }

    const currentDateStr = formatDate(currentDate)
    const previousDateStr = formatDate(previousDate)

    const queryParams = new URLSearchParams()

    if (goodsCategory) {
      queryParams.append("goodsCategory", goodsCategory)
    }
    if (locId) {
      queryParams.append("locId", locId)
    }
    queryParams.append("timePeriod", re_timePeriod)
    queryParams.append("currentDate", currentDateStr)
    queryParams.append("previousDate", previousDateStr)

    const response = await get(`${url.GET_STOCKS}?${queryParams.toString()}`)
    return { data: response }
  } catch (error) {
    throw error
  }
}

const putStocks = async (item, locId) => {
  const response = await put(url.PUT_STOCKS, {
    goods_name: item.goods_name,
    stock: item.stockValue,
    current_stock: item.current_stock,    
    full_stock: item.full_stock,
    product_expire_date: item.product_expire_date,
    loc_id: locId,
    switch_goods_name: item.switchGoodsName,
    switch_goods_no: item.switchGoodsNo,
    switch_price: item.switchPrice,
    switch_stock: item.switchStockValue,
    switch_full_stock: item.switchFullValue,
    machine_address: item.machine_address,
    max_stay_days: item.max_stay_days,
    max_stay_flag: item.max_stay_flag
  })

  return { data: response }
}

function formatDate(date) {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, "0")
  const day = String(date.getDate()).padStart(2, "0")

  return `${year}-${month}-${day}`
}

export { getStocks, putStocks }
