// src/pages/DeliveryPlan/DeliveryPlanShow.js

import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  fetchDeliveryPlansRequest,
  updateDeliveryPlanRequest,
  deleteDeliveryPlanRequest,
} from "../../store/deliveryPlan/actions";
import ErrorMessage from "../Common/ErrorMessage";
import LoadingOverlay from "../Common/LoadingOverlay";
import {
  GoogleMap,
  useJsApiLoader,
} from "@react-google-maps/api";
import { decode } from "@googlemaps/polyline-codec";
import { googleMapsConfig } from "./googleMapsConfig";
import { useNavigate } from "react-router-dom";
import { getLabel } from "./labelUtils";
import CustomGoogleMap from "./CustomGoogleMap";
import "./App.css"; // 必要に応じて

const formatDateTime = (dateTimeString) => {
  if (!dateTimeString) return "";
  const date = new Date(dateTimeString);
  return new Intl.DateTimeFormat("ja-JP", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  }).format(date);
};

const DeliveryPlanShow = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLoadingPlans = useSelector((state) => state.deliveryPlanReducer.loading);
  const deliveryPlans =
    useSelector((state) => state.deliveryPlanReducer.deliveryPlans) || [];
  const deliveryPlanDetail =
    useSelector((state) => state.deliveryPlanReducer.deliveryPlanDetail) || {};

  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [decodedRoute, setDecodedRoute] = useState([]);
  const mapRef = useRef(null);
  const [isResetModalOpen, setIsResetModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  // ローカルストレージから取得したユーザー名
  const [username, setUsername] = useState(null);

  // 「自分の担当分のみ表示」チェックボックスの状態
  const [onlyMyPlans, setOnlyMyPlans] = useState(true);

  useEffect(() => {
    dispatch(fetchDeliveryPlansRequest({ type: 3 }));
  }, [dispatch]);

  useEffect(() => {
    if (deliveryPlanDetail && deliveryPlanDetail.id > 0) {
      dispatch(fetchDeliveryPlansRequest({ type: 3 }));
    }
  }, [deliveryPlanDetail, dispatch]);

  useEffect(() => {
    // ローカルストレージからユーザー名を取得
    const authUserString = localStorage.getItem("authUser");
    if (authUserString) {
      const obj = JSON.parse(authUserString);
      if (obj?.name) {
        setUsername(obj.name);
      }
    }
  }, []);

  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const toggleResetModal = () => setIsResetModalOpen(!isResetModalOpen);
  const toggleDeleteModal = () => setIsDeleteModalOpen(!isDeleteModalOpen);

  const handleShowDetails = (plan) => {
    setSelectedPlan(plan);
    if (plan.route) {
      const decodedPath = decode(plan.route.route);
      setDecodedRoute(decodedPath);
    }
    toggleModal();
  };

  const handleResetPlan = () => {
    if (selectedPlan) {
      const updatedPlan = { ...selectedPlan, status: 1 };
      dispatch(updateDeliveryPlanRequest({ delivery_plan: updatedPlan }));
      toggleResetModal();
      toggleModal();
    }
  };

  const handleDeletePlan = () => {
    if (selectedPlan) {
      dispatch(deleteDeliveryPlanRequest({ id: selectedPlan.id }));
      toggleDeleteModal();
      toggleModal();
    }
  };

  // ロケーション追加
  const handleAddLocation = (plan) => {
    navigate("/delivery-plan/create", {
      state: {
        startWarehouseId: plan.start_warehouse_id,
        endWarehouseId: plan.end_warehouse_id,
        selectedLocations: plan.locations.waypoints.slice(1, -1).map((wp) => wp.id),
        startDate: plan.start_date,
        endDate: plan.end_date,
        originalPlanId: plan.id,
        locations: plan.locations.waypoints,
        selectedUser: { value: plan.user_id, label: plan.user_name },
      },
    });
  };

  // 商品積込
  const handleLoadItem = (plan) => {
    navigate("/delivery-plan/confirm", {
      state: {
        deliveryPlan: plan,
      },
    });
  };

  // チェックボックスを切り替えたら onlyMyPlans を更新
  const handleOnlyMyPlansChange = (e) => {
    setOnlyMyPlans(e.target.checked);
  };

  // フィルタ後の計画リストを作成
  const filteredPlans = onlyMyPlans
    ? deliveryPlans.filter((plan) => plan.user_name === username)
    : deliveryPlans;

  /* ★★★ 新しい計画順 (IDが大きい順) にソート ★★★ */
  const sortedPlans = [...filteredPlans].sort((a, b) => b.id - a.id);

  const { isLoaded, loadError } = useJsApiLoader(googleMapsConfig);

  if (loadError) {
    return <div>Error loading Google Maps</div>;
  }

  const tableStyle = window.innerWidth < 500 ? "p-0" : "mx-auto p-3";
  const tableStyle2 =
    window.innerWidth < 500 ? "p-0 bg-secondary" : "mx-auto p-3 bg-secondary";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="ホーム" breadcrumbItem="配送計画選択" />
          <ErrorMessage />
          <LoadingOverlay isLoading={isLoadingPlans} />

          <Row>
            <Col xs={12}>
              <Card className={tableStyle2} style={{ maxWidth: "950px" }}>
                <CardBody>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h2 style={{ margin: 0 }}>配送計画一覧</h2>
                    <FormGroup check inline>
                      <Input
                        type="checkbox"
                        id="onlyMyPlansCheck"
                        checked={onlyMyPlans}
                        onChange={handleOnlyMyPlansChange}
                      />
                      <Label for="onlyMyPlansCheck" className="mb-0">
                        担当分のみ表示
                      </Label>
                    </FormGroup>
                  </div>
                  {sortedPlans.length > 0 ? (
                    <div className="delivery-plan-table">
                      {/* ヘッダー */}
                      <div className="table-header">
                        <div className="table-row">
                          <div className="table-cell">計画No</div>
                          <div className="table-cell">担当者</div>
                          <div className="table-cell">ステータス</div>
                          <div className="table-cell d-none d-md-block">開始時間</div>
                          <div className="table-cell d-none d-md-block">終了時間</div>
                          <div className="table-cell d-none d-md-block">実績開始時間</div>
                          <div className="table-cell d-none d-md-block">実績終了時間</div>
                          <div className="table-cell">操作</div>
                        </div>
                      </div>
                      {/* ボディ */}
                      <div className="table-body">
                        {sortedPlans.map((plan, index) => (
                          <div
                            key={index}
                            className="table-row"
                          >
                            {/* 左に赤いマークを付ける部分 */}
                            <div className="table-cell plan-id-cell">
                              {plan.status === 1 && (
                                <span className="new-plan-indicator" />
                              )}
                              {plan.id}
                            </div>
                            <div className="table-cell">{plan.user_name}</div>
                            <div className="table-cell">{plan.status_name}</div>
                            <div className="table-cell d-none d-md-block">
                              {formatDateTime(plan.start_date)}
                            </div>
                            <div className="table-cell d-none d-md-block">
                              {formatDateTime(plan.end_date)}
                            </div>
                            <div className="table-cell d-none d-md-block">
                              {formatDateTime(plan.actual_start_date)}
                            </div>
                            <div className="table-cell d-none d-md-block">
                              {formatDateTime(plan.actual_end_date)}
                            </div>
                            <div className="table-cell">
                              <Button
                                color="success"
                                onClick={() => handleShowDetails(plan)}
                              >
                                詳細
                              </Button>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <p>割り当てられた配送計画がありません。</p>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        {/* 以下、モーダル部分はそのまま */}
        {selectedPlan && (
          <Modal isOpen={isModalOpen} toggle={toggleModal} size="lg">
            <ModalHeader toggle={toggleModal}>配送計画詳細</ModalHeader>
            <ModalBody>
              <h5>計画No: {selectedPlan.id}</h5>
              <h5>担当者: {selectedPlan.user_name}</h5>
              <h5>ステータス: {selectedPlan.status_name}</h5>
              <div className="d-flex justify-content-end mb-3">
                {selectedPlan && selectedPlan.status < 5 && selectedPlan.status >= 2 && (
                  <Button
                    color="warning"
                    size="sm"
                    className="mr-2"
                    onClick={toggleResetModal}
                  >
                    未実施に戻す
                  </Button>
                )}
                <Button
                  color="danger"
                  size="sm"
                  onClick={toggleDeleteModal}
                >
                  削除
                </Button>
              </div>
              <div className="delivery-plan-detail-table">
                <div className="table-header">
                  <div className="table-row">
                    <div className="table-cell">#</div>
                    <div className="table-cell">地点名</div>
                    <div className="table-cell d-none d-md-block">移動開始時間</div>
                    <div className="table-cell d-none d-md-block">到着時間</div>
                    <div className="table-cell d-none d-md-block">補充開始時間</div>
                    <div className="table-cell d-none d-md-block">補充終了時間</div>
                  </div>
                </div>
                <div className="table-body">
                  {selectedPlan.locations.waypoints.map((location, index) => (
                    <div key={index} className="table-row">
                      <div className="table-cell">
                        {getLabel(index, selectedPlan.locations.waypoints.length)}
                      </div>
                      <div className="table-cell">{location.name}</div>
                      <div className="table-cell d-none d-md-block">
                        {formatDateTime(location.start_moving_time)}
                      </div>
                      <div className="table-cell d-none d-md-block">
                        {formatDateTime(location.end_moving_time)}
                      </div>
                      <div className="table-cell d-none d-md-block">
                        {formatDateTime(location.start_refilling_time)}
                      </div>
                      <div className="table-cell d-none d-md-block">
                        {formatDateTime(location.end_refilling_time)}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {isLoaded && !loadError && (
                <CustomGoogleMap
                  decodedRoute={decodedRoute}
                  waypointsInfo={selectedPlan.route?.waypoints_info}
                  waypointsNames={selectedPlan.route?.waypoints_names}
                  completedLocations={[]} // 必要に応じて設定
                  mapId="b163a3409a584201"
                />
              )}
            </ModalBody>
            <ModalFooter>
              {selectedPlan.status === 1 && selectedPlan.user_name === username && (
                <Button
                  color="primary"
                  style={{ minWidth: "100px" }}
                  onClick={() => handleLoadItem(selectedPlan)}
                >
                  商品積込
                </Button>
              )}
              <Button
                color="secondary"
                onClick={toggleModal}
                style={{ minWidth: "100px" }}
              >
                閉じる
              </Button>
            </ModalFooter>
          </Modal>
        )}

        <Modal isOpen={isDeleteModalOpen} toggle={toggleDeleteModal}>
          <ModalHeader toggle={toggleDeleteModal}>確認</ModalHeader>
          <ModalBody>この配送計画を削除してもよろしいですか？</ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              onClick={handleDeletePlan}
              style={{ minWidth: "100px" }}
            >
              削除
            </Button>{" "}
            <Button
              color="secondary"
              onClick={toggleDeleteModal}
              style={{ minWidth: "100px" }}
            >
              キャンセル
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={isResetModalOpen} toggle={toggleResetModal}>
          <ModalHeader toggle={toggleResetModal}>確認</ModalHeader>
          <ModalBody>
            既にこの配送計画は実施中ですが、未実施に戻しますか？
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={handleResetPlan}
              style={{ minWidth: "100px" }}
            >
              OK
            </Button>{" "}
            <Button
              color="secondary"
              onClick={toggleResetModal}
              style={{ minWidth: "100px" }}
            >
              キャンセル
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default DeliveryPlanShow;
