import { takeLatest, put, call } from "redux-saga/effects"
import { GET_SYSCHECK } from "./actionTypes"
import { getSyscheckSuccess, getSyscheckFail } from "./actions"
import { getSyscheck } from "../../helpers/api/syscheckApi"

function* fetchSyscheck() {
  try {
    const response = yield call(getSyscheck)
    yield put(getSyscheckSuccess(response.data))
  } catch (error) {
    yield put(getSyscheckFail(error.message))
  }
}

function* syscheckSaga() {
  yield takeLatest(GET_SYSCHECK, fetchSyscheck)
}

export default syscheckSaga 