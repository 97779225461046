import {
  GET_SYSCHECK,
  GET_SYSCHECK_SUCCESS,
  GET_SYSCHECK_FAIL,
} from "./actionTypes"

const initialState = {
  syscheckData: null,
  loading: false,
  error: null,
}

const syscheckReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SYSCHECK:
      return {
        ...state,
        loading: true,
        error: null,
      }

    case GET_SYSCHECK_SUCCESS:
      return {
        ...state,
        loading: false,
        syscheckData: action.payload,
      }

    case GET_SYSCHECK_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}

export default syscheckReducer 