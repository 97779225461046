export const FETCH_MARKETING_COMMON_GOODS_CATEGORY_DATA =
  "FETCH_MARKETING_COMMON_GOODS_CATEGORY_DATA"
export const FETCH_MARKETING_COMMON_GOODS_CATEGORY_DATA_SUCCESS =
  "FETCH_MARKETING_COMMON_GOODS_CATEGORY_DATA_SUCCESS"
export const FETCH_MARKETING_COMMON_GOODS_CATEGORY_DATA_ERROR =
  "FETCH_MARKETING_COMMON_GOODS_CATEGORY_DATA_ERROR"

export const FETCH_MARKETING_COMMON_IPCS_DATA =
  "FETCH_MARKETING_COMMON_IPCS_DATA"
export const FETCH_MARKETING_COMMON_IPCS_DATA_SUCCESS =
  "FETCH_MARKETING_COMMON_IPCS_DATA_SUCCESS"
export const FETCH_MARKETING_COMMON_IPCS_DATA_ERROR =
  "FETCH_MARKETING_COMMON_IPCS_DATA_ERROR"

export const FETCH_IPCS_DATA_DETAIL = "FETCH_IPCS_DATA_DETAIL"
export const FETCH_IPCS_DATA_DETAIL_SUCCESS = "FETCH_IPCS_DATA_DETAIL_SUCCESS"
export const FETCH_IPCS_DATA_DETAIL_ERROR = "FETCH_IPCS_DATA_DETAIL_ERROR"

export const UPDATE_IPCS_DATA = "UPDATE_IPCS_DATA"
export const UPDATE_IPCS_DATA_SUCCESS = "UPDATE_IPCS_DATA_SUCCESS"
export const UPDATE_IPCS_DATA_ERROR = "UPDATE_IPCS_DATA_ERROR"
