import React, { useEffect } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { getSyscheck } from "../../store/syscheck/actions"
import ErrorMessage from "../Common/ErrorMessage"
import LoadingOverlay from "../Common/LoadingOverlay"

const Syscheck = () => {
  const dispatch = useDispatch()
  const { syscheckData, loading, error } = useSelector(state => state.syscheckReducer)

  // テスト用のデータ
  const testData = {
    sales: {
      status: "正常",
      lastUpdate: "2024-01-10 10:30:00",
      details: [
        { name: "売上データ取得", status: "OK", message: "最新データ取得済み" },
        { name: "売上集計処理", status: "OK", message: "正常に完了" },
        { name: "売上同期処理", status: "OK", message: "同期完了" }
      ]
    },
    ipcs: {
      status: "警告",
      lastUpdate: "2024-01-10 10:25:00",
      details: [
        { name: "IPCSデータ取得", status: "Warning", message: "3台接続エラー" }
      ]
    },
    stocks: {
      status: "正常",
      lastUpdate: "2024-01-10 10:28:00",
      details: [
        { name: "在庫データ更新", status: "OK", message: "更新完了" },
        { name: "在庫履歴記録", status: "OK", message: "記録完了" },
        { name: "在庫同期処理", status: "OK", message: "同期完了" }
      ]
    }
  }

  useEffect(() => {
    // 既存のAPI呼び出しは維持
    dispatch(getSyscheck())
  }, [dispatch])

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case "ok":
      case "正常":
        return "success"
      case "warning":
      case "警告":
        return "warning"
      case "error":
      case "エラー":
        return "danger"
      default:
        return "primary"
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="システム管理" breadcrumbItem="システムチェック" />
          <ErrorMessage />
          <LoadingOverlay isLoading={loading} />
          
          {Object.entries(testData).map(([system, data]) => (
            <Row key={system} className="mb-4">
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <div className="d-flex align-items-center">
                        <h4 className="card-title mb-0 me-3">{system.toUpperCase()}システム</h4>
                        <small className="text-muted">
                          {system === 'sales' && '(バッチ起動間隔: 5分)'}
                          {system === 'ipcs' && '(バッチ起動間隔: 40分)'}
                          {system === 'stocks' && '(バッチ起動間隔: 8分)'}
                        </small>
                      </div>
                      <div>
                        <span className={`badge bg-${getStatusColor(data.status)} me-2`}>
                          {data.status}
                        </span>
                        <small className="text-muted">
                          最終更新: {data.lastUpdate}
                        </small>
                      </div>
                    </div>
                    
                    {data.details.map((detail, index) => (
                      <div 
                        key={index} 
                        className="d-flex justify-content-between align-items-center p-2"
                        style={{ 
                          borderBottom: index < data.details.length - 1 ? '1px solid #eee' : 'none'
                        }}
                      >
                        <div>
                          <strong>{detail.name}</strong>
                          <p className="text-muted mb-0">{detail.message}</p>
                        </div>
                        <span className={`badge bg-${getStatusColor(detail.status)}`}>
                          {detail.status}
                        </span>
                      </div>
                    ))}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ))}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Syscheck 