import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

// パンくずリスト
import Breadcrumbs from "../../components/Common/Breadcrumb";

// Redux アクション
import { getGoodsMaster, registerGoodsMaster } from "../../store/goods-master/actions";

// 共通コンポーネント
import ErrorMessage from "../Common/ErrorMessage";
import LoadingOverlay from "../Common/LoadingOverlay";
import CommonComponent from "../CommonComponent";

const GoodsMaster = () => {
  const dispatch = useDispatch();

  const {
    goodsMasterData,
    loading,
    error,
    registerLoading,
    registerError,
    registerSuccess,
  } = useSelector((state) => state.goodsMasterReducer);

  const [goodsCategory, setGoodsCategory] = useState(null);
  const [locId, setLoc] = useState("");
  const [locGroup, setLocGroup] = useState(null);

  const [filterType, setFilterType] = useState("ALL");
  const [includeNormal, setIncludeNormal] = useState(false);

  const [warningModal, setWarningModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  useEffect(() => {
    dispatch(getGoodsMaster(goodsCategory, locId, includeNormal));
  }, [dispatch, goodsCategory, locId, includeNormal]);

  useEffect(() => {
    if (registerSuccess) {
      setSuccessModal(true);
      dispatch(getGoodsMaster(goodsCategory, locId, includeNormal));
    }
  }, [registerSuccess, dispatch, goodsCategory, locId, includeNormal]);

  const handleRegister = (item) => {
    if (!item.stock_goods_name || !item.stock_goods_no) {
      setWarningModal(true);
      return;
    }
    setCurrentItem(item);
    setConfirmModal(true);
  };

  const confirmRegister = () => {
    if (!currentItem.stock_goods_name || !currentItem.stock_goods_no) {
      setWarningModal(true);
      setConfirmModal(false);
      return;
    }
    dispatch(registerGoodsMaster({ ...currentItem, location_id: currentItem.loc_id }));
    setConfirmModal(false);
  };
  
  const getStyle = (a, b) =>
    !a && b ? { backgroundColor: "#ffffcc" } : a && b && a !== b ? { backgroundColor: "#ffffcc" } : {};

  const filteredResults = goodsMasterData?.results?.filter((item) => {
    // locId が選択されている場合は、item.loc_id と一致するか確認
    if (locId && item.loc_id !== locId) return false;

    if (!includeNormal && !item.mismatch_type) return false;
    switch (filterType) {
      case "商品番号不一致":
        return item.mismatch_type === "商品番号不一致";
      case "商品番号未登録":
        return item.mismatch_type === "商品番号未登録";
      case "商品名不一致":
        return item.mismatch_type === "商品名不一致";
      case "商品マスタ未登録":
        return item.mismatch_type === "商品マスタ未登録";
      default:
        return true;
    }
  }) || [];

  const sortedResults = filteredResults.slice().sort((a, b) => {
    const ipcA = (a.ipc_name || "").toLowerCase();
    const ipcB = (b.ipc_name || "").toLowerCase();
    if (ipcA < ipcB) return -1;
    if (ipcA > ipcB) return 1;
    return (Number(a.column_no) || 0) - (Number(b.column_no) || 0);
  });

  // 対象自販機を選択する関数
  const handleSelectMachine = (item) => {
    // CommonComponent に渡している検索条件（ここでは locId ）を更新
    setLoc(item.loc_id);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs maintitle="Home" title="システム管理" breadcrumbItem="商品情報設定の確認" />

          <ErrorMessage error={error || registerError} />
          <LoadingOverlay isLoading={loading || registerLoading} />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CommonComponent
                    showGoodsCategory={false}
                    showDate={false}
                    showAll={true}
                    goodsCategory={goodsCategory}
                    setGoodsCategory={setGoodsCategory}
                    locId={locId}
                    setLoc={setLoc}
                    setLocGroup={setLocGroup}
                    showLocGroup={false}
                    past_loc={false}
                  />

                  <Row className="mt-2 align-items-center">
                    <Col sm={12} md={12} className="d-flex align-items-center">
                      <span className="mr-2">フィルター：</span>
                      <select
                        value={filterType}
                        onChange={(e) => setFilterType(e.target.value)}
                        className="form-control w-auto mr-3"
                      >
                        <option value="ALL">すべて</option>
                        <option value="商品番号不一致">商品番号不一致</option>
                        <option value="商品番号未登録">商品番号未登録</option>
                        <option value="商品名不一致">商品名不一致</option>
                        <option value="商品マスタ未登録">商品マスタ未登録</option>
                      </select>
                      <FormGroup check className="mb-0">
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={includeNormal}
                            onChange={(e) => setIncludeNormal(e.target.checked)}
                          /> 一致しているレコードも表示する
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>

                  <table className="table table-bordered mt-3">
                    <thead className="thead-light">
                      <tr>
                        <th>種別</th>
                        <th>IPC番号</th>
                        <th>IPC名(ロケーション)</th>
                        <th>カラム</th>
                        <th>クラウド商品番号</th>
                        <th>クラウド商品名</th>
                        <th>マスタ商品番号</th>
                        <th>マスタ商品名</th>
                        <th>操作</th>
                      </tr>
                    </thead>
                    <tbody>
                      {sortedResults.map((item, idx) => (
                        <tr key={idx}>
                          <td>{item.mismatch_type || "-"}</td>
                          <td
                            onClick={() => handleSelectMachine(item)}
                            style={{
                              cursor: "pointer",
                              color: item.loc_id === locId ? "#007bff" : "black",
                              fontWeight: item.loc_id === locId ? "bold" : "normal",
                              textDecoration: item.loc_id === locId ? "underline" : "none"
                            }}
                          >
                            {item.ipc_no || "-"}
                          </td>
                          <td
                            onClick={() => handleSelectMachine(item)}
                            style={{
                              cursor: "pointer",
                              color: item.loc_id === locId ? "#007bff" : "black",
                              fontWeight: item.loc_id === locId ? "bold" : "normal",
                              textDecoration: item.loc_id === locId ? "underline" : "none"
                            }}
                          >
                            {item.ipc_name}
                            {item.loc_name ? ` (${item.loc_name})` : ""}
                          </td>
                          <td>{item.column_no || "-"}</td>
                          <td style={getStyle(item.master_goods_no, item.stock_goods_no)}>{item.stock_goods_no || "-"}</td>
                          <td style={getStyle(item.master_goods_name, item.stock_goods_name)}>{item.stock_goods_name || "-"}</td>
                          <td style={getStyle(item.stock_goods_no, item.master_goods_no)}>{item.master_goods_no || "-"}</td>
                          <td style={getStyle(item.stock_goods_name, item.master_goods_name)}>{item.master_goods_name || "-"}</td>
                          <td>
                            <Button color="primary" size="sm" onClick={() => handleRegister(item)}>
                              商品マスタ登録
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* 警告モーダル */}
          <Modal isOpen={warningModal} toggle={() => setWarningModal(!warningModal)}>
            <ModalHeader toggle={() => setWarningModal(!warningModal)}>
              警告
            </ModalHeader>
            <ModalBody>
              商品名または在庫の商品番号が未入力のため、登録できません。<br />
              データを正しく入力してください。
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={() => setWarningModal(false)}>
                閉じる
              </Button>
            </ModalFooter>
          </Modal>

          {/* 登録成功モーダル */}
          <Modal isOpen={successModal} toggle={() => setSuccessModal(!successModal)}>
            <ModalHeader toggle={() => setSuccessModal(!successModal)}>
              成功
            </ModalHeader>
            <ModalBody>
              商品マスタの登録が正常に完了しました。
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={() => setSuccessModal(false)}>
                閉じる
              </Button>
            </ModalFooter>
          </Modal>

          {/* 登録確認モーダル */}
          <Modal isOpen={confirmModal} toggle={() => setConfirmModal(!confirmModal)}>
            <ModalHeader toggle={() => setConfirmModal(!confirmModal)}>
              登録確認
            </ModalHeader>
            <ModalBody>
              以下の内容で商品マスタを登録しますか？<br />
              商品名: {currentItem?.stock_goods_name}<br />
              商品番号: {currentItem?.stock_goods_no}
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={confirmRegister}>
                登録する
              </Button>
              <Button color="secondary" onClick={() => setConfirmModal(false)}>
                キャンセル
              </Button>
            </ModalFooter>
          </Modal>

        </Container>
      </div>
    </React.Fragment>
  );
};

export default GoodsMaster;
