import { startOfMonth, subMonths, subYears } from "date-fns"
import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap"

import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { fetchGraphData } from "../../../store/marketing/time/actions"
import BarChart from "../../AllCharts/chartist/barchart"
import DountChart from "../../AllCharts/chartist/dountchart"
import ErrorMessage from "../../Common/ErrorMessage"
import CommonComponent from "../../CommonComponent"

BarChart.defaultProps = {
  labels: [],
  data: [],
}

DountChart.defaultProps = {
  labels: [],
  data: [],
}

const MarketingTime = () => {
  const [locId, setLoc] = useState("")
  const [goodsCategory, setGoodsCategory] = useState(null)

  const [timePeriod, setTimePeriod] = useState("日")
  const [currentDate, setCurrentDate] = useState(new Date())
  const [vendor_id, setVendor] = useState(null)
  const graphData = useSelector(state => state.marketingTimeReducer.graphData)
  const dispatch = useDispatch()

  const isLoading = useSelector(state => state.marketingTimeReducer.loading)

  const results = graphData && graphData.data ? graphData.data.results : []
  const labels = results.length ? results.map(item => item.time_range) : []
  const data = results.length ? results.map(item => item.count) : []

  const goods_results1 =
    graphData && graphData.data ? graphData.data.goods_count_results_1 : []
  const goods_labels1 = goods_results1.length
    ? goods_results1.map(item => item.goods_name)
    : []
  const goods_data1 = goods_results1.length
    ? goods_results1.map(item => item.count)
    : []

  const goods_results2 =
    graphData && graphData.data ? graphData.data.goods_count_results_2 : []
  const goods_labels2 = goods_results2.length
    ? goods_results2.map(item => item.goods_name)
    : []
  const goods_data2 = goods_results2.length
    ? goods_results2.map(item => item.count)
    : []

  const goods_results3 =
    graphData && graphData.data ? graphData.data.goods_count_results_3 : []
  const goods_labels3 = goods_results3.length
    ? goods_results3.map(item => item.goods_name)
    : []
  const goods_data3 = goods_results3.length
    ? goods_results3.map(item => item.count)
    : []

  const goods_results4 =
    graphData && graphData.data ? graphData.data.goods_count_results_4 : []
  const goods_labels4 = goods_results4.length
    ? goods_results4.map(item => item.goods_name)
    : []
  const goods_data4 = goods_results4.length
    ? goods_results4.map(item => item.count)
    : []

  const goods_results5 =
    graphData && graphData.data ? graphData.data.goods_count_results_5 : []
  const goods_labels5 = goods_results5.length
    ? goods_results5.map(item => item.goods_name)
    : []
  const goods_data5 = goods_results5.length
    ? goods_results5.map(item => item.count)
    : []

  const previousDate = useMemo(() => {
    if (timePeriod === "月") {
      return startOfMonth(subYears(currentDate, 1))
    } else if (timePeriod === "日") {
      return startOfMonth(currentDate)
    } else if (timePeriod === "時") {
      return currentDate
    } else if (timePeriod === "3か月") {
      return startOfMonth(subMonths(currentDate, 3))
    }
  }, [timePeriod, currentDate])

  const [locGroup, setLocGroup] = useState(null)

  useEffect(() => {
    if (locId !== "" || locGroup !== null) {
      dispatch(
        fetchGraphData(goodsCategory, locId, timePeriod, currentDate, previousDate, locGroup)
      )
    }
  }, [goodsCategory, locId, currentDate, locGroup])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="ホーム" breadcrumbItem="時間帯別" />
          <ErrorMessage />
          <Row>
            <Col xs={12}>
              <Card className="bg-secondary mx-auto" style={{ maxWidth: 900 }}>
                <CardBody>
                  <h4 className="card-title mb-4">検索条件</h4>
                  {isLoading && (
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        zIndex: 9999, // 必要に応じて、他の要素より前面に表示されるように設定します
                      }}
                    >
                      <div style={{ color: "white", fontSize: "2em" }}>
                        Loading...
                      </div>
                    </div>
                  )}
                  <CommonComponent
                    goodsCategory={goodsCategory}
                    setGoodsCategory={setGoodsCategory}
                    locId={locId}
                    setLoc={setLoc}
                    setLocGroup={setLocGroup}
                    currentDate={currentDate}
                    setCurrentDate={setCurrentDate}
                    timePeriod={timePeriod}
                    setTimePeriod={setTimePeriod}
                    previousDate={previousDate}
                    vendor_id={vendor_id}
                    setVendor={setVendor}
                    past_loc={true}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Card className="mx-auto" style={{ maxWidth: 750 }}>
                <CardBody>
                  <CardTitle>時間帯別売上数</CardTitle>
                  <BarChart labels={labels} data={data} label="販売数" />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Card className="mx-auto" style={{ maxWidth: 750 }}>
                <CardBody>
                  <CardTitle>時間帯別商品分布</CardTitle>
                  <h4 className="card-title mb-4">早朝(4-7時)</h4>
                  <DountChart labels={goods_labels1} data={goods_data1} />
                  <h4 className="card-title mb-4">午前(8-12時)</h4>
                  <DountChart labels={goods_labels2} data={goods_data2} />
                  <h4 className="card-title mb-4">午後(13-16時)</h4>
                  <DountChart labels={goods_labels3} data={goods_data3} />
                  <h4 className="card-title mb-4">夕方(17-21時)</h4>
                  <DountChart labels={goods_labels4} data={goods_data4} />
                  <h4 className="card-title mb-4">深夜(23-3時)</h4>
                  <DountChart labels={goods_labels5} data={goods_data5} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default MarketingTime
