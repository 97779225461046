import {
  GET_IPC_ERRORS,
  GET_IPC_ERRORS_SUCCESS,
  GET_IPC_ERRORS_FAIL,
} from "./actionTypes"

export const getIpcErrors = (locId) => ({
  type: GET_IPC_ERRORS,
  payload: { locId },
})

export const getIpcErrorsSuccess = (data) => ({
  type: GET_IPC_ERRORS_SUCCESS,
  payload: data,
})

export const getIpcErrorsFail = (error) => ({
  type: GET_IPC_ERRORS_FAIL,
  error,
}) 