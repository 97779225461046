import {
  FETCH_SOLD_OUT_REQUEST,
  FETCH_SOLD_OUT_SUCCESS,
  FETCH_SOLD_OUT_ERROR,
} from "./actionTypes"

// groupIdを引数として取る
export const fetchSoldOut = ({
  groupId,
  warehouseId,
  startWarehouseId,
  endWarehouseId,
  selectedLocations,
  locId,
  userId,
  onlySoldOut,
  originalPlanId,
} = {}) => ({
  type: FETCH_SOLD_OUT_REQUEST,
  payload: {
    groupId,
    warehouseId,
    startWarehouseId,
    endWarehouseId,
    selectedLocations,
    locId,
    userId,
    onlySoldOut,
    originalPlanId,
  },
})

export const fetchSoldOutSuccess = data => ({
  type: FETCH_SOLD_OUT_SUCCESS,
  payload: {
    route: data.route,
    soldOutData: data.results,
  },
})

export const fetchSoldOutError = error => ({
  type: FETCH_SOLD_OUT_ERROR,
  error,
})
