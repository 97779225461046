import { call, put, takeEvery } from "redux-saga/effects"
import { FETCH_STOCKS_GRAPH_DATA, UPDATE_STOCK_ITEM } from "./actionTypes"
import {
  fetchGraphDataSuccess,
  fetchGraphDataError,
  updateStockItemSuccess,
  updateStockItemError,
} from "./actions"

//Include Both Helper File with needed methods
import { getStocks, getIpcs, putStocks } from "../../helpers/backend_helper"

function* fetchGraphDataSaga(action) {
  try {
    const { goodsCategory, locId, timePeriod, currentDate, previousDate } =
      action.payload
    const graphData = yield call(
      getStocks,
      goodsCategory,
      locId,
      timePeriod,
      currentDate,
      previousDate,
    )
    yield put(fetchGraphDataSuccess(graphData))
  } catch (error) {
    yield put(fetchGraphDataError(error))
  }
}

function* updateStockItemSaga(action) {
  try {
    const { item, locId } = action.payload
    // itemにはproductExpiryが含まれており、これがバックエンドのproduct_expire_dateとして送信される
    const updatedItem = yield call(putStocks, item, locId)
    yield put(updateStockItemSuccess(updatedItem))
  } catch (error) {
    yield put(updateStockItemError(error))
  }
}

function* StocksSaga() {
  yield takeEvery(FETCH_STOCKS_GRAPH_DATA, fetchGraphDataSaga)
  yield takeEvery(UPDATE_STOCK_ITEM, updateStockItemSaga)
}

export default StocksSaga
