import { get } from "../api_helper"
import * as url from "../url_helper"

export const getIpcErrors = async (locId) => {
  try {
    const queryParams = new URLSearchParams()
    if (locId) {
      queryParams.append("loc_id", locId)
    }
    const response = await get(`${url.GET_IPC_ERRORS}?${queryParams.toString()}`)
    return { data: response }
  } catch (error) {
    throw error
  }
} 