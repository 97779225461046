// backend_helper.js

import { postJwtLogin } from "./api/loginApi"
import { getFlows } from "./api/flowsApi"
import {
  getIpcs,
  putIpcs,
  deleteIpcs,
  getIpcsForUser,
  getIpcsDetail,
  updateIpcs,
} from "./api/ipcsApi"
import {
  getGoods,
  postGoods,
  putGoods,
  deleteGoods,
  registerSurpriseApi,
  unregisterSurpriseApi,
  fetchGoodsDetailApi,
} from "./api/goodsApi"
import { getGoodsCategory, putGoodsCategory } from "./api/goodsCategoryApi"
import { getMarketingVolume } from "./api/marketingVolumeApi"
import { getMarketingCustomer } from "./api/marketingCustomerApi"
import { getMarketingTime } from "./api/marketingTimeApi"
import { getUsers, getUserDetail, updateUser } from "./api/usersApi"
import { getCameras, putCameras, deleteCameras } from "./api/camerasApi"
import { getStocks, putStocks } from "./api/stocksApi"
import { getSalesDaily } from "./api/salesDailyApi"
import { getSales } from "./api/salesApi"
import { getSalesAllData } from "./api/salesAllApi"
import { getSalesLocData } from "./api/salesLocApi"
import {
  getCustomersData,
  getCustomersData2,
  updateCustomerData,
} from "./api/customersApi"
import { getAnnouncementData } from "./api/announcementApi"
import {
  getCompanies,
  postCompany,
  putCompany,
  deleteCompany,
} from "./api/companyApi"
import { getShelfs, putShelfs } from "./api/shelfApi"
import {
  fetchLocationGroups,
  updateLocationGroup,
  deleteLocationGroup,
  registerLocationGroup,
  fetchLocationGroupDetails,
} from "./api/locationGroupApi"
import { fetchLocations, updateLocation, registerLocation, deleteLocation } from "./api/locationApi"
import { getSoldOutData } from "./api/soldOutApi"
import { postProfile } from "./api/profileApi"
import {
  getWarehouses,
  postWarehouse,
  putWarehouse,
  deleteWarehouse,
  getWarehouseStock,
  postWarehouseStock,
  putWarehouseStock,
  delWarehouseStock,
} from "./api/warehouseApi"

import {
  getVehicles,
  postVehicle,
  putVehicle,
  deleteVehicle,
  getVehicleStock,
  postVehicleStock,
  putVehicleStock,
  delVehicleStock,
} from "./api/vehicleApi"

import {
  postDeliveryPlan,
  getDeliveryItems,
  getDeliveryPlans,
  updateDeliveryPlan,
  getDeliveryPlanDetail,
  deleteDeliveryPlan,
  getPendingDeliveryPlansCount,
} from "./api/deliveryPlanApi"

import {
  fetchLineUsers,
  linkLineUser,
  unlinkLineUser,
  deleteLineUser,
} from "./api/lineUserApi"

import { getGoodsMaster, registerGoodsMaster } from "./api/goodsMasterApi"

import { get, put } from "./api_helper"
import * as url from "./url_helper"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// カメラ情報を取得する関数
export const getCameraInfo = (uuid) => get(`${url.GET_CAMERA_INFO}/${uuid}`)

// カメラ情報を更新する関数
export const updateCameraInfo = (data) => {
  const { uuid, ...updateData } = data;
  return put(`${url.UPDATE_CAMERA_INFO}/${uuid}`, updateData);
}

export {
  getLoggedInUser,
  isUserAuthenticated,
  postJwtLogin,
  getFlows,
  getIpcs,
  getGoods,
  postGoods,
  putGoods,
  deleteGoods,
  getGoodsCategory,
  getMarketingVolume,
  getMarketingCustomer,
  getMarketingTime,
  putGoodsCategory,
  getUsers,
  putIpcs,
  deleteIpcs,
  getCameras,
  putCameras,
  deleteCameras,
  getStocks,
  getSalesDaily,
  putStocks,
  getSales,
  getUserDetail,
  getSalesAllData,
  getSalesLocData,
  getCustomersData,
  updateCustomerData,
  getCustomersData2,
  getAnnouncementData,
  updateUser,
  getIpcsForUser,
  getCompanies,
  postCompany,
  putCompany,
  deleteCompany,
  getIpcsDetail,
  updateIpcs,
  getShelfs,
  putShelfs,
  fetchLocationGroups,
  updateLocationGroup,
  deleteLocationGroup,
  fetchLocationGroupDetails,
  getSoldOutData,
  postProfile,
  getWarehouses,
  postWarehouse,
  putWarehouse,
  deleteWarehouse,
  getWarehouseStock,
  postWarehouseStock,
  putWarehouseStock,
  delWarehouseStock,
  getVehicles,
  postVehicle,
  putVehicle,
  deleteVehicle,
  getVehicleStock,
  postVehicleStock,
  putVehicleStock,
  delVehicleStock,
  registerSurpriseApi,
  unregisterSurpriseApi,
  fetchGoodsDetailApi,
  postDeliveryPlan,
  getDeliveryItems,
  getDeliveryPlans,
  updateDeliveryPlan,
  getDeliveryPlanDetail,
  deleteDeliveryPlan,
  fetchLineUsers,
  linkLineUser,
  unlinkLineUser,
  deleteLineUser,
  fetchLocations,
  updateLocation,
  registerLocation,
  deleteLocation,
  registerLocationGroup,
  getPendingDeliveryPlansCount,
  getGoodsMaster,
  registerGoodsMaster,
}
