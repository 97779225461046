import { ca } from "date-fns/locale"
import { get, put } from "../api_helper"
import * as url from "../url_helper"

const getGoodsCategory = async () => {
  const response = await get(url.GET_GOODS_CATEGORY)

  return { data: response }
}

const putGoodsCategory = async (
  targetGoods,
  category,
  categoryName,
  deleteFlg,
  createFlg,
) => {
  const response = await put(url.PUT_GOODS_CATEGORY, {
    targetGoods: targetGoods,
    category: category,
    categoryName: categoryName,
    deleteFlg: deleteFlg,
    createFlg: createFlg,
  })

  return { data: response }
}

export { getGoodsCategory, putGoodsCategory }
