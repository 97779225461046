// reducers.js

import {
  FETCH_MARKETING_COMMON_GOODS_CATEGORY_DATA,
  FETCH_MARKETING_COMMON_GOODS_CATEGORY_DATA_SUCCESS,
  FETCH_MARKETING_COMMON_GOODS_CATEGORY_DATA_ERROR,
  FETCH_MARKETING_COMMON_IPCS_DATA,
  FETCH_MARKETING_COMMON_IPCS_DATA_SUCCESS,
  FETCH_MARKETING_COMMON_IPCS_DATA_ERROR,
  FETCH_IPCS_DATA_DETAIL,
  FETCH_IPCS_DATA_DETAIL_SUCCESS,
  FETCH_IPCS_DATA_DETAIL_ERROR,
  UPDATE_IPCS_DATA,
  UPDATE_IPCS_DATA_SUCCESS,
  UPDATE_IPCS_DATA_ERROR,
} from "./actionTypes"

const initialState = {
  graphData: [],
  locData: [],
  ipcDetail: [],
  loading: false,
  error: null,
}

const marketingCommonReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MARKETING_COMMON_GOODS_CATEGORY_DATA:
      return {
        ...state,
        loading: true,
      }
    case FETCH_MARKETING_COMMON_GOODS_CATEGORY_DATA_SUCCESS:
      return {
        ...state,
        graphData: action.payload,
        loading: false,
      }
    case FETCH_MARKETING_COMMON_GOODS_CATEGORY_DATA_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case FETCH_MARKETING_COMMON_IPCS_DATA:
      return {
        ...state,
        loading: true,
      }
    case FETCH_MARKETING_COMMON_IPCS_DATA_SUCCESS:
      return {
        ...state,
        locData: action.payload,
        loading: false,
      }
    case FETCH_MARKETING_COMMON_IPCS_DATA_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case FETCH_IPCS_DATA_DETAIL:
      return {
        ...state,
        loading: true,
      }
    case FETCH_IPCS_DATA_DETAIL_SUCCESS:
      return {
        ...state,
        ipcDetail: action.payload.ipcDetail,
        companies: action.payload.companies,
        loading: false,
      }
    case FETCH_IPCS_DATA_DETAIL_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case UPDATE_IPCS_DATA:
      return {
        ...state,
        loading: true,
      }
    case UPDATE_IPCS_DATA_SUCCESS:
      return {
        ...state,
        locData: action.payload,
        loading: false,
      }
    case UPDATE_IPCS_DATA_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      }
    default:
      return state
  }
}

export default marketingCommonReducer
