import React, { useEffect, useState, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  addIpcToUser,
  fetchUserDetail,
  fetchUsersData,
  fetchUsersIPCData,
  removeIpcFromUser,
  updateUserAction,
} from "../../store/users/actions"
import ErrorMessage from "../Common/ErrorMessage"
import LoadingOverlay from "../Common/LoadingOverlay"
import { FcHighPriority } from "react-icons/fc"

import { fetchCompanies } from "../../store/companies/actions"
import { set } from "lodash"

const Users = () => {
  const dispatch = useDispatch()

  // 変数初期値
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [editUser, setEditUser] = useState(null)
  const [selectedCompany, setSelectedCompany] = useState(null)
  const [selectedDepartment, setSelectedDepartment] = useState(null)
  const [selectedLevel, setSelectedLevel] = useState(null)
  const [selectedDivision, setSelectedDivision] = useState(null)
  const [addModalOpen, setAddModalOpen] = useState(false)

  const levelOptions = [
    { value: 1, label: "システム管理者" },
    { value: 2, label: "管理者" },
    { value: 3, label: "一般" },
    { value: 4, label: "オペレーター" },
  ]

  const levelOptions2 = [
    { value: 2, label: "管理者" },
    { value: 3, label: "一般" },
    { value: 4, label: "オペレーター" },
  ]

  // APIから取得したデータを取得
  const userData = useSelector(state => state.usersReducer.userData)
  const userIpcData = useSelector(state => state.usersReducer.userIpcData)
  const isLoading = useSelector(state => state.usersReducer.loading)
  const userDetails = useSelector(state => state.usersReducer.userDetails)
  const companies = useSelector(state => state.companyReducer.companiesData)
  const isLoading1 = useSelector(state => state.companyReducer.loading)

  const [newUserId, setNewUserId] = useState("")
  const [newUserName, setNewUserName] = useState("")
  const [newUserPassword, setNewUserPassword] = useState("")
  const [newCompany, setNewCompany] = useState("")
  const [newUserDepartment, setNewUserDepartment] = useState("")
  const [newUserRole, setNewUserRole] = useState("")
  const [newSandenUsername, setNewSandenUsername] = useState("")
  const [newSandenPassword, setNewSandenPassword] = useState("")
  const [newFujiUsername, setNewFujiUsername] = useState("")
  const [newFujiPassword, setNewFujiPassword] = useState("")
  const [filteredDivisionList, setFilteredDivisionList] = useState(
    userDetails && userDetails.divisions_list ? userDetails.divisions_list : []
  )

  const [fujiUsername, setFujiUsername] = useState(
    userDetails && userDetails.fuji_user_name ? userDetails.fuji_user_name : ""
  )
  const [fujiPassword, setFujiPassword] = useState(
    userDetails && userDetails.fuji_password ? userDetails.fuji_password : ""
  )
  const [sandenUsername, setSandenUsername] = useState(
    userDetails && userDetails.sanden_user_name
      ? userDetails.sanden_user_name
      : ""
  )
  const [sandenPassword, setSandenPassword] = useState(
    userDetails && userDetails.sanden_password
      ? userDetails.sanden_password
      : ""
  )

  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [newConfirmPassword, setNewConfirmPassword] = useState("")
  const [passwordMismatch, setPasswordMismatch] = useState(false) // パスワードが一致しない場合のエラーをトラッキングするためのステート
  const [newPasswordMismatch, setNewPasswordMismatch] = useState(false) // パスワードが一致しない場合のエラーをトラッキングするためのステート
  const [missingRequiredFields, setMissingRequiredFields] = useState(false)

  // ローカルストレージからauthUserを取得し、パースしてuserオブジェクトを作成
  const [user, setUser] = useState(null)

  useEffect(() => {
    const authUser = localStorage.getItem("authUser")
    if (authUser) {
      setUser(JSON.parse(authUser))
    }
  }, [])

  const users = userData && userData.data ? userData.data.results : []
  const selfUserId =
    userData && userData.data ? userData.data.self_user_id : null
  const usersIpc =
    userIpcData && userIpcData.data ? userIpcData.data.results : []
  const ipcsList =
    userIpcData && userIpcData.data ? userIpcData.data.results2 : []

  // API呼び出し
  const handleInputChange = selectedOption => {
    dispatch(addIpcToUser(selectedUser.user_id, selectedOption.value))
  }

  const handleUserClick = user => {
    dispatch(fetchUsersIPCData(user.user_id))
    dispatch(fetchCompanies())

    setSelectedUser(user)
    setIsModalOpen(true)
  }

  const handleUpdate = () => {
    if (password !== confirmPassword) {
      setPasswordMismatch(true)
      return
    }
    if (!selectedCompany || !selectedLevel) {
      setMissingRequiredFields(true)
      return
    } else {
      setMissingRequiredFields(false)
    }
    const updatedUser = {
      userId: userDetails.user_code, // これは変更できないので、直接 userDetails から取得
      userName: editUser.user_name, // これも変更できないので、直接 editUser から取得
      userPassword: password,
      company: selectedCompany ? selectedCompany : null,
      department: selectedDepartment ? selectedDepartment.value : null,
      level: selectedLevel ? selectedLevel : null,
      fujiUsername: fujiUsername,
      fujiPassword: fujiPassword,
      sandenUsername: sandenUsername,
      sandenPassword: sandenPassword,
      newFlg: 0,
    }

    dispatch(updateUserAction(updatedUser))
    setEditModalOpen(false)
  }

  const openEditModal = user => {
    // フィールドをリセット
    setPassword("") // パスワードをクリア
    setConfirmPassword("") // 確認用パスワードをクリア
    setSelectedCompany(null) // 企業をクリア
    setSelectedDivision(null) // 部門をクリア
    setSelectedLevel(null) // 権限レベルをクリア
    setFujiUsername("") // FUJIのユーザ名をクリア
    setFujiPassword("") // FUJIのパスワードをクリア
    setSandenUsername("") // Sandenのユーザ名をクリア
    setSandenPassword("") // Sandenのパスワードをクリア
    setPasswordMismatch(false) // パスワード不一致エラーをクリア
    setMissingRequiredFields(false)

    dispatch(fetchUserDetail(user.user_id))
    setEditUser(user)
    setEditModalOpen(true)
  }

  const closeEditModal = () => {
    setEditModalOpen(false)
    setEditUser(null)
  }

  const saveNewUser = () => {
    if (
      !newUserId ||
      !newUserPassword ||
      !newUserName ||
      !newCompany ||
      !newUserRole
    ) {
      setMissingRequiredFields(true)
      return
    } else {
      setMissingRequiredFields(false)
    }

    if (newUserPassword !== newConfirmPassword) {
      setNewPasswordMismatch(true)
      return
    }

    console.log(`新規ユーザーを保存: 
        ID: ${newUserId},
        名前: ${newUserName},
        部門: ${newUserDepartment},
        役割: ${newUserRole},
        SANDENクラウドのユーザ名: ${newSandenUsername},
        SANDENクラウドのパスワード: ${newSandenPassword},
        Fujiクラウドのユーザ名: ${newFujiUsername},
        Fujiクラウドのパスワード: ${newFujiPassword}
    `)

    const registerdUser = {
      userId: newUserId,
      userName: newUserName,
      userPassword: newUserPassword,
      company: newCompany,
      department: newUserDepartment ? newUserDepartment : null,
      level: newUserRole ? newUserRole : null,
      fujiUsername: newFujiUsername ? newFujiUsername : null,
      fujiPassword: newFujiPassword ? newFujiPassword : null,
      sandenUsername: newSandenUsername ? newSandenUsername : null,
      sandenPassword: newSandenPassword ? newSandenPassword : null,
      newFlg: 1,
    }

    dispatch(updateUserAction(registerdUser))

    // ここにAPI呼び出しやReduxのアクションを実行する処理を追加する
    // ...

    // モーダルを閉じて、入力フィールドをクリアする
    closeAddModal()
    setNewUserId("")
    setNewUserName("")
    setNewUserDepartment("")
    setNewUserRole("")
    setNewSandenUsername("")
    setNewSandenPassword("")
    setNewFujiUsername("")
    setNewFujiPassword("")
  }

  const openAddModal = () => {
    // 2. openAddModal関数の定義
    // ステートのリセット
    setNewUserId("")
    setNewUserPassword("")
    setNewConfirmPassword("")
    setNewUserName("")
    setNewCompany(null)
    setNewUserDepartment(null)
    setNewUserRole(null)
    setNewSandenUsername("")
    setNewSandenPassword("")
    setNewFujiUsername("")
    setNewFujiPassword("")
    setNewPasswordMismatch(false)
    setMissingRequiredFields(false)

    dispatch(fetchUserDetail(selfUserId))
    setAddModalOpen(true)

    if (userDetails) {
      setSelectedCompany(userDetails.company_id)
      setSelectedDivision(userDetails.division_id)
      setSelectedLevel(userDetails.level)
      setFujiUsername(userDetails.fuji_user_name || "")
      setFujiPassword(userDetails.fuji_password || "")
      setSandenUsername(userDetails.sanden_user_name || "")
      setSandenPassword(userDetails.sanden_password || "")
    }
  }

  const closeAddModal = () => {
    // 2. closeAddModal関数の定義
    setAddModalOpen(false)
  }

  useEffect(() => {
    dispatch(fetchUsersData())
  }, [dispatch])

  useEffect(() => {
    // setUpdatedUsersIpc(usersIpc);
  }, [usersIpc])

  useEffect(() => {
    if (userDetails) {
      setSelectedCompany(userDetails.company_id)
      setSelectedDivision(userDetails.division_id)
      setSelectedLevel(userDetails.level)
      setFujiUsername(userDetails.fuji_user_name || "")
      setFujiPassword(userDetails.fuji_password || "")
      setSandenUsername(userDetails.sanden_user_name || "")
      setSandenPassword(userDetails.sanden_password || "")
    }
  }, [userDetails])

  // Fetch user level from local storage
  const authUser = JSON.parse(localStorage.getItem("authUser"))
  const userLevel = authUser ? authUser.level : 0

  // 削除確認モーダルの表示状態
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  // 削除対象のユーザーID
  const [deleteUserId, setDeleteUserId] = useState(null)

  // ユーザー削除処理
  const deleteUser = user => {
    // API呼び出し等でユーザー削除処理を行う
    console.log(`Deleting user with ID: ${user.user_id}`)
    // 削除後の処理（例: ユーザーリストの再取得等）
    setDeleteModalOpen(false) // モーダルを閉じる
  }

  // 削除確認モーダルを開く
  const openDeleteModal = userId => {
    setDeleteUserId(userId)
    setDeleteModalOpen(true)
  }

  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  })

  const sortedUsers = useMemo(() => {
    let sortableUsers = [...users]
    if (sortConfig !== null) {
      sortableUsers.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1
        }
        return 0
      })
    }
    return sortableUsers
  }, [users, sortConfig])

  const requestSort = key => {
    let direction = "ascending"
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending"
    }
    setSortConfig({ key, direction })
  }

  // 検索語を管理するためのuseStateを追加
  const [searchTerm, setSearchTerm] = useState("")
  const [searchIpcsTerm, setSearchIpcsTerm] = useState("")

  // 検索語に基づいてユーザーリストをフィルタリング
  const filteredUsers = useMemo(() => {
    return sortedUsers.filter(
      user =>
        (user.user_name &&
          user.user_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (user.user_code &&
          user.user_code.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (user.company_name &&
          user.company_name.toLowerCase().includes(searchTerm.toLowerCase()))
    )
  }, [sortedUsers, searchTerm])

  const [selectedUnassignedIpc, setSelectedUnassignedIpc] = useState(null)
  const [selectedAssignedIpc, setSelectedAssignedIpc] = useState(null)

  // 未割り当てIPCを選択
  const selectUnassignedIpc = ipc => {
    setSelectedUnassignedIpc(ipc)
    setSelectedAssignedIpc(null)
  }

  // 割り当て済みIPCを選択
  const selectAssignedIpc = ipc => {
    setSelectedAssignedIpc(ipc)
    setSelectedUnassignedIpc(null)
  }

  // 未割り当てIPCをユーザーに割り当て
  const assignIpcToUser = () => {
    if (selectedUnassignedIpc) {
      dispatch(addIpcToUser(selectedUser.user_id, selectedUnassignedIpc.ipc_no))
      setSelectedUnassignedIpc(null) // 選択状態の解除
    }
  }

  // 割り当て済みIPCを削除
  const unassignIpcFromUser = () => {
    if (selectedAssignedIpc) {
      dispatch(
        removeIpcFromUser(selectedUser.user_id, selectedAssignedIpc.ipc_no)
      )
      setSelectedAssignedIpc(null) // 選択状態の解除
    }
  }

  const [filteredIpcsList, setFilteredIpcsList] = useState([])

  const handleCompanyChange = companyId => {
    setSelectedCompany(companyId)
    if (companyId) {
      const numericCompanyId = Number(companyId)
      const filtered = ipcsList.filter(
        ipc => ipc.company_id === numericCompanyId && ipc.ipc_no !== null
      )
      setFilteredIpcsList(filtered)
    } else {
      const validIpcs = ipcsList.filter(ipc => ipc.ipc_no !== null)
      setFilteredIpcsList(validIpcs)
    }

    setSelectedUnassignedIpc(null)
    setSelectedAssignedIpc(null)
  }

  useEffect(() => {
    const filterList = ipcsList.filter(
      ipc =>
        ipc.ipc_no !== null && (
          ipc.ipc_no.toString().includes(searchIpcsTerm) ||
          ipc.ipc_name.includes(searchIpcsTerm) ||
          (ipc.loc_code && ipc.loc_code.toString().includes(searchIpcsTerm)) ||
          (ipc.loc_name && ipc.loc_name.includes(searchIpcsTerm))
        )
    )
    
    setFilteredIpcsList(filterList)
  }, [searchIpcsTerm])

  const closeModal = () => {
    setIsModalOpen(false)
    // 選択状態のリセット
    setSelectedUnassignedIpc(null)
    setSelectedAssignedIpc(null)
    setFilteredIpcsList(ipcsList) // 全IPCリストにリセット
  }

  const handleSearchChange = e => {
    setSearchIpcsTerm(e.target.value)
  }

  useEffect(() => {
    const filterList = ipcsList.filter(
      ipc =>
        ipc.ipc_no !== null && (
          ipc.ipc_no.toString().includes(searchIpcsTerm) ||
          ipc.ipc_name.includes(searchIpcsTerm)
        )
    )

    setFilteredIpcsList(filterList)
  }, [searchIpcsTerm])

  useEffect(() => {
    // 初期表示時のIPCリストの設定
    const validIpcs = ipcsList.filter(ipc => ipc.ipc_no !== null)
    setFilteredIpcsList(validIpcs)
  }, [ipcsList])

  useEffect(() => {
    if (searchIpcsTerm) {  // 検索語がある場合のみフィルタリング
      const filterList = ipcsList.filter(
        ipc =>
          ipc.ipc_no !== null && (
            ipc.ipc_no.toString().includes(searchIpcsTerm) ||
            ipc.ipc_name.includes(searchIpcsTerm)
          )
      )
      setFilteredIpcsList(filterList)
    } else {
      // 検索語がない場合は、nullでないものすべてを表示
      const validIpcs = ipcsList.filter(ipc => ipc.ipc_no !== null)
      setFilteredIpcsList(validIpcs)
    }
  }, [searchIpcsTerm, ipcsList])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            maintitle="main"
            title="管理"
            breadcrumbItem="ユーザー一覧"
          />
          <ErrorMessage />
          <LoadingOverlay isLoading={isLoading} />
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <CardTitle>ユーザー一覧</CardTitle>
                  {/* 検索フィールドを追加 */}
                  <div style={{ marginBottom: "20px" }}>
                    <Input
                      type="text"
                      placeholder="検索..."
                      value={searchTerm}
                      onChange={e => setSearchTerm(e.target.value)}
                      style={{
                        width: "50%",
                        marginRight: "15px",
                        display: "inline-block",
                      }}
                    />
                    <Button
                      color="primary"
                      onClick={openAddModal}
                      style={{ marginLeft: "15px" }}
                    >
                      新規ユーザー登録
                    </Button>
                  </div>
                  <table className="table">
                    <thead>
                      <tr>
                        <th
                          onClick={() => requestSort("user_id")}
                          style={{ width: "10%" }}
                        >
                          ID
                        </th>
                        <th
                          onClick={() => requestSort("user_code")}
                          style={{ width: "20%" }}
                        >
                          ログインID
                        </th>
                        <th
                          onClick={() => requestSort("user_name")}
                          style={{ width: "30%" }}
                        >
                          名前
                        </th>
                        <th
                          onClick={() => requestSort("level")}
                          style={{ width: "20%" }}
                        >
                          レベル
                        </th>
                        <th
                          onClick={() => requestSort("company_name")}
                          style={{ width: "20%" }}
                        >
                          会社名
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredUsers.map(user => (
                        <tr
                          key={user.user_id}
                          onClick={() => handleUserClick(user)}
                        >
                          <td>{user.user_id}</td>
                          <td className="user-code">{user.user_code}</td>
                          <td className="user-name">{user.user_name}</td>
                          <td>
                            {levelOptions.find(
                              option => option.value === user.level
                            )?.label || "不明なレベル"}
                          </td>
                          <td>{user.company_name}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            isOpen={isModalOpen}
            toggle={() => setIsModalOpen(false)}
            className="custom-modal"
          >
            <ModalHeader toggle={() => setIsModalOpen(false)}>
              ユーザー詳細
            </ModalHeader>
            <LoadingOverlay isLoading={isLoading} />
            <LoadingOverlay isLoading={isLoading1} />
            <Button
              color="primary"
              onClick={() => openEditModal(selectedUser)}
              style={{ marginLeft: "10px", marginRight: "10px" }}
            >
              修正
            </Button>
            <Button
              color="danger"
              onClick={() => openDeleteModal(selectedUser)}
              style={{ marginLeft: "10px", marginRight: "10px" }}
            >
              削除
            </Button>
            <ModalBody style={{ maxHeight: "75vh", overflowY: "auto" }}>
              {" "}
              {selectedUser && (
                <>
                  <h4>ユーザー情報</h4>
                  <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <td>ログインID：</td>
                        <td>{selectedUser.user_code}</td>
                      </tr>
                      <tr>
                        <td>名前：</td>
                        <td>{selectedUser.user_name}</td>
                      </tr>
                      <tr>
                        <td>レベル：</td>
                        <td>
                          {levelOptions.find(
                            option => option.value === selectedUser.level
                          )?.label || "不明なレベル"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "20px",
                    }}
                  >
                    <div style={{ flex: 1, marginRight: "10px" }}>
                      <h5>未設定のIPC</h5>
                      <ul
                        style={{
                          maxHeight: "400px",
                          overflowY: "auto",
                          width: "100%",
                          padding: 0,
                        }}
                      >
                        {filteredIpcsList.map(ipc => (
                          <li
                            key={ipc.ipc_no}
                            onClick={() => selectUnassignedIpc(ipc)}
                            style={{
                              backgroundColor:
                                selectedUnassignedIpc &&
                                selectedUnassignedIpc.ipc_no === ipc.ipc_no
                                  ? "lightgray"
                                  : "transparent",
                              padding: "10px",
                              borderBottom: "1px solid #ccc",
                              cursor: "pointer",
                              transition: "background-color 0.3s ease",
                              fontSize: "16px",
                            }}
                          >
                            【{ipc.company_name}】{ipc.loc_name}
                            <span
                              style={{
                                visibility:
                                  ipc.is_active === 0 ? "visible" : "hidden",
                                marginRight: "5px",
                              }}
                            >
                              <FcHighPriority />
                            </span>
                          </li>
                        ))}
                      </ul>
                      <div>
                        <Input
                          type="text"
                          placeholder="IDまたは名称で検索..."
                          value={searchIpcsTerm}
                          onChange={handleSearchChange}
                          style={{ marginBottom: "10px" }}
                        />
                      </div>
                      <FormGroup>
                        <Input
                          type="select"
                          id="companySelect"
                          onChange={e => handleCompanyChange(e.target.value)}
                        >
                          <option value="">会社で検索...</option>
                          {companies &&
                            companies.data &&
                            companies.data.results.map(company => (
                              <option key={company.id} value={company.id}>
                                {company.name}
                              </option>
                            ))}
                        </Input>
                      </FormGroup>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "50px",
                      }}
                    >
                      <button
                        onClick={assignIpcToUser}
                        disabled={!selectedUnassignedIpc}
                        style={{ marginBottom: "10px", width: "50px" }}
                      >
                        &gt;
                      </button>
                      <button
                        onClick={unassignIpcFromUser}
                        disabled={!selectedAssignedIpc}
                        style={{ marginBottom: "10px", width: "50px" }}
                      >
                        &lt;
                      </button>
                    </div>
                    <div style={{ flex: 1, marginLeft: "10px" }}>
                      <h5>設定済みのIPC</h5>
                      <ul
                        style={{
                          maxHeight: "400px",
                          overflowY: "auto",
                          width: "100%",
                          padding: 0,
                        }}
                      >
                        {usersIpc.filter(ipc => ipc.ipc_no !== null).map(ipc => (
                          <li
                            key={ipc.ipc_no}
                            onClick={() => selectAssignedIpc(ipc)}
                            style={{
                              backgroundColor:
                                selectedAssignedIpc &&
                                selectedAssignedIpc.ipc_no === ipc.ipc_no
                                  ? "lightgray"
                                  : "transparent",
                              padding: "10px",
                              borderBottom: "1px solid #ccc",
                              cursor: "pointer",
                              transition: "background-color 0.3s ease",
                              fontSize: "16px",
                            }}
                          >
                            【{ipc.company_name}】{ipc.loc_name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </>
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={() => closeModal()}>
                閉じる
              </Button>
            </ModalFooter>
          </Modal>

          <Modal
            key={editUser ? editUser.user_id : "edit-modal"}
            isOpen={editModalOpen}
            toggle={closeEditModal}
          >
            <ModalHeader toggle={closeEditModal}>
              ユーザー詳細の編集
            </ModalHeader>
            <ModalBody>
              {editUser && userDetails && (
                <FormGroup>
                  {missingRequiredFields && (
                    <p style={{ color: "red" }}>
                      必須の項目を入力してください。
                    </p>
                  )}
                  <Label>ログインID</Label>
                  <p>{userDetails.user_code}</p>

                  <Label>名前</Label>
                  <p>{editUser.user_name}</p>

                  <Label htmlFor="password">
                    パスワード{" "}
                    <span style={{ color: "blue" }}>
                      {" "}
                      変更時のみ入力してください。
                    </span>
                  </Label>
                  <Input
                    type="text"
                    name="password"
                    id="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                  />

                  <Label htmlFor="confirmPassword">パスワード(確認)</Label>
                  <Input
                    type="text"
                    name="confirmPassword"
                    id="confirmPassword"
                    value={confirmPassword}
                    onChange={e => setConfirmPassword(e.target.value)}
                  />

                  {passwordMismatch && (
                    <p style={{ color: "red" }}>パスワードが一致しません。</p>
                  )}

                  <Label htmlFor="company">企業</Label>
                  {user.level === 1 ? (
                    // Display-only for users with level 1
                    <p>
                      {
                        userDetails.company_list.find(
                          company => company.id === selectedCompany
                        )?.name
                      }
                    </p>
                  ) : (
                    // Dropdown Select for other users
                    <Select
                      value={
                        selectedCompany
                          ? {
                              value: selectedCompany,
                              label: userDetails.company_list.find(
                                company => company.id === selectedCompany
                              ).name,
                            }
                          : null
                      }
                      options={userDetails.company_list.map(company => ({
                        value: company.id,
                        label: company.name,
                      }))}
                      name="company"
                      id="company"
                      onChange={option => setSelectedCompany(option.value)}
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          borderColor: missingRequiredFields
                            ? "red"
                            : provided.borderColor,
                          boxShadow: missingRequiredFields
                            ? "0 0 0 0.5px red"
                            : provided.boxShadow,
                        }),
                      }}
                    />
                  )}

                  <Label htmlFor="level">権限レベル</Label>
                  <Select
                    value={
                      selectedLevel
                        ? (user && user.level === 1
                            ? levelOptions2
                            : levelOptions
                          ).find(option => option.value === selectedLevel)
                        : null
                    }
                    options={
                      user && user.level === 1 ? levelOptions2 : levelOptions
                    }
                    name="level"
                    id="level"
                    onChange={option => setSelectedLevel(option.value)}
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        borderColor: missingRequiredFields
                          ? "red"
                          : provided.borderColor,
                        boxShadow: missingRequiredFields
                          ? "0 0 0 0.5px red"
                          : provided.boxShadow,
                      }),
                    }}
                  />

                  <Label htmlFor="fujiUsername">FUJI用のID</Label>
                  <Input
                    type="text"
                    name="fujiUsername"
                    id="fujiUsername"
                    value={fujiUsername}
                    onChange={e => setFujiUsername(e.target.value)}
                  />

                  <Label htmlFor="fujiPassword">FUJI用のPW</Label>
                  <Input
                    type="text"
                    name="fujiPassword"
                    id="fujiPassword"
                    value={fujiPassword}
                    onChange={e => setFujiPassword(e.target.value)}
                  />

                  <Label htmlFor="sandenUsername">Sanden用のID</Label>
                  <Input
                    type="text"
                    name="sandenUsername"
                    id="sandenUsername"
                    value={sandenUsername}
                    onChange={e => setSandenUsername(e.target.value)}
                  />

                  <Label htmlFor="sandenPassword">Sanden用のPW</Label>
                  <Input
                    type="text"
                    name="sandenPassword"
                    id="sandenPassword"
                    value={sandenPassword}
                    onChange={e => setSandenPassword(e.target.value)}
                  />
                </FormGroup>
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={closeEditModal}>
                キャンセル
              </Button>{" "}
              <Button color="primary" onClick={handleUpdate}>
                保存
              </Button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={addModalOpen} toggle={closeAddModal}>
            <ModalHeader toggle={closeAddModal}>新規ユーザー登録</ModalHeader>
            <ModalBody>
              {userDetails && (
                <FormGroup>
                  {missingRequiredFields && (
                    <p style={{ color: "red" }}>
                      必須の項目を入力してください。
                    </p>
                  )}

                  <Label htmlFor="newUserId">
                    ログインID <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    type="text"
                    name="newUserId"
                    id="newUserId"
                    placeholder="ログインIDを入力"
                    value={newUserId}
                    onChange={e => setNewUserId(e.target.value)}
                    style={missingRequiredFields ? { borderColor: "red" } : {}}
                  />

                  <Label htmlFor="newUserPassword">
                    パスワード <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    type="text"
                    name="newUserPassword"
                    id="newUserPassword"
                    placeholder="パスワードを入力"
                    value={newUserPassword}
                    onChange={e => setNewUserPassword(e.target.value)}
                    style={missingRequiredFields ? { borderColor: "red" } : {}}
                  />

                  <Label htmlFor="newConfirmPassword">
                    パスワード(確認) <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    type="text"
                    name="newConfirmPassword"
                    id="newConfirmPassword"
                    value={newConfirmPassword}
                    onChange={e => setNewConfirmPassword(e.target.value)}
                    style={missingRequiredFields ? { borderColor: "red" } : {}}
                  />

                  {newPasswordMismatch && (
                    <p style={{ color: "red" }}>パスワードが一致しません。</p>
                  )}

                  <Label htmlFor="newUserName">
                    名前 <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    type="text"
                    name="newUserName"
                    id="newUserName"
                    placeholder="名前を入力"
                    value={newUserName}
                    onChange={e => setNewUserName(e.target.value)}
                    style={missingRequiredFields ? { borderColor: "red" } : {}}
                  />

                  <Label htmlFor="newCompany">
                    企業 <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Select
                    value={
                      newCompany
                        ? {
                            value: newCompany,
                            label: userDetails.company_list.find(
                              company => company.id === newCompany
                            ).name,
                          }
                        : null
                    }
                    options={userDetails.company_list.map(company => ({
                      value: company.id,
                      label: company.name,
                    }))}
                    name="newCompany"
                    id="newCompany"
                    onChange={option => {
                      setNewCompany(option.value)
                      // 選択された企業に関連する部署をフィルタリング
                      const filteredDivisions =
                        userDetails.divisions_list.filter(
                          division => division.company_id === option.value
                        )
                      setFilteredDivisionList(filteredDivisions)
                    }}
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        borderColor: missingRequiredFields
                          ? "red"
                          : provided.borderColor,
                        boxShadow: missingRequiredFields
                          ? "0 0 0 0.5px red"
                          : provided.boxShadow,
                      }),
                    }}
                  />

                  <Label htmlFor="newUserRole">
                    権限レベル <span style={{ color: "red" }}>*</span>
                  </Label>
                  {user && user.level === 1 ? (
                    <Select
                      value={
                        newUserRole
                          ? levelOptions2.find(
                              option => option.value === newUserRole
                            )
                          : null
                      }
                      options={levelOptions2}
                      name="newUserRole"
                      id="newUserRole"
                      onChange={option => setNewUserRole(option.value)}
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          borderColor: missingRequiredFields
                            ? "red"
                            : provided.borderColor,
                          boxShadow: missingRequiredFields
                            ? "0 0 0 0.5px red"
                            : provided.boxShadow,
                        }),
                      }}
                    />
                  ) : (
                    <Select
                      value={
                        newUserRole
                          ? levelOptions.find(
                              option => option.value === newUserRole
                            )
                          : null
                      }
                      options={levelOptions}
                      name="newUserRole"
                      id="newUserRole"
                      onChange={option => setNewUserRole(option.value)}
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          borderColor: missingRequiredFields
                            ? "red"
                            : provided.borderColor,
                          boxShadow: missingRequiredFields
                            ? "0 0 0 0.5px red"
                            : provided.boxShadow,
                        }),
                      }}
                    />
                  )}

                  <Label htmlFor="newSandenUsername">
                    SANDENクラウドのユーザ名
                  </Label>
                  <Input
                    type="text"
                    name="newSandenUsername"
                    id="newSandenUsername"
                    placeholder="ユーザ名を入力"
                    value={newSandenUsername}
                    onChange={e => setNewSandenUsername(e.target.value)}
                  />

                  <Label htmlFor="newSandenPassword">
                    SANDENクラウドのパスワード
                  </Label>
                  <Input
                    type="text"
                    name="newSandenPassword"
                    id="newSandenPassword"
                    placeholder="パスワードを入力"
                    value={newSandenPassword}
                    onChange={e => setNewSandenPassword(e.target.value)}
                  />

                  <Label htmlFor="newFujiUsername">
                    Fujiクラウドのユーザ名
                  </Label>
                  <Input
                    type="text"
                    name="newFujiUsername"
                    id="newFujiUsername"
                    placeholder="ユーザ名を入力"
                    value={newFujiUsername}
                    onChange={e => setNewFujiUsername(e.target.value)}
                  />

                  <Label htmlFor="newFujiPassword">
                    Fujiクラウドのパスワード
                  </Label>
                  <Input
                    type="text"
                    name="newFujiPassword"
                    id="newFujiPassword"
                    placeholder="パスワードを入力"
                    value={newFujiPassword}
                    onChange={e => setNewFujiPassword(e.target.value)}
                  />
                </FormGroup>
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={closeAddModal}>
                キャンセル
              </Button>{" "}
              <Button color="primary" onClick={saveNewUser}>
                保存
              </Button>
            </ModalFooter>
          </Modal>
          {/* 削除確認モーダル */}
          <Modal
            isOpen={deleteModalOpen}
            toggle={() => setDeleteModalOpen(!deleteModalOpen)}
          >
            <ModalHeader toggle={() => setDeleteModalOpen(!deleteModalOpen)}>
              削除確認
            </ModalHeader>
            <ModalBody>本当にこのユーザーを削除しますか？</ModalBody>
            <ModalFooter>
              <Button
                color="secondary"
                onClick={() => setDeleteModalOpen(false)}
              >
                キャンセル
              </Button>
              <Button color="danger" onClick={() => deleteUser(deleteUserId)}>
                削除
              </Button>
            </ModalFooter>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Users
