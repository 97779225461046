import { get } from "../api_helper"
import * as url from "../url_helper"

// 売り切れ情報データ取得メソッド（グループ複数指定対応）
const getSoldOutData = async payload => {
  try {
    let queryParams = ""

    if (payload.groupId) {
      // グループIDが指定されている場合の処理
      const groupIds = Array.isArray(payload.groupId)
        ? payload.groupId
        : [payload.groupId]
      queryParams = groupIds.map(groupId => `groupId=${groupId}`).join("&")
    } else if (payload.warehouseId) {
      // 倉庫IDが指定されている場合の処理
      const warehouseIds = Array.isArray(payload.warehouseId)
        ? payload.warehouseId
        : [payload.warehouseId]
      queryParams = warehouseIds
        .map(warehouseId => `warehouseId=${warehouseId}`)
        .join("&")
    }

    // selectedLocations が存在する場合、その情報を追加
    if (payload.selectedLocations && payload.selectedLocations.length > 0) {
      const locCodes = payload.selectedLocations
        .map(loc => `locCode=${loc}`)
        .join("&")
      queryParams += `&${locCodes}`
    }

    // startWarehouseIdとendWarehouseIdがある場合の処理
    if (payload.startWarehouseId) {
      queryParams += `&startWarehouseId=${payload.startWarehouseId}`
    }
    if (payload.endWarehouseId) {
      queryParams += `&endWarehouseId=${payload.endWarehouseId}`
    }
    if (payload.locId) {
      queryParams += `&locId=${payload.locId}`
    }
    if (payload.userId) {
      queryParams += `&userId=${payload.userId}`
    }
    // onlySoldOutパラメータを追加
    if (payload.onlySoldOut !== undefined) {
      queryParams += `&onlySoldOut=${payload.onlySoldOut}`
    }

    if (payload.originalPlanId) {
      queryParams += `&originalPlanId=${payload.originalPlanId}`
    }

    if (queryParams) {
      queryParams = `?${queryParams}` // クエリパラメータの先頭に ? を追加
    }

    const response = await get(`${url.GET_SOLD_OUT}${queryParams}`)
    return { data: response }
  } catch (error) {
    throw error
  }
}

export { getSoldOutData }
