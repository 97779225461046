import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  FormGroup,
  Label,
  Form,
  Input,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import ErrorMessage from "../Common/ErrorMessage"
import LoadingOverlay from "../Common/LoadingOverlay"
import {
  fetchIpcsDataDetail,
  fetchIpcsData,
  updateIpcsData,
} from "../../store/marketing/common/actions"
import {
  updateLocationData,
  registerLocationData,
  deleteLocationData,
} from "store/location/actions"
import { FaCamera, FaExclamationTriangle } from "react-icons/fa"
import { GrCreditCard } from "react-icons/gr"

const Ipcs = () => {
  const dispatch = useDispatch()

  const isLoading = useSelector(state => state.marketingCommonReducer.loading)
  const ipcsData = useSelector(state => state.marketingCommonReducer.locData)
  const ipcDetail = useSelector(state => state.marketingCommonReducer.ipcDetail)
  const companies = useSelector(state => state.marketingCommonReducer.companies)

  const ipcDetail2 = useSelector(state => state.locationReducer.ipcDetail)
  const locationsx = useSelector(state => state.locationReducer.locations) || []

  const [locations, setLocations] = useState([])

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [mapUrl, setMapUrl] = useState("")

  const [searchQuery, setSearchQuery] = useState("")
  const [filteredIpcs, setFilteredIpcs] = useState([])

  const [sortField, setSortField] = useState(null)
  const [sortDirection, setSortDirection] = useState("asc")

  const [selectedCompanyId, setSelectedCompanyId] = useState("")

  // 住所設定フィルタ用チェックボックス（チェック時：住所未設定のみ表示）
  const [onlyNoAddress, setOnlyNoAddress] = useState(false)

  // ロケーション変更モーダルのステート
  const [isLocationModalOpen, setIsLocationModalOpen] = useState(false)
  const [selectedLocation, setSelectedLocation] = useState(null)

  // ロケーション登録モーダルのステート
  const [isLocationAddModalOpen, setIsLocationAddModalOpen] = useState(false)

  // ロケーション追加ボタンのクリックハンドラ
  const handleAddLocationClick = () => {
    const latestLocationInfo = findLatestLocation(locations)
    const latestEndDate = new Date(latestLocationInfo.end_datetime)
    const newStartDate = new Date(latestEndDate.getTime() + 1000)
    newStartDate.setHours(newStartDate.getHours() + 9)
    setLocationFormData({
      id: null,
      loc_code: latestLocationInfo.loc_code,
      loc_name: latestLocationInfo.loc_name,
      start_datetime: newStartDate.toISOString().slice(0, 16),
      end_datetime: "",
      is_active: false,
      comment: "",
      address: "",
    })
    setIsLocationAddModalOpen(true)
  }

  // ロケーション登録を処理
  const handleLocationAdd = () => {
    const updatedLocationFormData = {
      ...locationFormData,
      ipcNo: ipcDetail.ipc_no,
      end_datetime: locationFormData.end_datetime
        ? locationFormData.end_datetime
        : "2999-12-31T23:59:59",
    }
    dispatch(registerLocationData(ipcDetail.ipc_no, updatedLocationFormData))
    setIsLocationAddModalOpen(false)
  }

  // ロケーション変更フォームのステート
  const [locationFormData, setLocationFormData] = useState({
    id: null,
    loc_code: "",
    loc_name: "",
    start_datetime: "",
    end_datetime: "",
    is_active: false,
    comment: "",
    address: "",
    ipc_no: "",
  })

  // ロケーション変更モーダルを開く関数
  const handleLocationCardClick = location => {
    setSelectedLocation(location)
    setLocationFormData({ ...location })
    setIsLocationModalOpen(true)
  }

  // ロケーション変更フォームの入力変更を処理
  const handleLocationFormChange = e => {
    const { name, value } = e.target
    setLocationFormData(prev => ({
      ...prev,
      [name]: value,
    }))
  }

  // ロケーションの更新を処理
  const handleLocationUpdate = () => {
    const updatedLocationFormData = {
      ...locationFormData,
      end_datetime: locationFormData.end_datetime
        ? locationFormData.end_datetime
        : "2999-12-31T23:59:59",
    }
    const locationId = locationFormData.id
    dispatch(updateLocationData(locationId, updatedLocationFormData))
    setIsLocationModalOpen(false)
  }

  // ロケーションの削除を処理
  const handleLocationDelete = () => {
    const locationId = locationFormData.id
    dispatch(deleteLocationData(locationId, locationFormData))
    setIsLocationModalOpen(false)
  }

  useEffect(() => {
    if (locationFormData.address) {
      const encodedAddress = encodeURIComponent(locationFormData.address)
      setMapUrl(`https://www.google.com/maps?q=${encodedAddress}&output=embed`)
    }
  }, [locationFormData.address])

  useEffect(() => {
    dispatch(fetchIpcsData(undefined, undefined, 1))
  }, [dispatch])

  const handleVendingMachineClick = vendingMachine => {
    dispatch(fetchIpcsDataDetail(vendingMachine.ipc_no))
    setIsModalOpen(true)
  }

  const [formData, setFormData] = useState({
    ipc_no: "",
    ipc_name: "",
    comment: "",
    address: "",
    company_id: "",
    is_active: "",
    display_order: "",
  })

  useEffect(() => {
    if (ipcDetail) {
      setFormData({
        ipc_no: ipcDetail.ipc_no,
        ipc_name: ipcDetail.ipc_name,
        comment: ipcDetail.comment,
        address: ipcDetail.address,
        company_id: ipcDetail.company_id,
        is_active: ipcDetail.is_active,
        display_order: ipcDetail.display_order,
      })
      setSelectedCompanyId(ipcDetail.company_id)
    }
    if (ipcDetail && ipcDetail.locations) {
      setLocations(ipcDetail.locations)
    }
  }, [ipcDetail])

  useEffect(() => {
    if (ipcDetail2 && ipcDetail2.locations) {
      setLocations(ipcDetail2.locations)
    }
  }, [ipcDetail2])

  useEffect(() => {
    if (locationsx && locationsx.length > 0) {
      setLocations(locationsx)
    }
  }, [locationsx])

  const handleChange = e => {
    const { id, value } = e.target
    setFormData({
      ...formData,
      [id]: value,
    })
  }

  useEffect(() => {
    if (formData.address) {
      setMapUrl(
        `https://www.google.com/maps?q=${encodeURIComponent(
          formData.address
        )}&output=embed`
      )
    }
  }, [formData.address])

  const handleUpdate = () => {
    dispatch(updateIpcsData(formData))
    setIsModalOpen(false)
  }

  // 最も遅い終了日時を取得する関数
  const findLatestLocation = locations => {
    if (!locations || locations.length === 0) {
      return {
        end_datetime: new Date(),
        loc_code: "",
        loc_name: "",
      }
    }
    const latestLocation = locations.reduce((latest, current) => {
      const latestDate = new Date(latest.end_datetime)
      const currentDate = new Date(current.end_datetime)
      return latestDate > currentDate ? latest : current
    })
    return {
      end_datetime: latestLocation.end_datetime,
      loc_code: latestLocation.loc_code,
      loc_name: latestLocation.loc_name,
    }
  }

  // 特定の文字列を除外して比較用のラベルを取得する関数
  const getComparisonLabel = (label) => {
    if (!label) return ""
    return label.replace("(キャッシュレス)", "").replace("(カメラ)", "").trim()
  }

  // ipc_nameに含まれる特定のテキストをアイコンに置き換えるコンポーネント
  const LabelWithIcons = ({ label, isAuthorized, isGroup, isDifferent }) => {
    const isCameraAttached = label.includes("(カメラ)")
    const isCashless = label.includes("(キャッシュレス)")
    let displayLabel = label
    if (isCameraAttached) {
      displayLabel = displayLabel.replace("(カメラ)", "")
    }
    if (isCashless) {
      displayLabel = displayLabel.replace("(キャッシュレス)", "")
    }
    const labelStyle = isDifferent ? { color: "red", fontWeight: "bold" } : {}
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span style={labelStyle}>{displayLabel.trim()}</span>
        {!isGroup && isAuthorized === 0 && (
          <FaExclamationTriangle style={{ color: "red", marginLeft: "8px" }} />
        )}
        {!isGroup && isCameraAttached && (
          <FaCamera style={{ verticalAlign: "middle", marginLeft: "8px" }} />
        )}
        {!isGroup && isCashless && (
          <GrCreditCard style={{ verticalAlign: "middle", marginLeft: "8px" }} />
        )}
      </div>
    )
  }

  // 自販機一覧のフィルタ処理（検索、active状態、住所設定フィルタ）
  useEffect(() => {
    if (ipcsData && ipcsData.data) {
      let results = ipcsData.data.results.filter(ipc => ipc.ipc_no != null)
      // 常に active な自販機のみ表示
      results = results.filter(ipc => ipc.is_active === 1)
      if (searchQuery) {
        results = results.filter(ipc =>
          Object.values(ipc).some(value =>
            value
              ? value.toString().toLowerCase().includes(searchQuery.toLowerCase())
              : false
          )
        )
      }
      // チェックされている場合、住所情報がない自販機のみ抽出
      if (onlyNoAddress) {
        results = results.filter(ipc => !ipc.address)
      }
      setFilteredIpcs(results)
    } else {
      setFilteredIpcs([])
    }
  }, [searchQuery, ipcsData, onlyNoAddress])

  const handleSort = field => {
    let direction = sortDirection
    if (sortField === field) {
      direction = sortDirection === "asc" ? "desc" : "asc"
      setSortDirection(direction)
    } else {
      setSortField(field)
      setSortDirection("asc")
    }
    const sortedIpcs = [...filteredIpcs].sort((a, b) => {
      let aValue = a[field]
      let bValue = b[field]
      if (typeof aValue === "string") {
        aValue = aValue.toLowerCase()
      }
      if (typeof bValue === "string") {
        bValue = bValue.toLowerCase()
      }
      return direction === "asc" ? (aValue > bValue ? 1 : -1) : (aValue < bValue ? 1 : -1)
    })
    setFilteredIpcs(sortedIpcs)
  }

  const handleCompanyChange = e => {
    setSelectedCompanyId(e.target.value)
    setFormData({
      ...formData,
      company_id: e.target.value,
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            maintitle="メイン"
            title="管理"
            breadcrumbItem="自動販売機一覧"
          />
          <ErrorMessage />
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <CardTitle>自動販売機一覧</CardTitle>
                  <div style={{ display: "flex", marginBottom: "10px" }}>
                    <Input
                      type="text"
                      placeholder="自動販売機を検索..."
                      value={searchQuery}
                      onChange={e => setSearchQuery(e.target.value)}
                      style={{ width: "100%" }}
                    />
                  </div>
                  {/* 住所フィルタ用チェックボックス */}
                  <div style={{ marginBottom: "10px" }}>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          checked={onlyNoAddress}
                          onChange={e => setOnlyNoAddress(e.target.checked)}
                        />{" "}
                        住所設定無しのみ
                      </Label>
                    </FormGroup>
                  </div>
                  {/* ヘッダー */}
                  <div
                    style={{
                      display: "flex",
                      padding: "10px",
                      backgroundColor: "#e9ecef",
                      fontWeight: "bold",
                      borderRadius: "5px",
                      marginBottom: "10px",
                    }}
                  >
                    <div style={{ flex: 1, cursor: "pointer" }} onClick={() => handleSort("ipc_no")}>
                      IPC番号 {sortField === "ipc_no" ? (sortDirection === "asc" ? "▲" : "▼") : ""}
                    </div>
                    <div style={{ flex: 2, cursor: "pointer" }} onClick={() => handleSort("ipc_name")}>
                      IPC名 {sortField === "ipc_name" ? (sortDirection === "asc" ? "▲" : "▼") : ""}
                    </div>
                    <div style={{ flex: 2, cursor: "pointer" }} onClick={() => handleSort("loc_code")}>
                      ロケーションコード {sortField === "loc_code" ? (sortDirection === "asc" ? "▲" : "▼") : ""}
                    </div>
                    <div style={{ flex: 2, cursor: "pointer" }} onClick={() => handleSort("loc_name")}>
                      ロケーション名 {sortField === "loc_name" ? (sortDirection === "asc" ? "▲" : "▼") : ""}
                    </div>
                    {/* 住所設定カラム（ソート対象外） */}
                    <div style={{ flex: 2 }}>住所設定</div>
                    <div style={{ flex: 2, cursor: "pointer" }} onClick={() => handleSort("company_name")}>
                      会社名 {sortField === "company_name" ? (sortDirection === "asc" ? "▲" : "▼") : ""}
                    </div>
                  </div>
                  {/* 自販機一覧 */}
                  <div>
                    {filteredIpcs && filteredIpcs.length > 0 ? (
                      filteredIpcs.map(ipc => {
                        const ipcName = ipc.ipc_name || ""
                        const comparisonIpcName = getComparisonLabel(ipcName)
                        const isDifferent = comparisonIpcName !== ipc.loc_name
                        return (
                          <div
                            key={ipc.ipc_no}
                            onClick={() => handleVendingMachineClick(ipc)}
                            style={{
                              display: "flex",
                              padding: "10px",
                              borderBottom: "1px solid #dee2e6",
                              cursor: "pointer",
                              backgroundColor: "white",
                            }}
                          >
                            <div style={{ flex: 1 }}>{ipc.ipc_no}</div>
                            <div style={{ flex: 2 }}>
                              <LabelWithIcons
                                label={ipcName}
                                isAuthorized={ipc.is_authorized}
                                isGroup={ipc.is_group === 1}
                                isDifferent={isDifferent}
                              />
                            </div>
                            <div style={{ flex: 2 }}>{ipc.loc_code}</div>
                            <div style={{ flex: 2 }}>{ipc.loc_name}</div>
                            <div style={{ flex: 2 }}>{ipc.address ? "○" : ""}</div>
                            <div style={{ flex: 2 }}>{ipc.company_name}</div>
                          </div>
                        )
                      })
                    ) : (
                      <div style={{ padding: "10px", textAlign: "center" }}>
                        データがありません
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* 以下、モーダル部分は変更なし */}
          <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(false)}>
            <ErrorMessage />
            <ModalHeader toggle={() => setIsModalOpen(false)}>
              自動販売機詳細
            </ModalHeader>
            <ModalBody>
              <LoadingOverlay isLoading={isLoading} />
              {ipcDetail && (
                <Form>
                  <h4>自動販売機情報</h4>
                  <div className="mt-3 fw-bold">
                    <div className="mb-2 fs-5">IPC番号： {formData.ipc_no}</div>
                    <div className="fs-5 fw-bold">IPC名：{formData.ipc_name}</div>
                  </div>
                  <FormGroup>
                    {locations && (
                      <div style={{ marginTop: "20px" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <h4>ロケーション一覧</h4>
                          <Button color="primary" onClick={handleAddLocationClick}>
                            ＋
                          </Button>
                        </div>
                        <div
                          style={{
                            maxHeight: "300px",
                            overflowY: "auto",
                            border: "1px solid #dee2e6",
                            borderRadius: "0.5rem",
                            padding: "10px",
                          }}
                        >
                          {locations.map((location, index) => (
                            <Card
                              key={index}
                              style={{
                                marginBottom: "10px",
                                backgroundColor: location.is_active ? "white" : "lightgray",
                                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                              }}
                              onClick={() => handleLocationCardClick(location)}
                            >
                              <CardBody className="p-3">
                                <CardTitle tag="h5">{location.loc_name}</CardTitle>
                                <div>
                                  <strong>コード　:</strong> {location.loc_code}
                                </div>
                                <div>
                                  <strong>開始日時:</strong> {location.start_datetime}
                                </div>
                                <div>
                                  <strong>終了日時:</strong> {location.end_datetime}
                                </div>
                                <div>
                                  <strong>設置住所:</strong> {location.address}
                                </div>
                              </CardBody>
                            </Card>
                          ))}
                        </div>
                      </div>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="company_id">企業</Label>
                    <Input
                      type="select"
                      id="company_id"
                      value={selectedCompanyId}
                      onChange={handleCompanyChange}
                      disabled={true}
                    >
                      {(companies || []).map(company => (
                        <option key={company.id} value={company.id}>
                          {company.name}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="is_active">自販機情報の表示</Label>
                    <Input
                      type="select"
                      id="is_active"
                      value={formData.is_active}
                      onChange={handleChange}
                    >
                      <option value="1">表示する</option>
                      <option value="0">表示しない</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="display_order">表示順</Label>
                    <Input
                      type="number"
                      id="display_order"
                      value={formData.display_order}
                      onChange={handleChange}
                    />
                  </FormGroup>
                  <div className="d-flex">
                    <Button color="secondary" onClick={() => setIsModalOpen(false)} className="w-50">
                      キャンセル
                    </Button>
                    <Button color="primary" onClick={handleUpdate} className="w-50">
                      更新
                    </Button>
                  </div>
                </Form>
              )}
            </ModalBody>
          </Modal>
          <Modal isOpen={isLocationModalOpen} toggle={() => setIsLocationModalOpen(false)}>
            <ModalHeader toggle={() => setIsLocationModalOpen(false)}>
              ロケーション変更
            </ModalHeader>
            <ModalBody>
              <Form>
                <FormGroup>
                  <Label htmlFor="loc_code">ロケーションコード</Label>
                  <Input
                    type="text"
                    name="loc_code"
                    id="loc_code"
                    value={locationFormData.loc_code}
                    onChange={handleLocationFormChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="loc_name">ロケーション名</Label>
                  <Input
                    type="text"
                    name="loc_name"
                    id="loc_name"
                    value={locationFormData.loc_name}
                    onChange={handleLocationFormChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="start_datetime">開始日時</Label>
                  <Input
                    type="text"
                    name="start_datetime"
                    id="start_datetime"
                    value={locationFormData.start_datetime}
                    onChange={handleLocationFormChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="end_datetime">終了日時</Label>
                  <Input
                    type="text"
                    name="end_datetime"
                    id="end_datetime"
                    value={locationFormData.end_datetime}
                    onChange={handleLocationFormChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="comment">コメント</Label>
                  <Input
                    type="textarea"
                    name="comment"
                    id="comment"
                    value={locationFormData.comment}
                    onChange={handleLocationFormChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="address">設置場所</Label>
                  <Input
                    type="text"
                    name="address"
                    id="address"
                    value={locationFormData.address}
                    onChange={handleLocationFormChange}
                  />
                </FormGroup>
                {locationFormData.address && (
                  <div style={{ width: "100%", height: "400px", marginTop: "20px" }}>
                    <iframe
                      width="100%"
                      height="100%"
                      frameBorder="0"
                      style={{ border: 0 }}
                      src={mapUrl}
                      allowFullScreen
                    ></iframe>
                  </div>
                )}
                <div className="d-flex">
                  <Button
                    color="secondary"
                    className="w-25"
                    onClick={() => setIsLocationModalOpen(false)}
                  >
                    キャンセル
                  </Button>
                  <Button color="danger" className="w-25" onClick={handleLocationDelete}>
                    削除
                  </Button>
                  <Button color="primary" className="w-50" onClick={handleLocationUpdate}>
                    更新
                  </Button>
                </div>
              </Form>
            </ModalBody>
          </Modal>
          <Modal isOpen={isLocationAddModalOpen} toggle={() => setIsLocationAddModalOpen(false)}>
            <ModalHeader toggle={() => setIsLocationAddModalOpen(false)}>
              ロケーション登録
            </ModalHeader>
            <ModalBody>
              <Form>
                <FormGroup>
                  <Label htmlFor="loc_code">ロケーションコード</Label>
                  <Input
                    type="text"
                    name="loc_code"
                    id="loc_code"
                    value={locationFormData.loc_code}
                    onChange={handleLocationFormChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="loc_name">ロケーション名</Label>
                  <Input
                    type="text"
                    name="loc_name"
                    id="loc_name"
                    value={locationFormData.loc_name}
                    onChange={handleLocationFormChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="start_datetime">開始日時</Label>
                  <Input
                    type="datetime-local"
                    name="start_datetime"
                    id="start_datetime"
                    value={locationFormData.start_datetime}
                    onChange={handleLocationFormChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="end_datetime">終了日時</Label>
                  <Input
                    type="datetime-local"
                    name="end_datetime"
                    id="end_datetime"
                    value={locationFormData.end_datetime}
                    onChange={handleLocationFormChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="comment">コメント</Label>
                  <Input
                    type="textarea"
                    name="comment"
                    id="comment"
                    value={locationFormData.comment}
                    onChange={handleLocationFormChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="address">設置住所</Label>
                  <Input
                    type="text"
                    name="address"
                    id="address"
                    value={locationFormData.address}
                    onChange={handleLocationFormChange}
                  />
                </FormGroup>
                {locationFormData.address && (
                  <div style={{ width: "100%", height: "400px", marginTop: "20px" }}>
                    <iframe
                      width="100%"
                      height="100%"
                      frameBorder="0"
                      style={{ border: 0 }}
                      src={mapUrl}
                      allowFullScreen
                    ></iframe>
                  </div>
                )}
                <div className="d-flex">
                  <Button color="primary" onClick={handleLocationAdd}>
                    登録
                  </Button>
                  <Button color="secondary" onClick={() => setIsLocationAddModalOpen(false)}>
                    キャンセル
                  </Button>
                </div>
              </Form>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

const LabelWithIcons = ({ label, isAuthorized, isGroup, isDifferent }) => {
  const isCameraAttached = label.includes("(カメラ)")
  const isCashless = label.includes("(キャッシュレス)")
  let displayLabel = label
  if (isCameraAttached) {
    displayLabel = displayLabel.replace("(カメラ)", "")
  }
  if (isCashless) {
    displayLabel = displayLabel.replace("(キャッシュレス)", "")
  }
  const labelStyle = isDifferent ? { color: "red", fontWeight: "bold" } : {}
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span style={labelStyle}>{displayLabel.trim()}</span>
      {!isGroup && isAuthorized === 0 && (
        <FaExclamationTriangle style={{ color: "red", marginLeft: "8px" }} />
      )}
      {!isGroup && isCameraAttached && (
        <FaCamera style={{ verticalAlign: "middle", marginLeft: "8px" }} />
      )}
      {!isGroup && isCashless && (
        <GrCreditCard style={{ verticalAlign: "middle", marginLeft: "8px" }} />
      )}
    </div>
  )
}

export default Ipcs
