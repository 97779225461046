import { call, put, takeEvery } from "redux-saga/effects"
import {
  FETCH_MARKETING_COMMON_GOODS_CATEGORY_DATA,
  FETCH_MARKETING_COMMON_IPCS_DATA,
  FETCH_IPCS_DATA_DETAIL,
  UPDATE_IPCS_DATA,
} from "./actionTypes"
import {
  fetchGoodsCategoryDataSuccess,
  fetchGoodsCategoryDataError,
  fetchIpcsDataSuccess,
  fetchIpcsDataError,
  fetchIpcsDataDetailSuccess,
  fetchIpcsDataDetailError,
  updateIpcsDataSuccess,
  updateIpcsDataError,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getGoodsCategory,
  getIpcs,
  getIpcsDetail,
  updateIpcs,
} from "../../../helpers/backend_helper"

function* fetchGoodsCategoryDataSaga(action) {
  try {
    const graphData = yield call(getGoodsCategory)
    yield put(fetchGoodsCategoryDataSuccess(graphData))
  } catch (error) {
    yield put(fetchGoodsCategoryDataError(error))
  }
}

function* fetchIpcsDataSaga(action) {
  try {
    const { onlyCamera, onlyCashless, onlyIpc, pastLoc } = action.payload

    const locData = yield call(getIpcs, onlyCamera, onlyCashless, onlyIpc, pastLoc)
    yield put(fetchIpcsDataSuccess(locData))
  } catch (error) {
    yield put(fetchIpcsDataError(error))
  }
}

function* fetchIpcsDataDetailSaga(action) {
  try {
    const { ipc_no } = action

    const response = yield call(getIpcsDetail, ipc_no)
    const ipcDetail = response.data.results
    const companies = response.data.companies
    yield put(fetchIpcsDataDetailSuccess({ ipcDetail, companies }))
  } catch (error) {
    yield put(fetchIpcsDataDetailError(error))
  }
}

function* updateIpcsDataSaga(action) {
  try {
    const locData = yield call(updateIpcs, action.payload)
    yield put(updateIpcsDataSuccess(locData))
  } catch (error) {
    yield put(updateIpcsDataError(error))
  }
}

function* MarketingCommonSaga() {
  yield takeEvery(
    FETCH_MARKETING_COMMON_GOODS_CATEGORY_DATA,
    fetchGoodsCategoryDataSaga,
  )
  yield takeEvery(FETCH_MARKETING_COMMON_IPCS_DATA, fetchIpcsDataSaga)
  yield takeEvery(FETCH_IPCS_DATA_DETAIL, fetchIpcsDataDetailSaga)
  yield takeEvery(UPDATE_IPCS_DATA, updateIpcsDataSaga)
}

export default MarketingCommonSaga
