// store/goods-master/actions.js

import {
  GET_GOODS_MASTER,
  GET_GOODS_MASTER_SUCCESS,
  GET_GOODS_MASTER_FAIL,
  REGISTER_GOODS_MASTER,
  REGISTER_GOODS_MASTER_SUCCESS,
  REGISTER_GOODS_MASTER_FAIL,
} from "./actionTypes";

// 既存のアクションクリエーター
export const getGoodsMaster = (categoryId, locationId, includeNormal = false) => ({
  type: GET_GOODS_MASTER,
  payload: { categoryId, locationId, includeNormal },
});

export const getGoodsMasterSuccess = (data) => ({
  type: GET_GOODS_MASTER_SUCCESS,
  payload: data,
});

export const getGoodsMasterFail = (error) => ({
  type: GET_GOODS_MASTER_FAIL,
  error,
});

// 新規追加: 登録処理用のアクションクリエーター
export const registerGoodsMaster = (item) => ({
  type: REGISTER_GOODS_MASTER,
  payload: item,
});

export const registerGoodsMasterSuccess = () => ({
  type: REGISTER_GOODS_MASTER_SUCCESS,
});

export const registerGoodsMasterFail = (error) => ({
  type: REGISTER_GOODS_MASTER_FAIL,
  error,
});
