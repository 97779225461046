// src/pages/googleMapsConfig.js

export const googleMapsConfig = {
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY, // 環境変数からAPIキーを取得
    libraries: ["places", "geometry"], // 'marker' は無効なライブラリ名なので削除
    language: "ja", // 日本語を使用
    region: "JP", // 日本を地域に設定
    mapIds: [], // カスタムマップIDがない場合は空の配列
    nonce: "", // 必要に応じて設定（セキュリティ関連）
    url: "https://maps.googleapis.com/maps/api/js", // Google Maps APIのURL
    authReferrerPolicy: "origin", // リファラーのポリシー
  }
  