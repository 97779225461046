// src/pages/DeliveryPlanComplete.js

import React, { useEffect, useRef, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Container, Row, Col, Card, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, Table } from "reactstrap"
import ErrorMessage from "../Common/ErrorMessage"
import LoadingOverlay from "../Common/LoadingOverlay"
import { useDispatch, useSelector } from "react-redux"
import { fetchDeliveryPlanDetailRequest, updateDeliveryPlanRequest } from "../../store/deliveryPlan/actions"
import { GoogleMap, useJsApiLoader, Polyline, Marker, OverlayView } from "@react-google-maps/api"
import { decode } from "@googlemaps/polyline-codec"
import { googleMapsConfig } from "./googleMapsConfig"
import { fetchSoldOut } from "../../store/soldout/actions"
import "./App.css" // スタイル用のCSSファイルをインポート

const DeliveryPlanComplete = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const { deliveryPlan } = location.state || {}
  const mapRef = useRef(null)
  const [decodedRoute, setDecodedRoute] = useState([])
  const [isAddLocationModalOpen, setIsAddLocationModalOpen] = useState(false)
  const [insertPosition, setInsertPosition] = useState('end') // 'end' or index number
  const [selectedLocations, setSelectedLocations] = useState([])
  const [availableLocations, setAvailableLocations] = useState([])
  const [isUpdating, setIsUpdating] = useState(false)
  const [updatedWaypoints, setUpdatedWaypoints] = useState(null)
  
  // 備考を管理するstate
  const [notes, setNotes] = useState({})

  // Google Maps APIのロード
  const { isLoaded, loadError } = useJsApiLoader(googleMapsConfig)

  // 配送計画の詳細情報を取得
  const deliveryPlanDetail = useSelector(state => state.deliveryPlanReducer.deliveryPlanDetail)
  const isLoading = useSelector(state => state.deliveryPlanReducer.loading)

  useEffect(() => {
    if (deliveryPlan?.id) {
      dispatch(fetchDeliveryPlanDetailRequest(deliveryPlan.id))
    }
  }, [dispatch, deliveryPlan])

  // ルート情報が更新されたらデコード
  useEffect(() => {
    if (deliveryPlanDetail?.route?.route) {
      const decodedPath = decode(deliveryPlanDetail.route.route)
      setDecodedRoute(decodedPath)

      // 少し遅延を入れてマップの表示範囲を調整
      setTimeout(() => {
        if (mapRef.current && decodedPath.length > 0) {
          const bounds = new window.google.maps.LatLngBounds()
          decodedPath.forEach(([lat, lng]) => bounds.extend({ lat, lng }))
          mapRef.current.fitBounds(bounds)
        }
      }, 100)
    }
  }, [deliveryPlanDetail])

  const formatDateTime = (dateString) => {
    if (!dateString) return "未設定"
    return new Date(dateString).toLocaleString('ja-JP', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    })
  }

  // 移動時間を計算する関数
  const calculateTimeDifference = (startTime, endTime) => {
    if (!startTime || !endTime) return null
    
    const start = new Date(startTime)
    const end = new Date(endTime)
    const diffInSeconds = (end - start) / 1000
    
    const minutes = Math.floor(diffInSeconds / 60)
    const seconds = Math.floor(diffInSeconds % 60)
    
    return `${minutes}分${seconds}秒`
  }

  // 実績開始時刻と実績終了時刻の時間差を計算する関数
  const calculateActualTimeDifference = () => {
    const { actual_start_date, actual_end_date } = deliveryPlanDetail
    if (!actual_start_date || !actual_end_date) return "未設定"

    const start = new Date(actual_start_date)
    const end = new Date(actual_end_date)
    let diffInSeconds = (end - start) / 1000

    if (diffInSeconds < 0) return "時間差が不正です"

    const days = Math.floor(diffInSeconds / (3600 * 24))
    diffInSeconds %= 3600 * 24
    const hours = Math.floor(diffInSeconds / 3600)
    diffInSeconds %= 3600
    const minutes = Math.floor(diffInSeconds / 60)
    const seconds = Math.floor(diffInSeconds % 60)

    let diffString = ""
    if (days > 0) diffString += `${days}日 `
    if (hours > 0) diffString += `${hours}時間 `
    if (minutes > 0) diffString += `${minutes}分 `
    diffString += `${seconds}秒`

    return diffString
  }

  // 各区間の中間点を計算
  const calculateMidpoint = (point1, point2) => {
    return {
      lat: (point1.lat + point2.lat) / 2,
      lng: (point1.lng + point2.lng) / 2
    }
  }

  // 移動時間情報を生成
  // nextWaypointのstart_moving_timeとend_moving_timeを使用して、currentWaypoint→nextWaypoint間の移動時間を取得
  const getMoveTimeInfo = (index) => {
    const waypoints = deliveryPlanDetail.locations.waypoints
    if (index < waypoints.length - 1) {
      const nextWaypoint = waypoints[index + 1]
      
      // nextWaypointに移動時間が入っている場合
      if (nextWaypoint.start_moving_time && nextWaypoint.end_moving_time) {
        return calculateTimeDifference(
          nextWaypoint.start_moving_time,
          nextWaypoint.end_moving_time
        )
      }
    }
    return null
  }

  const renderMap = () => {
    if (!isLoaded) return null

    return (
      <GoogleMap
        mapContainerStyle={{ height: "400px", width: "100%" }}
        onLoad={map => {
          mapRef.current = map
          if (decodedRoute.length > 0) {
            const bounds = new window.google.maps.LatLngBounds()
            decodedRoute.forEach(([lat, lng]) => bounds.extend({ lat, lng }))
            map.fitBounds(bounds)
          }
        }}
        options={{
          mapId: "b163a3409a584201",
          disableDefaultUI: false,
          clickableIcons: false,
          scrollwheel: true,
          mapTypeControl: false,
        }}
        center={decodedRoute.length > 0 ? { 
          lat: decodedRoute[0][0], 
          lng: decodedRoute[0][1] 
        } : { lat: 35.6895, lng: 139.6917 }}
        zoom={decodedRoute.length > 0 ? 12 : 10}
      >
        {decodedRoute.length > 0 && (
          <Polyline
            path={decodedRoute.map(([lat, lng]) => ({
              lat,
              lng,
            }))}
            options={{
              strokeColor: "#FF0000",
              strokeOpacity: 1.0,
              strokeWeight: 3,
            }}
          />
        )}
        
        {/* マーカーと移動時間ラベルを表示 */}
        {deliveryPlanDetail?.route?.waypoints_info?.map((point, index) => {
          const nextPoint = deliveryPlanDetail.route.waypoints_info[index + 1]
          const moveTime = getMoveTimeInfo(index)
          const midpoint = nextPoint ? calculateMidpoint(point.location, nextPoint.location) : null

          return (
            <React.Fragment key={index}>
              <Marker
                position={point.location}
                label={{
                  text: String.fromCharCode(65 + index),
                  color: "#FFFFFF",
                  fontWeight: "bold",
                }}
              />
              
              {/* OverlayViewを使用して移動時間ラベルを中間点に表示 */}
              {moveTime && midpoint && (
                <OverlayView
                  position={midpoint}
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                >
                  <div className="move-time-label">
                    <span className="clock-icon">⏱️</span>
                    移動時間: {moveTime}
                  </div>
                </OverlayView>
              )}
            </React.Fragment>
          )
        })}
      </GoogleMap>
    )
  }

  // ロケーション追加モーダルの開閉
  const toggleAddLocationModal = () => {
    setIsAddLocationModalOpen(!isAddLocationModalOpen)
    if (!isAddLocationModalOpen) {
      // モーダルを開く時に利用可能なロケーション一覧を取得
      dispatch(fetchSoldOut({
        warehouseId: deliveryPlanDetail.start_warehouse_id,
        userId: deliveryPlanDetail.user_id,
        onlySoldOut: false
      }))
    }
  }

  // 利用可能なロケーション一覧を取得
  const soldOutData = useSelector(state => state.soldOutReducer.soldOutData || [])

  useEffect(() => {
    if (soldOutData.length > 0) {
      // 既存のロケーションIDを取得
      const existingLocIds = new Set(deliveryPlanDetail.locations.waypoints.map(wp => wp.id))
      // 既存のロケーションを除外した新しいロケーション一覧を設定
      const newLocations = soldOutData.filter(loc => !existingLocIds.has(loc.loc_id))
      setAvailableLocations(newLocations)
    }
  }, [soldOutData, deliveryPlanDetail])

  // ロケーション選択の切り替え
  const toggleLocationSelection = (locId) => {
    setSelectedLocations(prev => 
      prev.includes(locId) 
        ? prev.filter(id => id !== locId)
        : [...prev, locId]
    )
  }

  // 更新処理
  const handleUpdate = async () => {
    if (selectedLocations.length === 0) return

    setIsUpdating(true)
    const currentWaypoints = [...deliveryPlanDetail.locations.waypoints]
    const newLocations = selectedLocations.map(locId => {
      const loc = availableLocations.find(l => l.loc_id === locId)
      return {
        id: loc.loc_id,
        name: loc.loc_name,
        address: loc.loc_address,
        status: null,
        start_moving_time: null,
        end_moving_time: null,
        start_refilling_time: null,
        end_refilling_time: null
      }
    })

    // 挿入位置に応じてwaypointsを更新
    let newWaypoints
    if (insertPosition === 'end') {
      // 最後の倉庫の前に追加
      newWaypoints = [
        ...currentWaypoints.slice(0, -1),
        ...newLocations,
        currentWaypoints[currentWaypoints.length - 1]
      ]
    } else {
      const pos = parseInt(insertPosition)
      newWaypoints = [
        ...currentWaypoints.slice(0, pos + 1),
        ...newLocations,
        ...currentWaypoints.slice(pos + 1)
      ]
    }

    setUpdatedWaypoints(newWaypoints)
    setIsUpdating(false)
    toggleAddLocationModal()
  }

  // 反映ボタンの処理
  const handleApplyChanges = () => {
    // ここでnotesをサーバーに送る処理を追加することも可能
    // 例）updatedPlan.notes = notes;

    if (!updatedWaypoints && Object.values(notes).every(val => val.trim() === "")) return

    // waypointsに備考を追加
    const waypointsWithNotes = (updatedWaypoints || deliveryPlanDetail.locations.waypoints).map((waypoint, index) => ({
      ...waypoint,
      note: notes[index] || "" // 備考を追加
    }))    

    const updatedPlan = {
      ...deliveryPlanDetail,
      locations: {
        ...deliveryPlanDetail.locations,
        waypoints: waypointsWithNotes
      }
      // 備考情報を反映したい場合はここでupdatedPlanにnotesを格納する処理を追加
      // 例：updatedPlan.notes = notes;
    }

    dispatch(updateDeliveryPlanRequest({
      delivery_plan: updatedPlan,
      plan_id: deliveryPlanDetail.id
    }))
  }

  // 備考が変更されたときのハンドラ
  const handleNoteChange = (index, value) => {
    setNotes(prev => ({
      ...prev,
      [index]: value
    }))
  }

  if (loadError) {
    return <div>Google Maps APIのロード中にエラーが発生しました。</div>
  }

  // 備考に変化があるか判定
  const hasNotesChanges = Object.values(notes).some(value => value.trim() !== "")
  const shouldShowApplyChangesButton = updatedWaypoints || hasNotesChanges

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="ホーム" breadcrumbItem="配送計画" />
          <ErrorMessage />
          <LoadingOverlay isLoading={isLoading} />
          <Row>
            <Col xs={12}>
              <Card className="border">
                <CardBody
                  style={{ textAlign: "center", backgroundColor: "#fff" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "10px",
                      backgroundColor: "#fff",
                      border: "1px solid #ccc",
                      padding: "10px",
                      borderRadius: "5px",
                      flexDirection: "row",
                    }}
                  >
                    <h2 style={{ margin: 0 }}>計画NO: {deliveryPlanDetail?.id}</h2>
                    <p style={{ margin: 0 }}>
                      作成時刻: {formatDateTime(deliveryPlanDetail?.created_at)}
                    </p>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {deliveryPlanDetail?.route && (
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: "20px",
                        marginBottom: "20px",
                      }}
                    >
                      <Table bordered>
                        <tbody>
                          <tr>
                            <th>総距離</th>
                            <td>{deliveryPlanDetail.route.total_distance} km</td>
                            <th>総時間</th>
                            <td>{calculateActualTimeDifference()} 分</td>
                          </tr>
                          <tr>
                            <th>実績開始時刻</th>
                            <td>{formatDateTime(deliveryPlanDetail?.actual_start_date)}</td>
                            <th>実績終了時刻</th>
                            <td>{formatDateTime(deliveryPlanDetail?.actual_end_date)}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    {renderMap()}
                    <div style={{ 
                      marginTop: "20px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "10px"
                    }}>
                      <h5>ロケーション一覧</h5>
                      <Button 
                        color="primary" 
                        onClick={toggleAddLocationModal}
                        size="sm"
                      >
                        ロケーション追加
                      </Button>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      {(updatedWaypoints || deliveryPlanDetail.locations.waypoints)?.map((point, index) => {
                        const displayName = point.name
                        return (
                          <div 
                            key={index} 
                            style={{ 
                              margin: "5px 0",
                              padding: "10px",
                              border: "1px solid #ddd",
                              borderRadius: "4px",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "flex-start", 
                              backgroundColor: updatedWaypoints && index >= deliveryPlanDetail.locations.waypoints.length - 1 ? "#e3f2fd" : "white"
                            }}
                          >
                            <div style={{ width: "45%" }}>
                              <strong>{String.fromCharCode(65 + index)}:</strong>{" "}
                              {displayName} : {point.address}
                              {index > 0 && index < (updatedWaypoints || deliveryPlanDetail.locations.waypoints).length - 1 && (
                                <div style={{ marginTop: "5px" }}>
                                  {point.end_refilling_time && (
                                    <span 
                                      style={{ 
                                        backgroundColor: '#4CAF50',
                                        color: 'white',
                                        padding: '2px 8px',
                                        borderRadius: '4px',
                                        fontSize: '12px'
                                      }}
                                    >
                                      済
                                    </span>
                                  )}
                                </div>
                              )}
                            </div>
                            <div style={{ width: "50%" }}>
                              {/* 備考入力欄 */}
                              <Input 
                                type="textarea"
                                placeholder="備考を入力"
                                value={notes[index] || ""}
                                onChange={(e) => handleNoteChange(index, e.target.value)}
                                style={{ width: "100%", height: "60px" }}
                              />
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          {shouldShowApplyChangesButton && (
            <div style={{ 
              marginTop: "20px",
              textAlign: "center"
            }}>
              <Button 
                color="success" 
                onClick={handleApplyChanges}
                size="lg"
              >
                変更を反映
              </Button>
            </div>
          )}

          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <p>補充が完了しました</p>
            <p>お疲れ様でした。</p>
            <Button color="primary" onClick={() => navigate("/")}>
              TOPへ戻る
            </Button>
          </div>
        </Container>
      </div>

      {/* ロケーション追加モーダル */}
      <Modal isOpen={isAddLocationModalOpen} toggle={toggleAddLocationModal} size="lg">
        <ModalHeader toggle={toggleAddLocationModal}>
          ロケーション追加
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>追加位置</Label>
            <Input
              type="select"
              value={insertPosition}
              onChange={(e) => setInsertPosition(e.target.value)}
            >
              <option value="end">最後に追加</option>
              {deliveryPlanDetail?.route?.waypoints_names?.map((point, index) => {
                if (index > 0 && index < deliveryPlanDetail.route.waypoints_names.length - 1) {
                  return (
                    <option key={index} value={index}>
                      {String.fromCharCode(65 + index)}: {point.name.split(":")[1]} の後に追加
                    </option>
                  )
                }
                return null
              }).filter(Boolean)}
            </Input>
          </FormGroup>
          <div style={{ maxHeight: "400px", overflowY: "auto" }}>
            {availableLocations.map((loc) => (
              <div
                key={loc.loc_id}
                style={{
                  padding: "10px",
                  margin: "5px 0",
                  border: "1px solid #ddd",
                  borderRadius: "4px",
                  cursor: "pointer",
                  backgroundColor: selectedLocations.includes(loc.loc_id) ? "#e3f2fd" : "white"
                }}
                onClick={() => toggleLocationSelection(loc.loc_id)}
              >
                <div>{loc.loc_name}</div>
                <div style={{ fontSize: "0.9em", color: "#666" }}>{loc.loc_address}</div>
              </div>
            ))}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleAddLocationModal}>
            キャンセル
          </Button>
          <Button 
            color="primary" 
            onClick={handleUpdate}
            disabled={selectedLocations.length === 0 || isUpdating}
          >
            更新
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default DeliveryPlanComplete
