import { takeLatest, put, call } from "redux-saga/effects"
import { GET_IPC_ERRORS } from "./actionTypes"
import { getIpcErrorsSuccess, getIpcErrorsFail } from "./actions"
import { getIpcErrors } from "../../helpers/api/ipcErrorApi"

function* fetchIpcErrors({ payload: { locId } }) {
  try {
    const response = yield call(getIpcErrors, locId)
    yield put(getIpcErrorsSuccess(response.data))
  } catch (error) {
    yield put(getIpcErrorsFail(error))
  }
}

function* ipcErrorSaga() {
  yield takeLatest(GET_IPC_ERRORS, fetchIpcErrors)
}

export default ipcErrorSaga 