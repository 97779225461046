// store/goods-master/actionTypes.js

export const GET_GOODS_MASTER = "GET_GOODS_MASTER";
export const GET_GOODS_MASTER_SUCCESS = "GET_GOODS_MASTER_SUCCESS";
export const GET_GOODS_MASTER_FAIL = "GET_GOODS_MASTER_FAIL";

// 新規追加: 登録処理用のアクションタイプ
export const REGISTER_GOODS_MASTER = "REGISTER_GOODS_MASTER";
export const REGISTER_GOODS_MASTER_SUCCESS = "REGISTER_GOODS_MASTER_SUCCESS";
export const REGISTER_GOODS_MASTER_FAIL = "REGISTER_GOODS_MASTER_FAIL";
