import React, { useState, useEffect, useMemo } from "react"
import { Row, Col, FormGroup } from "reactstrap"
import {
  addDays,
  subDays,
  addMonths,
  subMonths,
  addYears,
  subYears,
  format,
} from "date-fns"

import { startOfMonth, endOfMonth } from "date-fns"
import DatePicker from "react-datepicker"
import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"
import {
  fetchGoodsCategoryData,
  fetchIpcsData,
} from "../store/marketing/common/actions"
import { FaCamera, FaMoneyCheck, FaExclamationTriangle } from "react-icons/fa"

import { GrCreditCard } from "react-icons/gr"
import { useLocation } from "react-router-dom"
import { ja } from "date-fns/locale"

function disableSidebar() {
  if (window.screen.width <= 992) {
    var body = document.body
    body.classList.remove("sidebar-enable")
  }
}

// ローカルストレージのキーを定数として定義
const STORAGE_KEYS = {
  SELECTED_LOC: 'selectedLoc',
  SELECTED_LOC_GROUP: 'selectedLocGroup'
};

const CommonComponent = ({
  goodsCategory,
  setGoodsCategory,
  locId,
  setLoc,
  setLocGroup,
  currentDate,
  setCurrentDate,
  timePeriod,
  setTimePeriod,
  previousDate,
  showGoodsCategory = true,
  showDate = true,
  showAll = true,
  showLocGroup = true,
  onlyCamera = false,
  vendor_id = null,
  setVendor = null,
  past_loc = null,
}) => {
  const graphData = useSelector(state => state.marketingCommonReducer.graphData)
  const goodsCategoryData =
    graphData && graphData.data ? graphData.data.goodsCategoryResults : []
  const locData = useSelector(state => state.marketingCommonReducer.locData)

  const storedLoc = Number(localStorage.getItem("selectedLoc"))

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)

  const [onlyCameraState, setOnlyCameraState] = useState(onlyCamera)
  const [onlyCashlessState, setOnlyCashlessState] = useState(false)
  const [includePastLocations, setIncludePastLocations] = useState(() => {
    // ローカルストレージから値を読み込む
    const storedValue = localStorage.getItem("includePastLocations")
    // storedValueがnullではない場合は、その値（真偽値）を返し、
    // そうでなければデフォルト値のfalseを返す
    return storedValue == "true" ? true : false
  })
  const dispatch = useDispatch()

  // URLのクエリパラメータを取得
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const loc_id_from_url = queryParams.get("loc_id")
  const loc_id_as_number = loc_id_from_url ? parseInt(loc_id_from_url, 10) : null
  const loc_group_from_url = queryParams.get("loc_group")
  const loc_group_as_number = loc_group_from_url ? parseInt(loc_group_from_url, 10) : null

  useEffect(() => {
    if (loc_id_from_url !== null) {
      setAndStoreIpcs(loc_id_as_number)
    }
    if (loc_group_from_url !== null) {
      setAndStoreLocGroup(loc_group_as_number)
    }
  }, [loc_id_as_number, loc_group_as_number])

  // 1. データをローカルストレージに保存する関数
  const storeIpcsInLocalStorage = () => {
    const currentTimestamp = new Date().getTime()
    const storedData = {
      timestamp: currentTimestamp,
    }
    localStorage.setItem("commonData", JSON.stringify(storedData))
  }

  // 2. データをローカルストレージから取得する関数
  const getIpcsFromLocalStorage = () => {
    //const storedData = localStorage.getItem('commonData');
    //if (storedData) {
    //  const { timestamp } = JSON.parse(storedData);
    //  const currentTimestamp = new Date().getTime();
    // データが1時間以上前に保存されている場合はnullを返す
    //  if (currentTimestamp - timestamp > 3600000) {
    //    return null;
    //  }
    //  return 'OK';
    //}
    return null
  }

  const handlePrevPeriod = () => {
    setCurrentDate(prevDate => {
      if (timePeriod === "時") return subDays(prevDate, 1)
      if (timePeriod === "日") return subMonths(prevDate, 1)
      if (timePeriod === "月") return subYears(prevDate, 1)
      return prevDate
    })
  }

  const handleNextPeriod = () => {
    setCurrentDate(prevDate => {
      if (timePeriod === "時") return addDays(prevDate, 1)
      if (timePeriod === "日") return addMonths(prevDate, 1)
      if (timePeriod === "月") return addYears(prevDate, 1)
      return prevDate
    })
  }

  const handleDateChange = date => {
    if (timePeriod !== "時") {
      date = endOfMonth(date)
    }
    setCurrentDate(date)
    setIsDatePickerOpen(false)
  }

  const goodsCategoryOptions = useMemo(() => {
    if (!goodsCategoryData) return []

    return [
      { label: "ALL", value: null },
      { label: "カテゴリーなし", value: 999999999 },
      ...goodsCategoryData.map(item => ({
        label: item.goods_category_name,
        value: item.goods_category_id,
      })),
    ]
  }, [goodsCategoryData])

  const locDataOptions = useMemo(() => {
    if (
      !locData ||
      !locData.data ||
      !locData.data.results ||
      locData.data.results.length === 0
    )
      return []

    const options = locData.data.results
      .filter(item => {
        // showLocGroupがfalseの場合、グループを除外
        if (!showLocGroup && item.is_group === 1) {
          return false;
        }
        return true;
      })
      .map(item => {
        // グループかどうかで表示ラベルを変更
        const label = item.is_group === 1
          ? `【 ${item.location_group_name}】`
          : item.loc_name

        return {
          label: label,
          value: item.is_group === 1 ? item.location_group_id : item.loc_id,
          vendor_id: item.vendor_id,
          ipc_no: item.ipc_no,
          loc_code: item.loc_code,
          isAuthorized: item.is_authorized,
          isGroup: item.is_group === 1
        }
      })

    if (showAll) {
      options.unshift({ label: "ALL", value: null })
    }

    return options
  }, [locData, showAll, showLocGroup])

  //自販機検索モーダルの引数
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")
  const [filteredIpcsOptions, setFilteredIpcsOptions] = useState(locDataOptions)

  // モーダルの検索処理
  useEffect(() => {
    const searchTermLower = searchTerm.toLowerCase()
    const filteredOptions = locDataOptions.filter(
      option =>
        // option.labelがnullまたはundefinedの場合に空文字列を代入
        (option.label ? option.label.toLowerCase() : "").includes(
          searchTermLower
        ) ||
        // option.ipc_noがnullまたはundefinedの場合に空文字列を代入し、toString()を呼び出す
        (option.ipc_no ? option.ipc_no.toString().toLowerCase() : "").includes(
          searchTermLower
        ) ||
        // option.loc_codeがnullまたはundefinedの場合に空文字列を代入
        (option.loc_code ? option.loc_code.toLowerCase() : "").includes(
          searchTermLower
        )
    )

    setFilteredIpcsOptions(filteredOptions)
  }, [searchTerm, locDataOptions])

  useEffect(() => {
    if (timePeriod === "日" || timePeriod === "3か月" || timePeriod === "月") {
      setCurrentDate(prevDate => endOfMonth(prevDate))
    } else if (
      timePeriod === "時" &&
      currentDate.getDate() === endOfMonth(currentDate).getDate()
    ) {
      setCurrentDate(new Date())
    }
  }, [timePeriod])

  function findVendorIdByValue(value) {
    const valueToSearch = Number(value)
    const option = locDataOptions.find(opt => opt.value === valueToSearch)
    return option ? option.vendor_id : null
  }

  // showAllがfalseで、現在の選択が"ALL"（またはnull）である場合、先頭のオプションを選択
  useEffect(() => {
    if (locDataOptions.length > 0) {
      if (locId === "" && loc_id_from_url !== null) {
        // URLのクエリパラメータにloc_idが存在す場合
        setAndStoreIpcs(loc_id_as_number)
        const vendorId = findVendorIdByValue(loc_id_from_url)
        if (vendorId !== null && setVendor) {
          setVendor(vendorId)
        }
      } else if (loc_id_from_url === null && loc_group_from_url !== null) {
        // URLのクエリパラメータにloc_groupが存在する場合
        setAndStoreLocGroup(loc_group_as_number)
        setSelectedLocGroup(loc_group_as_number)
        if (setLocGroup) {
          setLocGroup(loc_group_as_number)
        }
      } else if (locId === "" && loc_id_from_url === null && loc_group_from_url === null) {
        const storedLoc = Number(localStorage.getItem(STORAGE_KEYS.SELECTED_LOC))
        const storedLocGroup = Number(localStorage.getItem(STORAGE_KEYS.SELECTED_LOC_GROUP))

        if (storedLocGroup) {
          setAndStoreLocGroup(storedLocGroup)
          setSelectedLocGroup(storedLocGroup)
          if (setLocGroup) {
            setLocGroup(storedLocGroup)
          }
        } else if (storedLoc) {
          setAndStoreIpcs(storedLoc)
          const vendorId = findVendorIdByValue(storedLoc)
          if (vendorId !== null && setVendor) {
            setVendor(vendorId)
          }
        } else {
          setAndStoreIpcs(locDataOptions[0].value)
          if (setVendor && locDataOptions[0].vendor_id) {
            setVendor(locDataOptions[0].vendor_id)
          }
        }
      }
    }
  }, [locDataOptions])

  useEffect(() => {
    if (past_loc !== null) {
      setIncludePastLocations(past_loc)
    }

    dispatch(
      fetchIpcsData(
        onlyCameraState,
        onlyCashlessState,
        null,
        includePastLocations
      )
    )
    dispatch(fetchGoodsCategoryData())
  }, [dispatch, onlyCameraState, onlyCashlessState, includePastLocations, past_loc])

  // ロケーションIDの保存と設定
  const setAndStoreIpcs = newLoc => {
    setLoc(newLoc)
    localStorage.setItem(STORAGE_KEYS.SELECTED_LOC, newLoc)
  }

  // ロケーショングループIDの保存と設定
  const setAndStoreLocGroup = newLocGroup => {
    if (setLocGroup) {
      setLocGroup(newLocGroup)
      localStorage.setItem(STORAGE_KEYS.SELECTED_LOC_GROUP, newLocGroup)
    }
  }

  const handleTimePeriodChange = period => {
    setTimePeriod(period)
  }

  const formatDateDisplay = date => {
    switch (timePeriod) {
      case "月":
        return format(date, "yyyy年")
      case "日":
        return format(date, "yyyy年M月")
      case "時":
        return format(date, "yyyy年M月d日")
      default:
        return format(date, "yyyy年M月d日")
    }
  }

  const LabelWithCamera = ({ label, isAuthorized, isGroup }) => {
    const isCameraAttached = label.includes("(カメラ)")
    const isCashless = label.includes("(キャッシュレス)")

    let displayLabel = label
    if (isCameraAttached) {
      displayLabel = displayLabel.replace("(カメラ)", "")
    }
    if (isCashless) {
      displayLabel = displayLabel.replace("(キャッシュレス)", "")
    }

    return (
      <div>
        {displayLabel}
        {!isGroup && isAuthorized === 0 && (
          <FaExclamationTriangle style={{ color: "red", marginLeft: "8px" }} />
        )}
        {!isGroup && isCameraAttached && (
          <FaCamera style={{ verticalAlign: "middle", marginLeft: "8px" }} />
        )}
        {!isGroup && isCashless && (
          <GrCreditCard style={{ verticalAlign: "middle", marginLeft: "8px" }} />
        )}
      </div>
    )
  }

  // locGroupのstateを追加
  const [selectedLocGroup, setSelectedLocGroup] = useState(() => {
    // ローカルストレージから初期値を取得
    const storedLocGroup = localStorage.getItem(STORAGE_KEYS.SELECTED_LOC_GROUP)
    return storedLocGroup ? Number(storedLocGroup) : null
  })

  return (
    <React.Fragment>
      <Row>
        <Col lg={8}>
          <p className="text-muted mb-2">対象自販機</p>
          <div className="d-flex align-items-center mb-2">
            <Select
              className="custom-dropdown flex-grow-1"
              isSearchable={false}
              value={locDataOptions.find(option => {
                if (selectedLocGroup !== null && selectedLocGroup !== undefined && !isNaN(selectedLocGroup)) {
                  // グループが選択されている場合
                  return option.isGroup && option.value === selectedLocGroup;
                } else {
                  // 個別のロケーションが選択されている場合
                  return !option.isGroup && option.value === locId
                }
              })}
              onChange={selectedOption => {
                const newValue = selectedOption ? selectedOption.value : null
                const newValue2 = selectedOption ? selectedOption.vendor_id : null

                if (selectedOption && selectedOption.isGroup) {
                  // グループが選択された場合
                  setSelectedLocGroup(newValue)
                  setAndStoreLocGroup(newValue)
                  setLoc("")  // locIdをクリア
                  setAndStoreIpcs("")  // ローカルストレージのlocIdもクリア
                  if (setLocGroup) {
                    setLocGroup(newValue)  // 親コンポーネントのlocGroupを更新
                  }
                } else {
                  // 個別のロケーションが選択された場合
                  setSelectedLocGroup(null)
                  setAndStoreLocGroup(null)
                  setLoc(newValue)
                  setAndStoreIpcs(newValue)
                  if (setLocGroup) {
                    setLocGroup(null)  // 親コンポーネントのlocGroupをクリア
                  }
                }

                if (setVendor) {
                  setVendor(newValue2)
                }
              }}
              options={locDataOptions}
              formatOptionLabel={option => (
                <LabelWithCamera
                  label={option.label}
                  isAuthorized={option.isAuthorized}
                  isGroup={option.isGroup}
                />
              )}
            />
            <button
              onClick={() => {
                setIsModalOpen(true)
                disableSidebar()
              }}
              className="btn btn-primary ml-2 flex-shrink-0"
            >
              検索
            </button>
          </div>
          <div className="mt-2">
            <label style={{ verticalAlign: "middle" }}>
              <input
                type="checkbox"
                checked={onlyCameraState}
                onChange={() => setOnlyCameraState(!onlyCameraState)}
                style={{ verticalAlign: "middle" }}
              />
              <FaCamera
                style={{ verticalAlign: "middle", marginLeft: "8px" }}
              />{" "}
              カメラ付のみ
            </label>
            <label style={{ verticalAlign: "middle" }}>　　</label>
            <label style={{ verticalAlign: "middle" }}>
              <input
                type="checkbox"
                checked={onlyCashlessState}
                onChange={() => setOnlyCashlessState(!onlyCashlessState)}
                style={{ verticalAlign: "middle" }}
              />
              <GrCreditCard
                style={{ verticalAlign: "middle", marginLeft: "8px" }}
              />{" "}
              キャッシュレスのみ
            </label>
            <label style={{ verticalAlign: "middle" }}>　　</label>
            <label style={{ verticalAlign: "middle" }}>
              <input
                type="checkbox"
                checked={includePastLocations}
                onChange={e => setIncludePastLocations(e.target.checked)}
                style={{ verticalAlign: "middle" }}
                disabled={!past_loc}
              />{" "}
              過去ロケーションを含める
            </label>
          </div>

          {/* 対象自販機のデータがなければメッセージを表示 */}
          {locDataOptions.length === 0 && (
            <div className="mt-2" style={{ color: "red" }}>
              対象自販機がありません
            </div>
          )}
          {showGoodsCategory && (
            <div className="mt-3">
              <p className="text-muted mb-2">対象商品カテゴリー</p>
              <Select
                isSearchable={false}
                value={
                  goodsCategoryOptions.find(
                    option => option.value === goodsCategory
                  ) || goodsCategoryOptions[0]
                }
                onChange={selectedOption => {
                  const newValue = selectedOption ? selectedOption.value : null
                  setGoodsCategory(newValue)
                }}
                options={goodsCategoryOptions}
              />
            </div>
          )}
        </Col>
        <Col lg={5}>
          {showDate && (
            <div
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Row>
                <Col lg={5}>
                  {showDate && (
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        marginLeft: "40px",
                      }}
                    >
                      <Row>
                        <Col xs={12} className="d-flex align-items-center mt-3">
                          <div
                            style={{
                              minWidth: "80px",
                              textAlign: "right",
                              fontWeight: "bold",
                              fontSize: "12px",
                              marginRight: "5px",
                            }}
                          >
                            表示モード
                          </div>
                          <div
                            className="d-flex justify-content-start align-items-center"
                            style={{ gap: "5px" }}
                          >
                            <button
                              onClick={() => handleTimePeriodChange("月")}
                              className={`btn btn-sm ${timePeriod === "月"
                                ? "btn-primary"
                                : "btn-outline-primary"
                                }`}
                              style={{ minWidth: "60px" }}
                            >
                              月
                            </button>
                            <button
                              onClick={() => handleTimePeriodChange("日")}
                              className={`btn btn-sm ${timePeriod === "日"
                                ? "btn-primary"
                                : "btn-outline-primary"
                                }`}
                              style={{ minWidth: "60px" }}
                            >
                              日
                            </button>
                            <button
                              onClick={() => handleTimePeriodChange("時")}
                              className={`btn btn-sm ${timePeriod === "時"
                                ? "btn-primary"
                                : "btn-outline-primary"
                                }`}
                              style={{ minWidth: "60px" }}
                            >
                              時
                            </button>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col xs={4} className="d-flex align-items-center">
                          <div
                            style={{
                              minWidth: "80px",
                              textAlign: "right",
                              fontWeight: "bold",
                              fontSize: "12px",
                              marginRight: "5px",
                            }}
                          >
                            期間
                          </div>
                          <div
                            className="d-flex align-items-center"
                            style={{ gap: "5px", width: "100%" }}
                          >
                            <button
                              onClick={handlePrevPeriod}
                              className="btn btn-primary"
                              style={{ padding: "0.25rem 0.5rem" }}
                            >
                              &lt;
                            </button>
                            <div
                              style={{
                                flexGrow: 1,
                                display: "flex",
                                justifyContent: "center",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {isDatePickerOpen ? (
                                <DatePicker
                                  locale={ja}
                                  selected={currentDate}
                                  onChange={handleDateChange}
                                  inline
                                  style={{ width: "100%" }}
                                />
                              ) : (
                                <h7
                                  onClick={() => setIsDatePickerOpen(true)}
                                  style={{ margin: "0" }}
                                >
                                  {formatDateDisplay(previousDate)}
                                </h7>
                              )}
                            </div>
                            <button
                              onClick={handleNextPeriod}
                              className="btn btn-primary"
                              style={{ padding: "0.25rem 0.5rem" }}
                            >
                              &gt;
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          )}
        </Col>
      </Row>
      {/* 対象自販機の検索モーダルの実装 */}
      {isModalOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0,0,0,0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 10,
          }}
        >
          <div
            style={{
              width: window.innerWidth < 500 ? "85%" : "70%",
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "8px",
              marginLeft: window.innerWidth >= 932 ? "200px" : "0",
            }}
          >
            <h4>対象自販機を検索</h4>
            <div
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <input
                type="text"
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                placeholder="自販機名で検索"
                className="form-control mb-2 custom-input"
                style={{ paddingRight: "30px", color: "#000" }}
              />
              <span
                onClick={() => setSearchTerm("")}
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                  zIndex: 2,
                }}
              >
                <i className="ti-close"></i>
              </span>
            </div>
            <div
              style={{ maxHeight: "300px", overflowY: "auto", width: "100%" }}
            >
              {filteredIpcsOptions.map(option => (
                <div
                  key={option.value}
                  style={{ cursor: "pointer", padding: "5px" }}
                  onClick={() => {
                    setAndStoreIpcs(option.value)
                    if (setVendor && option.vendor_id) {
                      setVendor(option.vendor_id)
                    }
                    setIsModalOpen(false)
                  }}
                >
                  {option.label}
                </div>
              ))}
            </div>
            <button
              onClick={() => setIsModalOpen(false)}
              className="btn btn-secondary mt-4 w-100"
            >
              閉じる
            </button>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default CommonComponent
