import { call, put, takeEvery } from "redux-saga/effects"
import { FETCH_MARKETING_CUSTOMER_GRAPH_DATA } from "./actionTypes"
import { fetchGraphDataSuccess, fetchGraphDataError } from "./actions"

//Include Both Helper File with needed methods
import { getMarketingCustomer } from "../../../helpers/backend_helper"

function* fetchGraphDataSaga(action) {
  try {
    const { goodsCategory, locId, timePeriod, currentDate, previousDate, locGroup } =
      action.payload
    const graphData = yield call(
      getMarketingCustomer,
      goodsCategory,
      locId,
      timePeriod,
      currentDate,
      previousDate,
      locGroup
    )
    yield put(fetchGraphDataSuccess(graphData))
  } catch (error) {
    yield put(fetchGraphDataError(error))
  }
}

function* MarketingCustomerSaga() {
  yield takeEvery(FETCH_MARKETING_CUSTOMER_GRAPH_DATA, fetchGraphDataSaga)
}

export default MarketingCustomerSaga
