import { startOfMonth, subDays, subMonths, subYears } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { fetchGraphData, updateStockItem } from "../../store/stocks/actions";
import LoadingOverlay from "../Common/LoadingOverlay";
import CommonComponent from "../CommonComponent";

import {
  StyledButton1,
  StyledButton4,
  StyledButtonRow,
  StyledInput1,
  StyledTableCell,
  StyledTableHeader,
  StyledValueCell,
} from "../../components/StyledComponents";
import ErrorMessage from "../Common/ErrorMessage";

const Stocks = () => {
  const [locId, setLoc] = useState("");
  const [goodsCategory, setGoodsCategory] = useState(null);
  const [vendor_id, setVendor] = useState(null);
  const [timePeriod, setTimePeriod] = useState("時");
  const [currentDate, setCurrentDate] = useState(new Date());
  const [modal, setModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [sliderValue, setSliderValue] = useState(0);
  const [stockValue, setStockValue] = useState(0);
  const [switchModal, setSwitchModal] = useState(false);
  const [switchItem, setSwitchItem] = useState(null);
  const [switchStockValue, setSwitchStockValue] = useState(0);
  const [switchGoodsName, setSwitchGoodsName] = useState("");
  const [switchProductExpiry, setSwitchProductExpiry] = useState(null);
  const [switchFullValue, setSwitchFullValue] = useState(1);
  const [switchGoodsNo, setSwitchGoodsNo] = useState("");
  const [switchPrice, setSwitchPrice] = useState(0);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [nowDate, setNowDate] = useState("");

  const [retentionPeriod, setRetentionPeriod] = useState(90); // ※今回、滞留期日は削除します
  const [locGroup, setLocGroup] = useState(null);
  const [showInactive, setShowInactive] = useState(false);
  const [isStayEnabled, setIsStayEnabled] = useState(false);
  const [maxStayDays, setMaxStayDays] = useState(90);

  // productExpiry 状態：直近の賞味期限（初期値：現在＋90日、23:59:59）
  const [productExpiry, setProductExpiry] = useState(() => {
    const date = new Date();
    date.setDate(date.getDate() + 90);
    date.setHours(23, 59, 59);
    return date;
  });

  const dispatch = useDispatch();
  const graphData = useSelector(state => state.stocksReducer.graphData);
  const isLoading = useSelector(state => state.stocksReducer.loading);
  const isLoading1 = useSelector(state => state.stocksReducer.loading1);
  const results = graphData && graphData.data ? graphData.data.results : [];

  const hasZeroFullStock = results.some(result => result.full_stock === 0);

  useEffect(() => {
    const now = new Date();
    const formattedDate = `${now.getFullYear()}/${String(
      now.getMonth() + 1
    ).padStart(2, "0")}/${String(now.getDate()).padStart(2, "0")} ${String(
      now.getHours()
    ).padStart(2, "0")}:${String(now.getMinutes()).padStart(2, "0")}`;
    setNowDate(formattedDate);
  }, []);

  // previousDate の定義（locId 変更時の fetchGraphData で利用）
  const previousDate = useMemo(() => {
    if (timePeriod === "月") {
      return startOfMonth(subYears(currentDate, 1));
    } else if (timePeriod === "日") {
      return startOfMonth(currentDate);
    } else if (timePeriod === "時") {
      return subDays(currentDate, 1);
    } else if (timePeriod === "3か月") {
      return startOfMonth(subMonths(currentDate, 3));
    }
  }, [timePeriod, currentDate]);

  // locId が変更されたらグラフデータをフェッチ
  useEffect(() => {
    if (locId !== "") {
      dispatch(
        fetchGraphData(goodsCategory, locId, timePeriod, currentDate, previousDate)
      );
    }
  }, [locId, goodsCategory, timePeriod, currentDate, previousDate, dispatch]);

  useEffect(() => {
    if (isLoading1 === false && switchModal === true) {
      setSwitchModal(false);
      setModal(false);
    }
  }, [isLoading1]);

  // 賞味期限が今日から1週間以内か判定
  const isWithinOneWeek = dateStr => {
    if (!dateStr) return false;
    
    // "2025年03月01日" → "2025-03-01"
    const formattedDateStr = dateStr.replace(/年|月/g, "-").replace(/日/, "");

    const today = new Date();
    const oneWeekLater = new Date(today);
    oneWeekLater.setDate(today.getDate() + 7);
    
    return new Date(formattedDateStr) <= oneWeekLater;
  };
  
  const getWarningIcon = (result) => {
    const isExpiringSoon = isWithinOneWeek(result.oldest_expiry_date);
    const isEndingSoon = isWithinOneWeek(result.oldest_end_date);
  
    if (isExpiringSoon) {
      return <span style={{ color: "red", fontSize: "16px", fontWeight: "bold" }}>！</span>;
    } else if (isEndingSoon) {
      return <span style={{ color: "blue", fontSize: "16px", fontWeight: "bold" }}>！</span>;
    }
    return null;
  };

  // formatExpiryDate: "YYYY年MM月DD日 HH:MM:SS" 形式にフォーマット
  const formatExpiryDate = dateStr => {
    if (!dateStr) return "データなし";
    const date = new Date(dateStr);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${year}年${month}月${day}日 ${hours}:${minutes}:${seconds}`;
  };

  // handleExpiryChange: ユーザーが日付を入力した際に、その日の23:59:59に固定して設定する
  const handleExpiryChange = e => {
    const selectedDate = new Date(e.target.value);
    selectedDate.setHours(23, 59, 59);
    setProductExpiry(selectedDate);
  };

  const handleSwitchModalOpen = () => {
    setSwitchItem({ ...selectedItem });
    setSwitchModal(true);
  };

  const handleSwitchGoodsNameChange = e => {
    setSwitchGoodsName(e.target.value);
  };

  const handleSwitchStockChange = e => {
    setSwitchStockValue(parseInt(e.target.value));
  };

  const handleSwitchExpiryChange = e => {
    const selectedDate = new Date(e.target.value);
    selectedDate.setHours(23, 59, 59);
    setSwitchProductExpiry(selectedDate);
  };

  const decreaseStock = () => {
    if (stockValue > 0 - selectedItem.current_stock) {
      setStockValue(stockValue - 1);
    }
  };

  const increaseStock = () => {
    if (stockValue < 100 && sliderValue > stockValue + selectedItem.current_stock) {
      setStockValue(stockValue + 1);
    }
  };

  const handleStockChange = e => {
    const value = parseInt(e.target.value);
    if (value >= 1 && value <= 100) {
      setStockValue(value);
    }
  };

  const fillStock = () => {
    setStockValue(selectedItem.full_stock - selectedItem.current_stock);
  };

  const switchStock = () => {
    let goodsName = selectedItem.goods_name;
    const index = goodsName.indexOf("_");
    if (index > -1) {
      goodsName = goodsName.slice(index + 1);
    }
    setSwitchGoodsName(goodsName);
    setSwitchStockValue(stockValue);
    setSwitchProductExpiry(productExpiry);
    setSwitchFullValue(sliderValue);
    setSwitchModal(true);
  };

  const decreaseFull = () => {
    if (sliderValue > 0 && sliderValue > selectedItem.current_stock) {
      const newValue = sliderValue - 1;
      setSliderValue(newValue);
      setSelectedItem({ ...selectedItem, full_stock: newValue });
    }
  };

  const increaseFull = () => {
    if (sliderValue < 100) {
      const newValue = sliderValue + 1;
      setSliderValue(newValue);
      setSelectedItem({ ...selectedItem, full_stock: newValue });
    }
  };

  const handleSliderChange = e => {
    const value = parseInt(e.target.value);
    setSelectedItem({ ...selectedItem, full_stock: value });
    setSliderValue(value);
  };

  // 以下は switchModal 用の操作
  const increaseSwitchStock = () => {
    setSwitchStockValue(prevValue => Math.min(prevValue + 1, 100));
  };
  const decreaseSwitchStock = () => {
    setSwitchStockValue(prevValue => Math.max(prevValue - 1, 1));
  };
  const increaseSwitchFull = () => {
    setSwitchFullValue(prevValue => Math.min(prevValue + 1, 100));
  };
  const decreaseSwitchFull = () => {
    setSwitchFullValue(prevValue => Math.max(prevValue - 1, 1));
  };

  const handleRetentionPeriodChange = e => {
    setRetentionPeriod(parseInt(e.target.value));
  };

  // 行クリック時またはモーダルを開く際に、selectedItem と productExpiry を設定する
  const openModal = (item) => {
    setSelectedItem(item);
    setStockValue(item.full_stock - item.current_stock);
    setSliderValue(item.full_stock);
    if (item.oldest_expiry_date) {
      // oldest_expiry_date がある場合はその値を初期値として設定
      const formattedDateStr = item.oldest_expiry_date
        .replace("年", "-")
        .replace("月", "-")
        .replace("日", "");
      const parsedDate = new Date(formattedDateStr);
      setProductExpiry(parsedDate);
    } else if (item.expiry_date) {
      setProductExpiry(new Date(item.expiry_date));
    } else {
      const defaultDate = new Date();
      defaultDate.setDate(defaultDate.getDate() + 90);
      defaultDate.setHours(23, 59, 59);
      setProductExpiry(defaultDate);
    }
    setIsStayEnabled(!!item.max_stay_flag);
    setMaxStayDays(item.max_stay_days || 90);
    setModal(true);
  };

  // 更新処理では、selectedItem の情報ではなく productExpiry 状態の値を利用し、ISO文字列に変換して送信する
  const handleUpdate = () => {
    if (selectedItem) {
      const updateData = {
        ...selectedItem,
        stockValue: stockValue + selectedItem.current_stock,  // 在庫数（必要に応じて調整）
        full_stock: selectedItem.full_stock,  // 満タン数
        goods_name: selectedItem.goods_name,  // 商品名
        product_expire_date: productExpiry.toISOString(),   // ISO文字列へ変換
        loc_id: selectedItem.loc_id,
        machine_address: selectedItem.machine_address,
        max_stay_days: isStayEnabled ? maxStayDays : null,
        max_stay_flag: isStayEnabled ? 1 : 0
      };
      
      dispatch(updateStockItem(updateData, selectedItem.loc_id));
      setModal(false);
    }
  };

  // 同様に switch update でも日付を文字列に変換
  const handleSwitchUpdate = () => {
    const updatedItem = {
      ...switchItem,
      goods_name: selectedItem.goods_name,
      switchGoodsName: switchGoodsName,
      switchStockValue: switchStockValue,
      switchFullValue: switchFullValue,
      switchPrice: switchPrice,
      switchGoodsNo: switchGoodsNo,
      productExpiry: (switchProductExpiry ? switchProductExpiry : productExpiry).toISOString(),
      machine_address: selectedItem.machine_address,
      stay_enabled: isStayEnabled,
      max_stay_days: isStayEnabled ? maxStayDays : null,
    };
    dispatch(updateStockItem(updatedItem, locId));
  };

  // 滞留日数を計算し、日付とセットで返す関数
  const calculateDaysStayedWithDate = (oldestEndDate, maxStayDays) => {
    if (!oldestEndDate || !maxStayDays) return "未設定";

    const formattedDateStr = oldestEndDate
      .replace("年", "-")
      .replace("月", "-")
      .replace("日", "");

    const endDate = new Date(formattedDateStr);
    if (isNaN(endDate)) return '日付が不正です';

    const createdAt = new Date(endDate);
    createdAt.setDate(createdAt.getDate() - maxStayDays);

    const now = new Date();
    const diffTime = now - createdAt;
    const daysStayed = Math.floor(diffTime / (1000 * 60 * 60 * 24));

    return `${daysStayed}日`;
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="ホーム" breadcrumbItem="自販機補充" />
          <ErrorMessage />
          <LoadingOverlay isLoading={isLoading1} />
          <Row>
            <Col xs={12}>
              <Card className="bg-secondary">
                <CardBody>
                  <h4 className="card-title mb-4">検索条件</h4>
                  <LoadingOverlay isLoading={isLoading} />
                  <CommonComponent
                    showGoodsCategory={false}
                    showDate={false}
                    showAll={false}
                    goodsCategory={goodsCategory}
                    setGoodsCategory={setGoodsCategory}
                    locId={locId}
                    setLoc={setLoc}
                    setLocGroup={setLocGroup}
                    currentDate={currentDate}
                    setCurrentDate={setCurrentDate}
                    timePeriod={timePeriod}
                    setTimePeriod={setTimePeriod}
                    previousDate={previousDate}
                    vendor_id={vendor_id}
                    setVendor={setVendor}
                    past_loc={false}
                    showLocGroup={false}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '1rem'
                  }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
                      <CardTitle className="mb-0">在庫状況</CardTitle>

                      {/* 凡例を追加 */}
                      <div style={{ fontSize: '14px', whiteSpace: 'nowrap', display: 'flex', gap: '10px' }}>
                        <span><span style={{ color: 'blue', fontWeight: 'bold' }}>！</span> = 滞留期限が7日以内</span>
                        <span><span style={{ color: 'red', fontWeight: 'bold' }}>！</span> = 賞味期限が7日以内</span>
                      </div>
                    </div>
                    <FormGroup check style={{
                      display: 'flex',
                      alignItems: 'center',
                      margin: 0
                    }}>
                      <Input
                        type="checkbox"
                        id="showInactiveCheckbox"
                        checked={showInactive}
                        onChange={(e) => setShowInactive(e.target.checked)}
                        style={{ marginRight: '0.5rem' }}
                        disabled={!hasZeroFullStock}
                      />
                      <label htmlFor="showInactiveCheckbox" style={{ marginBottom: 0, color: !hasZeroFullStock ? '#999' : 'inherit' }}>
                        満タン数0も表示
                      </label>
                    </FormGroup>
                  </div>
                  {/* テーブルヘッダー */}
                  <div style={{
                    display: 'flex',
                    borderBottom: '2px solid #dee2e6',
                    backgroundColor: '#f8f9fa',
                    fontWeight: 'bold'
                  }}>
                    <StyledTableHeader className="product-name">商品名</StyledTableHeader>
                    <StyledTableHeader className="centered">満タン</StyledTableHeader>
                    <StyledTableHeader className="centered">在庫</StyledTableHeader>
                    <StyledTableHeader className="centered">不足</StyledTableHeader>
                  </div>
                  <div style={{ width: '100%' }}>
                    {results.length > 0 ? (
                      results
                        .filter(result => showInactive || result.full_stock > 0)
                        .map((result, index) => (
                          <div
                            key={index}
                            onClick={() => openModal(result)}
                            style={{
                              display: 'flex',
                              borderBottom: '1px solid #dee2e6',
                              backgroundColor: result.full_stock === 0 ? '#fff3cd' :
                                index % 2 === 0 ? '#ffffff' : '#f8f9fa',
                              color: result.full_stock === 0 ? '#999' : 'inherit',
                              cursor: 'pointer'
                            }}
                          >
                            <StyledTableCell className="product-name">
                              {getWarningIcon(result)}
                              <span>{result.goods_name}</span>
                            </StyledTableCell>
                            <StyledValueCell>
                              {result.full_stock}
                            </StyledValueCell>
                            <StyledValueCell style={{
                              color: result.full_stock === 0 ? '#999' :
                                result.current_stock <= 0 ? "red" : "inherit",
                              fontWeight: result.current_stock <= 0 ? "bold" : "normal"
                            }}>
                              {result.current_stock}
                            </StyledValueCell>
                            <StyledValueCell>
                              {result.full_stock - result.current_stock}
                            </StyledValueCell>
                          </div>
                        ))
                    ) : (
                      <div style={{
                        padding: '0.75rem',
                        textAlign: 'center',
                        borderBottom: '1px solid #dee2e6'
                      }}>
                        データがありません
                      </div>
                    )}
                  </div>
                  <div style={{ textAlign: "right" }}>{nowDate}現在</div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            key={selectedItem ? selectedItem.ipc_no : "modal"}
            isOpen={modal}
            toggle={() => setModal(!modal)}
          >
            <ModalHeader toggle={() => setModal(!modal)}>
              商品情報更新
            </ModalHeader>
            <ModalBody>
              <FormGroup>
                <label>対象自販機名：</label>
                <h5>
                  <p>{selectedItem?.ipc_name || "N/A"}</p>
                </h5>
              </FormGroup>
              <FormGroup>
                <label>商品名：</label>
                <h5>
                  <p>{selectedItem?.goods_name || "N/A"}</p>
                </h5>
              </FormGroup>
              {selectedItem?.show_goods_no && (
                <FormGroup>
                  <label>商品番号：</label>
                  <h5>
                    <p style={{
                      color: selectedItem?.is_valid ? 'inherit' : 'red',
                      fontWeight: selectedItem?.is_valid ? 'normal' : 'bold'
                    }}>
                      {selectedItem?.goods_no || "N/A"}
                      {!selectedItem?.is_valid && selectedItem?.goods_no && " (商品マスタに未登録)"}
                    </p>
                  </h5>
                </FormGroup>
              )}
              {selectedItem && vendor_id !== 1 ? (
                <FormGroup>
                <label>直近の賞味期限</label>
                <Input
                  // 日付のみ入力、表示は YYYY-MM-DD のみ
                  type="date"
                  value={new Date(productExpiry).toISOString().split("T")[0]}
                  onChange={handleExpiryChange}
                />
                </FormGroup>
              ) : selectedItem && (
                <FormGroup
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    gap: "2rem",
                  }}
                >
                  <div>
                    <label>直近の賞味期限</label>
                    <h5>
                      <a
                        href={`/shelfs?loc_id=${selectedItem.loc_id}&column_no=${selectedItem.goods_name.split("_")[0]}&isStock=1`}
                        rel="noopener noreferrer"
                      >
                        {selectedItem.oldest_expiry_date}
                      </a>
                    </h5>
                  </div>                
                </FormGroup>
              )}
              {/* 滞留期限設定の有効/無効切り替え */}
              <FormGroup check className="mb-3">
                <Input
                  type="checkbox"
                  id="stayEnabled"
                  checked={isStayEnabled}
                  onChange={(e) => setIsStayEnabled(e.target.checked)}
                />
                <Label check for="stayEnabled">
                  滞留期限を設定する
                </Label>
              </FormGroup>

              {/* 滞留期限関連の設定（チェックボックスがONの時のみ表示） */}
              {isStayEnabled && (
                <>
                  <FormGroup>
                    <Label>滞留日数</Label>
                    <div className="bg-light p-2 rounded border">
                      {calculateDaysStayedWithDate(selectedItem.oldest_end_date, selectedItem.max_stay_days)}
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label>滞留日時設定（日）</Label>
                    <Input
                      type="number"
                      value={maxStayDays}
                      onChange={(e) => setMaxStayDays(parseInt(e.target.value))}
                      min={1}
                      max={999}
                    />
                  </FormGroup>
                </>
              )}
              <FormGroup>
                <label>満タン数</label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <StyledButton1 color="secondary" onClick={decreaseFull}>
                    -
                  </StyledButton1>
                  <StyledInput1
                    type="number"
                    min="0"
                    max="100"
                    value={sliderValue}
                    onChange={handleSliderChange}
                    step="1"
                  />
                  <StyledButton1 color="secondary" onClick={increaseFull}>
                    +
                  </StyledButton1>
                </div>
              </FormGroup>
              {vendor_id && vendor_id !== 1 && (
                <FormGroup>
                  <label>補充数(現時点の在庫数：{selectedItem?.current_stock})</label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <StyledButton1 color="secondary" onClick={decreaseStock}>
                      -
                    </StyledButton1>
                    <StyledInput1
                      type="number"
                      min="1"
                      max="100"
                      value={stockValue}
                      onChange={handleStockChange}
                      step="1"
                    />
                    <StyledButton1 color="secondary" onClick={increaseStock}>
                      +
                    </StyledButton1>
                  </div>
                </FormGroup>
              )}
            </ModalBody>
            <ModalFooter
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <StyledButtonRow>
                <StyledButton4 color="secondary" onClick={() => setModal(!modal)}>
                  キャンセル
                </StyledButton4>
                <StyledButton4 color="primary" onClick={handleUpdate}>
                  更新
                </StyledButton4>
              </StyledButtonRow>
            </ModalFooter>
          </Modal>

          <Modal isOpen={switchModal} toggle={() => setSwitchModal(!switchModal)}>
            <ModalHeader toggle={() => setSwitchModal(!switchModal)}>
              在庫入れ替え
            </ModalHeader>
            <ModalBody>
              <LoadingOverlay isLoading={isLoading1} />
              <FormGroup>
                <label>商品名</label>
                <Input
                  type="text"
                  value={switchGoodsName}
                  onChange={handleSwitchGoodsNameChange}
                />
              </FormGroup>
              <FormGroup>
                <label>補充数</label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Button color="secondary" onClick={decreaseSwitchStock} style={{ marginRight: "5px" }}>
                    -
                  </Button>
                  <Input
                    type="number"
                    min="0"
                    max="100"
                    value={switchStockValue}
                    onChange={handleSwitchStockChange}
                    step="1"
                    style={{ marginRight: "5px" }}
                  />
                  <Button color="secondary" onClick={increaseSwitchStock}>
                    +
                  </Button>
                </div>
              </FormGroup>
              <FormGroup>
                <label>満タン</label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Button color="secondary" onClick={decreaseSwitchFull} style={{ marginRight: "5px" }}>
                    -
                  </Button>
                  <Input
                    type="number"
                    min="1"
                    max="100"
                    value={switchFullValue}
                    onChange={e => setSwitchFullValue(+e.target.value)}
                    step="1"
                    style={{ marginRight: "5px" }}
                  />
                  <Button color="secondary" onClick={increaseSwitchFull}>
                    +
                  </Button>
                </div>
              </FormGroup>
              <FormGroup>
                <label>商品番号（goods_no）</label>
                <Input
                  type="text"
                  value={switchGoodsNo}
                  onChange={e => setSwitchGoodsNo(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <label>価格</label>
                <Input
                  type="number"
                  min="0"
                  value={switchPrice}
                  onChange={e => setSwitchPrice(+e.target.value)}
                  step="1"
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <StyledButtonRow>
                <StyledButton4 color="secondary" onClick={() => setSwitchModal(!switchModal)}>
                  キャンセル
                </StyledButton4>
                <StyledButton4 color="primary" onClick={handleSwitchUpdate}>
                  更新
                </StyledButton4>
              </StyledButtonRow>
            </ModalFooter>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Stocks;
