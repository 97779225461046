// store/goods-master/reducer.js

import {
  GET_GOODS_MASTER,
  GET_GOODS_MASTER_SUCCESS,
  GET_GOODS_MASTER_FAIL,
  REGISTER_GOODS_MASTER,
  REGISTER_GOODS_MASTER_SUCCESS,
  REGISTER_GOODS_MASTER_FAIL,
} from "./actionTypes";

const initialState = {
  goodsMasterData: [],
  loading: false,
  error: null,
  // 新規追加: 登録処理の状態
  registerLoading: false,
  registerError: null,
  registerSuccess: false,
};

const goodsMasterReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_GOODS_MASTER:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_GOODS_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        goodsMasterData: action.payload,
      };

    case GET_GOODS_MASTER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // 新規追加: REGISTER_GOODS_MASTER のハンドリング
    case REGISTER_GOODS_MASTER:
      return {
        ...state,
        registerLoading: true,
        registerError: null,
        registerSuccess: false,
      };

    case REGISTER_GOODS_MASTER_SUCCESS:
      return {
        ...state,
        registerLoading: false,
        registerSuccess: true,
      };

    case REGISTER_GOODS_MASTER_FAIL:
      return {
        ...state,
        registerLoading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default goodsMasterReducer;
