// src/pages/DeliveryPlanConfirm.js

import React, { useEffect, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardTitle,
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import ErrorMessage from "../Common/ErrorMessage"
import LoadingOverlay from "../Common/LoadingOverlay"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { updateDeliveryPlanRequest } from "../../store/deliveryPlan/actions"

// ★★★ CustomGoogleMap をインポート ★★★
import { decode } from "@googlemaps/polyline-codec";
import CustomGoogleMap from "./CustomGoogleMap"
import { getLabel } from "./labelUtils" // 必要に応じてインポート

const DeliveryPlanConfirm = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const { deliveryPlan } = location.state || {}

  const isLoading1 = useSelector(state => state.deliveryPlanReducer.loading)

  // ルートデコード用ステート
  const [decodedRoute, setDecodedRoute] = useState([])
  // 完了済みのロケーションを管理するステート（必要に応じて設定）
  const [completedLocations, setCompletedLocations] = useState([])
  // クラスター内のマーカー情報を管理する状態
  const [clusterMarkers, setClusterMarkers] = useState({
    position: null,
    labels: [],
  })

  // CustomGoogleMap 用のステート
  const [selectedMarker, setSelectedMarker] = useState(null)

  useEffect(() => {
    if (deliveryPlan?.route?.route) {
      const decodedPath = decode(deliveryPlan.route.route)
      setDecodedRoute(decodedPath)
    }

    // 必要に応じて completedLocations を設定
    // 例:
    // const completed = deliveryPlan.locations.waypoints
    //   .map((wp, index) => wp.isCompleted ? index : null)
    //   .filter(index => index !== null)
    // setCompletedLocations(completed)
  }, [deliveryPlan])

  const handleConfirm = () => {
    if (deliveryPlan) {
      const updatedPlan = {
        ...deliveryPlan,
        status: 2,
        actual_start_date: new Date().toISOString(),
      }

      dispatch(
        updateDeliveryPlanRequest({
          delivery_plan: updatedPlan,
        })
      )

      navigate("/delivery-plan/vehicle-select", {
        state: {
          deliveryPlan: updatedPlan,
          startLocationName: updatedPlan.start_warehouse_name,
        },
      })
    } else {
      console.error("Delivery plan is undefined")
    }
  }

  // CustomGoogleMap で使用するハンドラー
  const handleMarkerClick = (index) => {
    setSelectedMarker(index)
    // クラスターInfoWindowを閉じたい場合は下記のようにリセット
    setClusterMarkers({ position: null, labels: [] })
  }

  return (
    <React.Fragment>
      <div className="page-content" style={{ backgroundColor: "#dcdcdc" }}>
        <Container fluid={true}>
          <Breadcrumbs title="ホーム" breadcrumbItem="配送計画確認" />
          <ErrorMessage />
          <LoadingOverlay isLoading={isLoading1} />

          <Row className="justify-content-center">
            <Col xs={12} md={8}>
              <Card className="mx-auto p-3 bg-secondary" style={{ maxWidth: "950px" }}>
                <CardBody className="text-center">
                  <CardTitle tag="h4" className="text-center">
                    配送計画詳細
                  </CardTitle>
                  <hr />

                  {/* ルート情報の表示 */}
                  <div
                    className="border p-3 mb-3"
                    style={{
                      backgroundColor: "#dcdcdc",
                      borderRadius: "5px",
                      padding: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.2em",
                          margin: 0,
                        }}
                      >
                        計画No: {deliveryPlan.id}
                      </p>
                      <p style={{ margin: 0 }}>
                        作成時間: {new Date(deliveryPlan.created_at).toLocaleString()}
                      </p>
                    </div>
                  </div>
                  <div className="text-left" style={{ marginBottom: "20px" }}>
                    <p>担当者: {deliveryPlan.user_name}</p>
                    <CardTitle tag="h5">ルート情報</CardTitle>
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: "20px",
                        marginBottom: "20px",
                      }}
                    >                
                      <span style={{ marginRight: "5px" }}>
                        <strong>距離:</strong> 約{deliveryPlan.locations.total_distance} km
                      </span>
                      <span>
                        <strong>時間:</strong> 約{deliveryPlan.locations.total_duration} 分
                      </span>
                    </div>

                    {/* CustomGoogleMap を使用 */}
                    {decodedRoute.length > 0 && deliveryPlan.route?.waypoints_info?.length > 0 && (
                      <div
                        style={{
                          width: "100%",
                          height: "400px",
                          marginBottom: "20px",
                        }}
                      >
                        <CustomGoogleMap
                          decodedRoute={decodedRoute}
                          waypointsInfo={deliveryPlan.route.waypoints_info}
                          waypointsNames={deliveryPlan.locations.waypoints}
                          completedLocations={completedLocations}
                          mapId="b163a3409a584201"
                          onMarkerClick={handleMarkerClick}
                        />
                      </div>
                    )}

                    {/* Waypoints リスト表示 */}
                    <div>
                      {deliveryPlan.route?.waypoints_info &&
                        deliveryPlan.locations?.waypoints?.map((waypoint, index) => {
                          // 「:」で区切って後半部分を取得（なければそのまま）
                          const displayName = waypoint.name.split(":")[1] || waypoint.name

                          // 住所を加工する（例: 〒や「日本、」を取り除く）
                          const processedAddress = (waypoint.address || "")
                            .replace(/〒\d{3}-\d{4}/, "")
                            .replace(/〒\d{7}/, "")
                            .replace("日本、", "")

                          // 終点がスタート地点と同じかどうか判定したい場合 (必要に応じて)
                          const isDuplicateEnd =
                            index === deliveryPlan.locations.waypoints.length - 1 &&
                            deliveryPlan.route.waypoints_info[0].location.lat ===
                              deliveryPlan.route.waypoints_info[index].location.lat &&
                            deliveryPlan.route.waypoints_info[0].location.lng ===
                              deliveryPlan.route.waypoints_info[index].location.lng

                          // ラベルに表示する文字
                          const labelName =
                            deliveryPlan.route.waypoints_info[index]?.name ||
                            getLabel(index, deliveryPlan.locations.waypoints.length, isDuplicateEnd)

                          return (
                            // keyをReact.Fragmentに付与
                            <React.Fragment key={index}>
                              <div className="waypoints-list">
                                <Row className="waypoints-item">
                                  {/* ラベル部分 */}
                                  <Col xs="2" sm="1" md="1" lg="1">
                                    <strong className="waypoints-label">{labelName}:</strong>
                                  </Col>
                                  {/* 名前と住所部分 */}
                                  <Col xs="10" sm="11" md="11" lg="11">
                                    <div className="waypoints-content">
                                      {displayName}
                                      {processedAddress ? ` (${processedAddress})` : ""}
                                    </div>
                                  </Col>
                                </Row>
                              </div>

                              {/* 最終地点でなければ矢印(↓)を表示 */}
                              {index < deliveryPlan.locations.waypoints.length - 1 && (
                                <div style={{ textAlign: "center", fontSize: "1.4rem" }}>
                                  ↓
                                </div>
                              )}
                            </React.Fragment>
                          )
                        })}
                    </div>
                  </div>

                  {/* 「補充計画を実行しますか？」メッセージ */}
                  <div className="text-center mt-4">
                    <p>この補充計画を実行しますか？</p>
                    <div>
                      <Button
                        color="primary"
                        onClick={handleConfirm}
                        className="mr-2"
                        style={{ width: "100px" }}
                      >
                        はい
                      </Button>
                      <Button
                        color="secondary"
                        onClick={() => navigate(-1)}
                        style={{ width: "100px" }}
                      >
                        いいえ
                      </Button>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DeliveryPlanConfirm
