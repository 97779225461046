import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Card, CardBody, Button, Table } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { fetchDeliveryPlansRequest } from "../../store/deliveryPlan/actions"
import ErrorMessage from "../Common/ErrorMessage"
import LoadingOverlay from "../Common/LoadingOverlay"

const DeliveryPlanDelivery = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const isLoadingPlans = useSelector(
    state => state.deliveryPlanReducer.loading
  )
  const allDeliveryPlans =
    useSelector(state => state.deliveryPlanReducer.deliveryPlans) || []

  // ステータスが3 or 4のプランのみをフィルタリング
  const deliveryPlans = allDeliveryPlans.filter(
    plan => plan.status === 3 || plan.status === 4
  )

  // ★ 新しい順（created_at が新しいものを先頭に）でソート
  const sortedPlans = [...deliveryPlans].sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  )

  useEffect(() => {
    // 既存の仕様に合わせて type: 2 を使用
    dispatch(fetchDeliveryPlansRequest({ type: 2 }))
  }, [dispatch])

  const handleSelectPlan = plan => {
    let path
    switch (plan.status) {
      case 1:
        path = "/delivery-plan/confirm"
        navigate(path, {
          state: {
            startLocationName: plan.start_warehouse_name,
            deliveryPlan: plan,
            history: null,
          },
        })
        break
      case 2:
        path = "/delivery-plan/vehicle-select"
        navigate(path, {
          state: {
            startLocationName: plan.start_warehouse_name,
            deliveryPlan: plan,
            history: null,
          },
        })
        break
      case 3:
      case 4:
        path = "/delivery-plan/execute"
        navigate(path, {
          state: {
            deliveryPlan: plan,
          },
        })
        break
      default:
        break
    }
  }

  // ★ 3日以内かどうかを判定するための基準日時
  const now = new Date()
  const threeDaysAgo = new Date(now.getTime() - 3 * 24 * 60 * 60 * 1000)

  // 画面幅によってクラスを切り替え
  const tableStyle = window.innerWidth < 500 ? "p-0" : "mx-auto p-3"
  const tableStyle2 =
    window.innerWidth < 500 ? "p-0 bg-secondary" : "mx-auto p-3 bg-secondary"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="ホーム" breadcrumbItem="配送実行" />
          <ErrorMessage />
          <LoadingOverlay isLoading={isLoadingPlans} />
          <Row>
            <Col xs={12}>
              <Card className={tableStyle2} style={{ maxWidth: "950px" }}>
                <CardBody>
                  <h2>配送一覧</h2>
                  {sortedPlans.length > 0 ? (
                    <Table className="table">
                      <thead>
                        <tr>
                          <th>計画No</th>
                          <th>担当者</th>
                          <th>ステータス</th>
                          <th>計画作成日時</th>
                          <th>操作</th>
                        </tr>
                      </thead>
                      <tbody>
                        {sortedPlans.map((plan, index) => {
                          const createdAtDate = new Date(plan.created_at)
                          const isWithin3Days = createdAtDate >= threeDaysAgo

                          return (
                            <tr key={index}>
                              <td>
                                {/* 3日以内なら赤丸を表示 */}
                                {isWithin3Days && (
                                  <span
                                    style={{
                                      display: "inline-block",
                                      width: "8px",
                                      height: "8px",
                                      borderRadius: "50%",
                                      backgroundColor: "red",
                                      marginRight: "5px",
                                    }}
                                  />
                                )}
                                {plan.id}
                              </td>
                              <td>{plan.user_name}</td>
                              <td>{plan.status_name}</td>
                              <td>{plan.created_at}</td>
                              <td>
                                <Button
                                  color="primary"
                                  onClick={() => handleSelectPlan(plan)}
                                >
                                  配送
                                </Button>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  ) : (
                    <p>割り当てられた計画がありません。</p>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DeliveryPlanDelivery
