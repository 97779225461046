import { get, post, put, del } from "../api_helper"
import * as url from "../url_helper"

const postDeliveryPlan = async data => {
  const response = await post(url.POST_DELIVERY_PLAN, data)
  return { data: response }
}

const getDeliveryItems = async ({ deliveryPlanId, vehicleId }) => {
  try {
    const response = await get(url.GET_DELIVERY_ITEM, {
      params: { deliveryPlanId: deliveryPlanId, vehicleId },
    })
    return response
  } catch (error) {
    throw new Error(error.response ? error.response.data : error.message)
  }
}

const getDeliveryPlans = async data => {
  return await get(`${url.GET_DELIVERY_PLANS}`, {
    params: { type: data.type },
  })
}

const updateDeliveryPlan = async data => {
  if (data.delivery_plan.id) {
    const response = await put(
      `${url.UPDATE_DELIVERY_PLAN}/${data.delivery_plan.id}`,
      data.delivery_plan
    )
    return { data: response }
  } else{
    const response = await put(
      `${url.UPDATE_DELIVERY_PLAN}/${data.plan_id}`,
      data.delivery_plan
    )
    return { data: response }
  }
}

const getDeliveryPlanDetail = async deliveryPlanId => {
  try {
    const response = await get(`${url.GET_DELIVERY_PLAN_DETAIL}`, {
      params: { id: deliveryPlanId },
    })
    return response
  } catch (error) {
    throw new Error(error.response ? error.response.data : error.message)
  }
}

const deleteDeliveryPlan = async deliveryPlanId => {
  const response = await del(`${url.DELETE_DELIVERY_PLAN}/${deliveryPlanId.id}`)
  return { data: response }
}

const getPendingDeliveryPlansCount = async () => {
  const response = await get(url.GET_PENDING_DELIVERY_PLANS_COUNT)
  return response
}

export {
  postDeliveryPlan,
  getDeliveryItems,
  getDeliveryPlans,
  updateDeliveryPlan,
  getDeliveryPlanDetail,
  deleteDeliveryPlan,
  getPendingDeliveryPlansCount,
}
