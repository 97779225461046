// actions.js
import {
  FETCH_MARKETING_TIME_GRAPH_DATA,
  FETCH_MARKETING_TIME_GRAPH_DATA_SUCCESS,
  FETCH_MARKETING_TIME_GRAPH_DATA_ERROR,
} from "./actionTypes"

// Fetch graph data action
export const fetchGraphData = (
  goodsCategory,
  locId,
  timePeriod,
  currentDate,
  previousDate,
  locGroup
) => {
  return {
    type: FETCH_MARKETING_TIME_GRAPH_DATA,
    payload: { goodsCategory, locId, timePeriod, currentDate, previousDate, locGroup },
  }
}

// Fetch graph data success action
export const fetchGraphDataSuccess = payload => {
  return {
    type: FETCH_MARKETING_TIME_GRAPH_DATA_SUCCESS,
    payload,
  }
}

// Fetch graph data error action
export const fetchGraphDataError = error => {
  return {
    type: FETCH_MARKETING_TIME_GRAPH_DATA_ERROR,
    error,
  }
}
